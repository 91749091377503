import gql from 'graphql-tag'
import get from 'lodash/get'
import { createProvider } from '@/vue-apollo'

export default {
  async fetchDataListItems({ commit }) {
    const apollo = createProvider().clients.defaultClient
    const response = await apollo.query({
      query: gql`
        query Property {
          property_user {
            id
            username
          }
        }
      `,
    })

    commit('SET_USERS', get(response, ['data', 'property_user'], []))
  },
}
