export default {
  SET_PAGE(state, pages) {
    state.pages = pages
  },
  // SET_LABELS(state, labels) {
  //   state.eventLabels = labels
  // },
  // UPDATE_ZONE (state, item) {
  //   const itemIndex = state.zones.findIndex((p) => p.id === item.id)
  //   Object.assign(state.brands[itemIndex], item)
  // },
  // REMOVE_ZONE (state, itemId) {
  //   const ItemIndex = state.zones.findIndex((p) => p.id === itemId)
  //   state.zones.splice(ItemIndex, 1)
  // }
}
