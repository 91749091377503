import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'
import { createProvider } from '@/vue-apollo'
import {
  buildList,
  buildRow,
  buildRequestBody,
  groupAttributeByLang,
  buildFileRequestBody,
  buildFileResponseBody,
  checkNameExist
} from '@/helper/Utils'

import {
  GET_HOUSE_SELL_GALLERY_LIST,
  GET_HOUSE_SELL_GALLERY_LIST_BY_PROJECT_UUID,
  GET_HOUSE_SELL_GALLERY_ITEM,
  ADD_HOUSE_SELL_GALLERY,
  UPDATE_HOUSE_SELL_GALLERY,
  DELETE_HOUSE_SELL_GALLERY,
} from './constantGql'

import HOUSE_SELL_GALLERY_ATTRIBUTE from './attributeJson.json'

const getItemByUuid = async (id) => {
  const apollo = createProvider().clients.defaultClient
  const response = await apollo.query({
    query: GET_HOUSE_SELL_GALLERY_ITEM,
    variables: {
      id,
    },
  })

  const rawData = get(response, ['data', 'house_sell_gallery', 0], [])
  const parsedList = groupAttributeByLang(rawData)

  return parsedList
}

export default {
  async fetch({ commit, rootState }) {
    const apollo = createProvider().clients.defaultClient
    const lang = rootState.locale.currentLang
    const response = await apollo.query({
      query: GET_HOUSE_SELL_GALLERY_LIST,
      variables: {
        lang,
      },
    })
    const rawList = get(response, ['data', 'house_sell_gallery'], [])
    let parsedData = buildList(
      rawList,
      'house_sell_gallery_translates',
      rootState.locale.currentLang,
    )
    
    parsedData = parsedData.map((data) => {
      const newData = {
        ...omit(data, 'project_house_sell_gallery_links'),
      }

      const rawProject = get(data, ['project_house_sell_gallery_links', 0, 'project'], {})
      const project = buildRow(rawProject, 'project_translates', lang)
      newData.project_title = get(project, 'title', '')

      return newData
    })

    commit('SET_ITEMS', parsedData)

    return parsedData
  },
  async fetchByProjectUuid ({ commit, rootState }, projectUuid) {
    const lang = rootState.locale.currentLang
    const apollo = createProvider().clients.defaultClient
    const response = await apollo.query({
      query: GET_HOUSE_SELL_GALLERY_LIST_BY_PROJECT_UUID,
      variables: {
        lang,
        projectUuid
      },
    })
  
    const rawList = get(response, ['data', 'house_sell_gallery'], [])
    
    const parsedData = rawList.map((data) => {
      const newData = {
        ...omit(data, 'project_house_sell_gallery_links'),
      }
      newData.house_sell_gallery_files = buildFileResponseBody(newData.house_sell_gallery_files)

      const rawProject = get(data, ['project_house_sell_gallery_links', 0, 'project'], {})
      const project = buildRow(rawProject, 'project_translates', lang)
      newData.project_title = get(project, 'title', '')

      return newData
    })

    commit('SET_FILTER_ITEMS', parsedData)
    return parsedData
  },
  async addItem({ commit, dispatch, rootState }, data) {
    const apollo = createProvider().clients.defaultClient

    const existHouseSellGallery = await dispatch('fetch')
    checkNameExist(existHouseSellGallery, data.name, 'name')

    const lang = rootState.locale.currentLang
    const rawFile = get(data, 'house_sell_gallery_files', [])
    const parsedFile = buildFileRequestBody(
      rawFile,
      HOUSE_SELL_GALLERY_ATTRIBUTE.house_sell_gallery.house_sell_gallery_files,
    )
    data.house_sell_gallery_files = parsedFile
    data.lang = lang

    const body = buildRequestBody(
      { house_sell_gallery: data },
      'house_sell_gallery',
      HOUSE_SELL_GALLERY_ATTRIBUTE,
      lang,
    )

    const response = await apollo.mutate({
      mutation: ADD_HOUSE_SELL_GALLERY,
      // Parameters
      variables: {
        houseSellGallery: body.house_sell_gallery,
      },
    })

    const id = get(response, ['data', 'insert_property_house_sell_gallery_one', 'id'], null)

    commit('ADD_ITEM', get(response, ['data', 'insert_property_house_sell_gallery_one']))

    return id
  },

  async duplicateItem(context, data) {
    const existHouseSellGallery = await context.dispatch('fetch')
    let canUseName = false
    data.name = data.name.concat('_copy')

    while (!canUseName) {
      try {
        checkNameExist(existHouseSellGallery, data.name, 'name')
        canUseName = true
      } catch (e) {
        data.name = data.name.concat('_copy')
      }
    }
    return await context.dispatch('addItem', data)
  },
  

  async updateItem({ commit, rootState, dispatch }, data) {
    const apollo = createProvider().clients.defaultClient

    const existHouseSellGallery = await dispatch('fetch')
    checkNameExist(existHouseSellGallery, data.name, 'name', data.id)
    
    const lang = rootState.locale.currentLang
    const rawFile = get(data, 'house_sell_gallery_files', [])
    const parsedFile = buildFileRequestBody(
      rawFile,
      HOUSE_SELL_GALLERY_ATTRIBUTE.house_sell_gallery.house_sell_gallery_files,
    )
    data.house_sell_gallery_files = parsedFile

    const body = buildRequestBody(
      { house_sell_gallery: data },
      'house_sell_gallery',
      HOUSE_SELL_GALLERY_ATTRIBUTE,
      lang,
    )
    // const revisionData = await getItemByUuid(data.uuid)

    const files = body.house_sell_gallery.house_sell_gallery_files
    const houseSellGalleryFile = isEmpty(files)
      ? []
      : files.data.map((file) => ({
          ...file,
          house_sell_gallery_id: data.id,
        }))

    const links = body.house_sell_gallery.project_house_sell_gallery_links
    const houseSellGalleryLink = isEmpty(links)
      ? {}
      : links.data.map((link) => ({
          ...link,
          house_sell_gallery_id: data.id,
        }))[0]

    const rawResponse = await apollo.mutate({
      mutation: UPDATE_HOUSE_SELL_GALLERY,
      // Parameters
      variables: {
        houseSellGallery: omit(body.house_sell_gallery, [
          'house_sell_gallery_files',
          'project_house_sell_gallery_links'
        ]),
        houseSellGalleryLink,
        houseSellGalleryFile,
        id: data.id,
      },
    })

    const response = get(rawResponse, ['data', 'update_property_house_sell_gallery_by_pk'])
    commit('UPDATE_ITEM', response)

    return response
  },

  async delete({ commit }, id) {
    const apollo = createProvider().clients.defaultClient
    // const revisionData = await getItemByUuid(id)

    await apollo.mutate({
      mutation: DELETE_HOUSE_SELL_GALLERY,
      // Parameters
      variables: {
        id
      },
    })

    commit('DELETE_ITEM', id)

    return id
  },
  async fetchOne({ commit }, id) {
    const parsed = await getItemByUuid(id)
    parsed.house_sell_gallery_files = buildFileResponseBody(parsed.house_sell_gallery_files)

    commit('SET_ITEM', parsed)

    return parsed
  },
}
