import gql from 'graphql-tag'

export const CREATE = gql`
  mutation($categoryId: Int!, $toCreateItem: cms_content_insert_input!) {
    result: insert_cms_content_category_one(
      object: { category_id: $categoryId, content: { data: $toCreateItem } }
    ) {
      id
      content {
        id
      }
    }
  }
`
export const UPDATE = gql`
  mutation(
    $id: Int!
    $categoryId: Int!
    $toUpdateItem: cms_content_set_input!
    $toDeleteItem: cms_content_revision_insert_input!
    $customSlug: String!
  ) {
    updateResult: update_cms_content_by_pk(pk_columns: { id: $id }, _set: $toUpdateItem) {
      id
    }

    delete_cms_content_category(where: { content_id: { _eq: $id } }) {
      affected_rows
    }

    insert_cms_content_category(objects: [{content_id: $id, category_id: $categoryId}]) {
      returning {
        id
      }
    }

    update_cms_content(_set: { custom_slug: $customSlug, slug: $customSlug }, where: {
      parent_id: {
        _eq: $id
      }
      lang: {
        _neq: "th"
      }
    }) {
      affected_rows
    }

    revisionResult: insert_cms_content_revision_one(object: $toDeleteItem) {
      id
      revision_id
    }
  }
`

export const DELETE = gql`
  mutation($id: Int!, $toDeleteItem: cms_content_revision_insert_input!) {
    deleteResult: delete_cms_content_by_pk(id: $id) {
      id
    }

    revisionResult: insert_cms_content_revision_one(object: $toDeleteItem) {
      id
      revision_id
    }
  }
`

export const FETCH = gql`
  query($lang: String!) {
    result: cms_content(
      where: {
        status: { _neq: "archived" }
        lang: { _eq: $lang }
        content_categories: { category: { parent_id: { _eq: 2 } } }
      }
      order_by: { updated_at: desc }
    ) {
      id
      project_id
      title
      slug
      custom_slug
      content_categories {
        category {
          id
          name
        }
      }
      description
      published_date
      revision_id
      updated_at
      updated_by
      created_by
    }
  }
`

export const FIND_ONE_BY_ID = gql`
  query($id: Int!) {
    result: cms_content_by_pk(id: $id) {
      id
      lang
      revision_id
      project_id
      title
      slug
      custom_slug
      description
      content
      thumbnail
      seo_title
      seo_h1
      seo_description
      seo_keywords
      seo_image
      published_date
      folder_name
      content_categories {
        category_id
      }
      updated_at
      updated_by
      created_by
    }
  }
`


export const FETCH_ALL = gql`
  query {
    result: cms_content(
      where: {
        status: { _neq: "archived" }
        content_categories: { category: { parent_id: { _eq: 2 } } }
      }
      order_by: { updated_at: desc }
    ) {
      id
      project_id
      title
      slug
      custom_slug
      lang
      content_categories {
        category {
          id
          name
        }
      }
      description
      published_date
      revision_id
      updated_at
      updated_by
      created_by
    }
  }
`
