import gql from 'graphql-tag'

export const CREATE = gql`
  mutation($toCreateItem: property_brand_insert_input!) {
    result: insert_property_brand_one(object: $toCreateItem) {
      id
    }
  }
`

export const CREATE_FOLDER_NAME = gql`
  mutation($folderName: property_brand_translate_insert_input!) {
    result: insert_property_brand_translate_one(object: $folderName) {
      id
    }
  }
`

export const UPDATE = gql`
  mutation update(
    $brand: property_brand_set_input!
    $brandTranslate: [property_brand_translate_insert_input!]!
    $brandFile: [property_brand_file_insert_input!]!
    $brandTalk: [property_brand_talk_insert_input!]!
    $id: Int!
    $slug: String!
    $brand_lang_id: Int!
  ) {
    update_property_brand_by_pk(_set: $brand, pk_columns: { id: $id }) {
      id
    }
    update_property_brand(_set: { slug: $slug }, where: {
      brand_lang_id: {
        _eq: $brand_lang_id
      }
      lang: {
        _neq: "th"
      }
      id: {
        _neq: $id
      }
    }) {
      affected_rows
    }
    delete_property_brand_translate(where: { brand_id: { _eq: $id } }) {
      affected_rows
    }
    insert_property_brand_translate(objects: $brandTranslate) {
      returning {
        id
      }
    }
    delete_property_brand_file(where: { brand_id: { _eq: $id } }) {
      affected_rows
    }
    insert_property_brand_file(objects: $brandFile) {
      returning {
        id
      }
    }
    delete_property_brand_talk(where: { brand_id: { _eq: $id } }) {
      affected_rows
    }
    insert_property_brand_talk(objects: $brandTalk) {
      returning {
        uuid
      }
    }
  }
`

export const UPDATE_PRIORITY = gql`
  mutation($id: Int!, $priority: Int!) {
    updateResult: update_property_brand_by_pk(
      pk_columns: { id: $id }
      _set: { priority: $priority }
    ) {
      id
    }
  }
`

export const DELETE = gql`
  mutation($id: Int!) {
    deleteResult: delete_property_brand_by_pk(id: $id) {
      id
    }
  }
`

export const FETCH = gql`
  query($lang: String!) {
    result: property_brand(
      where: { status: { _neq: "archived" }, lang: { _eq: $lang } }
      order_by: { updated_at: desc }
    ) {
      id
      slug
      brand_translates {
        lang
        attribute_code
        value
      }
      brand_files {
        attribute_code
        image
      }
      priority
      updated_at
      created_by
      updated_by
    }
  }
`

export const FIND_ONE_BY_ID = gql`
  query($id: Int!) {
    result: property_brand_by_pk(id: $id) {
      id
      brand_lang_id
      slug
      brand_translates {
        id
        lang
        attribute_code
        value
      }
      brand_files {
        id
        attribute_code
        image
      }
      brand_talks (
        order_by: { position: asc }
      ) {
        uuid
        position
        brand_talk_translates {
          uuid
          lang
          attribute_code
          value
        }
      }
      brand_lang_id
      priority
      lang
      updated_at
      created_by
      updated_by
    }
  }
`

export const GET_PROJECT_BRAND_ID = gql`
  query($brandId: Int!) {
    result: property_project(
      where: { brand_id: { _eq: $brandId }, status: { _neq: "archived" } }
    ) {
      id
      status
    }
  }
`

export const FETCH_ALL = gql`
  query {
    result: property_brand(
      where: { status: { _neq: "archived" } }
      order_by: { updated_at: desc }
    ) {
      id
      brand_lang_id
      brand_translates {
        lang
        attribute_code
        value
      }
      lang
      slug
      priority
      updated_at
      created_by
      updated_by
    }
  }
`

export const FIND_ONE_BY_ID_LANG = gql`
  query Brand_lang($id: Int!, $lang: String) {
    result: property_brand(where: {
      status: { _neq: "archived" },
      lang: { _eq: $lang },
      brand_lang_id: { _eq: $id }
    }) {
      id
      brand_lang_id
    }
  }
`

export const GET_LIST_BY_SLUG = gql`
  query Brand_lang($brand_lang_id: Int, $slug: String) {
    result: property_brand(where: {
      status: { _neq: "archived" },
      brand_lang_id: { _neq: $brand_lang_id }
      slug: { _eq: $slug }
    }) {
      id
      brand_lang_id
    }
  }
`