import gql from 'graphql-tag'
import get from 'lodash/get'
import { createProvider } from '@/vue-apollo'
import { buildList } from '@/helper/Utils'

export default {
  async fetchDataListItems({ commit, rootState }, lang = null) {
    const currentLang = rootState.locale.currentLang
    const queryLang = lang ? lang : currentLang
    const apollo = createProvider().clients.defaultClient
    const response = await apollo.query({
      query: gql`
        query Property {
          categories: property_property_category (
          order_by: {
            updated_at: desc
          }
        ) {
            id
            property_type
            code
            property_category_translates {
              id
              lang
              attribute_code
              value
            }
          }
        }
      `
    })

    const rawList = get(response, ['data', 'categories'], [])
    const parsedData = buildList(rawList, 'property_category_translates', queryLang)

    commit('SET_CATEGORIES', parsedData)

    return parsedData
  },
}
