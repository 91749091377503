import gql from 'graphql-tag'

export const CREATE = gql`
  mutation($toCreateItem: cms_content_insert_input!) {
    result: insert_cms_content_category_one(
      object: { category_id: 6, content: { data: $toCreateItem } }
    ) {
      id
      content {
        id
      }
    }
  }
`
export const UPDATE = gql`
  mutation(
    $id: Int!
    $toUpdateItem: cms_content_set_input!
    $toDeleteItem: cms_content_revision_insert_input!
  ) {
    updateResult: update_cms_content_by_pk(pk_columns: { id: $id }, _set: $toUpdateItem) {
      id
    }

    revisionResult: insert_cms_content_revision_one(object: $toDeleteItem) {
      id
      revision_id
    }
  }
`

export const DELETE = gql`
  mutation($id: Int!, $toDeleteItem: cms_content_revision_insert_input!) {
    deleteResult: delete_cms_content_by_pk(id: $id) {
      id
    }

    revisionResult: insert_cms_content_revision_one(object: $toDeleteItem) {
      id
      revision_id
    }
  }
`

export const FETCH = gql`
  query($lang: String!) {
    result: cms_content(
      where: {
        status: { _neq: "archived" }
        lang: { _eq: $lang }
        content_categories: { category_id: { _eq: 6 } }
      }
      order_by: { updated_at: desc }
    ) {
      id
      lang
      slug
      custom_slug
      project_id
      title
      description
      published_date
      revision_id
      updated_at
      updated_by
      created_by
    }
  }
`

export const FIND_ONE_BY_ID = gql`
  query($id: Int!) {
    result: cms_content_by_pk(id: $id) {
      id
      lang
      revision_id
      project_id
      title
      slug
      custom_slug
      description
      content
      thumbnail
      seo_title
      seo_h1
      seo_description
      seo_keywords
      seo_image
      published_date
      folder_name
      updated_at
      updated_by
      created_by
    }
  }
`
