export default {
  SET_ITEMS(state, value) {
    state.items = value
  },
  SET_ALL_ITEMS(state, value) {
    state.all_items = value
  },
  SET_LOADING(state, loading) {
    state.loading = loading
  },
}
