export default {
  ADD_BRAND(state, item) {
    state.brands.addBrand = item
  },
  SET_MAGAZINES(state, magazines) {
    state.items = magazines
  },
  // SET_LABELS(state, labels) {
  //   state.eventLabels = labels
  // },
  UPDATE_BRAND(state, brand) {
    const brandIndex = state.brands.findIndex((p) => p.id === brand.id)
    Object.assign(state.brands[brandIndex], brand)
  },
  REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.brands.findIndex((p) => p.id === itemId)
    state.brands.splice(ItemIndex, 1)
  },
  SET_LOADING(state, loading) {
    state.loading = loading
  },
}
