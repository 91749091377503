export default {
  ADD_USER(state, item) {
    state.users.unshift(item)
  },
  SET_USERS(state, users) {
    state.users = users
  },
  // SET_LABELS(state, labels) {
  //   state.eventLabels = labels
  // },
  UPDATE_USER(state, item) {
    const itemIndex = state.users.findIndex((p) => p.id === item.id)
    Object.assign(state.brands[itemIndex], item)
  },
  REMOVE_USER(state, itemId) {
    const ItemIndex = state.users.findIndex((p) => p.id === itemId)
    state.users.splice(ItemIndex, 1)
  },
}
