import gql from 'graphql-tag'

export const CREATE = gql`
  mutation($toCreateItem: cms_footer_insert_input!) {
    result: insert_cms_footer_one(object: $toCreateItem) {
      id
    }
  }
`
export const UPDATE = gql`
  mutation(
    $id: Int!
    $toUpdateItem: cms_footer_set_input!
    $toDeleteItem: cms_footer_revision_insert_input!
  ) {
    updateResult: update_cms_footer_by_pk(pk_columns: { id: $id }, _set: $toUpdateItem) {
      id
    }

    revisionResult: insert_cms_footer_revision_one(object: $toDeleteItem) {
      id
      revision_id
    }
  }
`

export const DELETE = gql`
  mutation($id: Int!, $toDeleteItem: cms_footer_revision_insert_input!) {
    deleteResult: delete_cms_footer_by_pk(id: $id) {
      id
    }

    revisionResult: insert_cms_footer_revision_one(object: $toDeleteItem) {
      id
      revision_id
    }
  }
`

export const FETCH = gql`
  query($lang: String!) {
    result: cms_footer(
      where: { status: { _neq: "archived" }, lang: { _eq: $lang } }
      order_by: { updated_at: desc }
    ) {
      id
      lang
      name
      url
      is_default
      revision_id
      updated_at
      created_by
      updated_by
    }
  }
`

export const FIND_ONE_BY_ID = gql`
  query($id: Int!) {
    result: cms_footer_by_pk(id: $id) {
      id
      lang
      name
      url
      content
      is_default
      revision_id
      updated_at
      created_by
      updated_by
    }
  }
`
