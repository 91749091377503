import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'
import { createProvider } from '@/vue-apollo'
import { buildList, buildRequestBody, groupAttributeByLang } from '@/helper/Utils'
import {
  GET_HOUSE_FUNCTION_LIST,
  GET_HOUSE_FUNCTION_ITEM,
  ADD_HOUSE_FUNCTION,
  UPDATE_HOUSE_FUNCTION,
  DELETE_HOUSE_FUNCTION,
  GET_EXIST_LANG_BY_ID
} from './constantGql'

import HOUSE_FUNCTION_ATTRIBUTE from './attributeJson.json'

const getItemByUuid = async (uuid) => {
  const apollo = createProvider().clients.defaultClient
  const response = await apollo.query({
    query: GET_HOUSE_FUNCTION_ITEM,
    variables: {
      uuid,
    },
  })

  const rawData = get(response, ['data', 'house_function', 0], [])
  const parsedList = groupAttributeByLang(rawData)

  return parsedList
}

export default {
  async fetch({ commit, rootState }) {
    const apollo = createProvider().clients.defaultClient
    const lang = rootState.locale.currentLang
    const response = await apollo.query({
      query: GET_HOUSE_FUNCTION_LIST,
      variables: {
        lang,
      },
    })
    const rawList = get(response, ['data', 'house_function'], [])
    const parsedData = buildList(
      rawList,
      'house_function_translates',
      lang,
    )
    commit('SET_ITEMS', parsedData)

    return parsedData
  },
  async fetchByLang({ commit }, lang) {
    const apollo = createProvider().clients.defaultClient
    const response = await apollo.query({
      query: GET_HOUSE_FUNCTION_LIST,
      variables: {
        lang,
      },
    })
    const rawList = get(response, ['data', 'house_function'], [])
    const parsedData = buildList(
      rawList,
      'house_function_translates',
      lang,
    )

    commit('SET_LANG_ITEMS', parsedData)

    return parsedData
  },
  async addItem({ rootState }, data) {
    const apollo = createProvider().clients.defaultClient

    const lang = rootState.locale.currentLang
    
    data.lang = data.lang ? data.lang : lang
    const body = buildRequestBody(
      { house_function: data },
      'house_function',
      HOUSE_FUNCTION_ATTRIBUTE,
      data.lang,
    )

    const response = await apollo.mutate({
      mutation: ADD_HOUSE_FUNCTION,
      // Parameters
      variables: {
        houseFunction: body.house_function,
      },
    })

    return get(response, ['data', 'insert_property_house_function_one', 'uuid'], null)
  },

  async updateItem({ commit, rootState }, data) {
    const apollo = createProvider().clients.defaultClient

    const lang = rootState.locale.currentLang
    const body = buildRequestBody(
      { house_function: data },
      'house_function',
      HOUSE_FUNCTION_ATTRIBUTE,
      lang,
    )
    // const revisionData = await getItemByUuid(data.uuid)

    const translates = body.house_function.house_function_translates
    const houseFunctionTranslate = isEmpty(translates)
      ? []
      : translates.data.map((translate) => ({
          ...translate,
          house_function_uuid: data.uuid,
        }))

    const rawResponse = await apollo.mutate({
      mutation: UPDATE_HOUSE_FUNCTION,
      // Parameters
      variables: {
        houseFunction: omit(body.house_function, ['house_function_translates']),
        houseFunctionTranslate,
        uuid: data.uuid,
        lang,
      },
    })

    const response = get(rawResponse, ['data', 'update_property_house_function_by_pk'])

    commit('UPDATE_ITEM', response)

    return response
  },

  async delete(_, uuid) {
    const apollo = createProvider().clients.defaultClient
    const revisionData = await getItemByUuid(uuid)

    await apollo.mutate({
      mutation: DELETE_HOUSE_FUNCTION,
      // Parameters
      variables: {
        uuid,
        revisionData,
      },
    })

    return uuid
  },

  async fetchById({ commit }, id) {
    const apollo = createProvider().clients.defaultClient
    const response = await apollo.query({
      query: GET_EXIST_LANG_BY_ID,
      variables: {
        id,
      },
    })
    const rawList = get(response, ['data', 'house_function'], [])
    const parsedData = rawList.map((item) => {
      return item.lang
    })

    commit('SET_LANGS', parsedData)

    return parsedData
  },

  async fetchOne({ commit }, uuid) {
    const parsedList = await getItemByUuid(uuid)

    commit('SET_ITEM', parsedList)

    return parsedList
  },
}
