import gql from 'graphql-tag'

export const GET_HOUSE_SELL_GALLERY_LIST = gql`
  query LIST($lang: String!) {
    house_sell_gallery: property_house_sell_gallery (
      where: {lang: {_eq: $lang}}
      order_by: { updated_at: desc }
    ){
      id
      lang
      name
      folder_name
      updated_at
      created_at
      updated_by
      created_by
      project_house_sell_gallery_links {
        project {
          id
          uuid
          project_translates{
            attribute_code
            lang
            value
          }
        }
      }
    }
  }
`

export const GET_HOUSE_SELL_GALLERY_LIST_BY_PROJECT_UUID = gql`
  query LIST($lang: String!, $projectUuid: uuid!) {
    house_sell_gallery: property_house_sell_gallery (
      where: {
        lang: {_eq: $lang}
        project_house_sell_gallery_links: {
          project: {
            uuid: {_eq: $projectUuid}
          }
        }
      }
    ){
      id
      lang
      name
      folder_name
      updated_at
      created_at
      updated_by
      created_by
      project_house_sell_gallery_links {
        project_uuid
        project {
          project_translates{
            attribute_code
            lang
            value
          }
        }
      }
      house_sell_gallery_files {
        uuid
        image
        house_sell_gallery_file_translates {
          lang
          value
          attribute_code
        }
      }
    }
  }
`

export const GET_HOUSE_SELL_GALLERY_ITEM = gql`
  query GET_ITEM($id: Int!) {
    house_sell_gallery: property_house_sell_gallery(where:{id: {_eq: $id}}){
      id
      lang
      name
      folder_name
      updated_at
      created_at
      updated_by
      created_by
      project_house_sell_gallery_links {
        project_uuid
        project {
          project_translates{
            attribute_code
            lang
            value
          }
        }
      }
      house_sell_gallery_files {
        image
        house_sell_gallery_file_translates {
          attribute_code
          lang
          value
        }
      }
    }
  }
`

export const ADD_HOUSE_SELL_GALLERY = gql`
  mutation HouseSellGallery($houseSellGallery: property_house_sell_gallery_insert_input!) {
    insert_property_house_sell_gallery_one(object: $houseSellGallery) {
      id
    }
  }
`

export const UPDATE_HOUSE_SELL_GALLERY = gql`
  mutation update(
    $houseSellGallery: property_house_sell_gallery_set_input!
    $houseSellGalleryLink: property_project_house_sell_gallery_link_set_input!
    $houseSellGalleryFile: [property_house_sell_gallery_file_insert_input!]!
    $id: Int!
  ) {
    update_property_house_sell_gallery_by_pk(_set: $houseSellGallery, pk_columns: { id: $id }) {
      id 
    }
    update_property_project_house_sell_gallery_link(
      _set: $houseSellGalleryLink,
      where:{
        house_sell_gallery_id: {
          _eq: $id
        }
      }) {
      returning {
        uuid
      }
    }
    delete_property_house_sell_gallery_file(where: { house_sell_gallery_id: { _eq: $id } }) {
      affected_rows
    }
    insert_property_house_sell_gallery_file(objects: $houseSellGalleryFile) {
      returning {
        uuid
      }
    }
  }
`

export const DELETE_HOUSE_SELL_GALLERY = gql`
  mutation DELETE($id: Int!) {
    delete_property_house_sell_gallery_by_pk(id: $id) {
      id
    }
  }
`
