import gql from 'graphql-tag'

export const CREATE = gql`
  mutation($toCreateItem: cms_redirection_insert_input!) {
    result: insert_cms_redirection_one(object: $toCreateItem) {
      id
    }
  }
`
export const UPDATE = gql`
  mutation($id: Int!, $toUpdateItem: cms_redirection_set_input!) {
    updateResult: update_cms_redirection_by_pk(
      pk_columns: { id: $id }
      _set: $toUpdateItem
    ) {
      id
    }
  }
`

export const DELETE = gql`
  mutation($id: Int!) {
    deleteResult: delete_cms_redirection_by_pk(id: $id) {
      id
    }
  }
`

export const FETCH = gql`
  query {
    result: cms_redirection(
      order_by: { updated_at: desc }
    ) {
      id
      source_site
      source
      target_site
      target
      status_code
      active
      expiry
      start_date
      priority
      updated_at
    }
  }
`

export const FIND_BY_IN_USED = gql`
  query {
    result: cms_redirection_views(order_by: { id: desc }) {
      id
      source_site
      source
      target_site
      target
      status_code
      active
      expiry
      start_date
      priority
      updated_at
    }
  }
`

export const FIND_ONE_BY_ID = gql`
  query($id: Int!) {
    result: cms_redirection_by_pk(id: $id) {
      id
      source_site
      source
      target_site
      target
      status_code
      active
      expiry
      start_date
      priority
      updated_at
      updated_by
      created_by
    }
  }
`
