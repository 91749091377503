import mock from '@/fake-db/mock.js'

const data = {
  brands: [
    {
      id: 1,
      name: 'Ladawan',
      logo: 'https://via.placeholder.com/150',
      concept: 'concept blah',
      priority: 1,
      status: 'active',
      created_by: '1',
      created_at: '2012-01-26T13:51:50.417-07:00',
      modified_by: '1',
      modified_at: '2012-01-26T13:51:50.417-07:00',
    },
    {
      id: 2,
      name: 'LH',
      logo: 'https://via.placeholder.com/150',
      concept: 'concept blah',
      priority: 1,
      status: 'inactive',
      created_by: '1',
      created_at: '2012-01-26T13:51:50.417-07:00',
      modified_by: '1',
      modified_at: '2012-01-26T13:51:50.417-07:00',
    },
    {
      id: 3,
      name: 'Vive',
      logo: 'https://via.placeholder.com/150',
      concept: 'concept blah',
      priority: 1,
      status: 'inactive',
      created_by: '1',
      created_at: '2012-01-26T13:51:50.417-07:00',
      modified_by: '1',
      modified_at: '2012-01-26T13:51:50.417-07:00',
    },
    {
      id: 4,
      name: 'Ladawan',
      logo: 'https://via.placeholder.com/150',
      concept: 'concept blah',
      priority: 1,
      status: 'active',
      created_by: '1',
      created_at: '2012-01-26T13:51:50.417-07:00',
      modified_by: '1',
      modified_at: '2012-01-26T13:51:50.417-07:00',
    },
    {
      id: 5,
      name: 'Ladawan',
      logo: 'https://via.placeholder.com/150',
      concept: 'concept blah',
      priority: 1,
      status: 'active',
      created_by: '1',
      created_at: '2012-01-26T13:51:50.417-07:00',
      modified_by: '1',
      modified_at: '2012-01-26T13:51:50.417-07:00',
    },
    {
      id: 6,
      name: 'Ladawan',
      logo: 'https://via.placeholder.com/150',
      concept: 'concept blah',
      priority: 1,
      status: 'active',
      created_by: '1',
      created_at: '2012-01-26T13:51:50.417-07:00',
      modified_by: '1',
      modified_at: '2012-01-26T13:51:50.417-07:00',
    },
    {
      id: 7,
      name: 'Ladawan',
      logo: 'https://via.placeholder.com/150',
      concept: 'concept blah',
      priority: 1,
      status: 'active',
      created_by: '1',
      created_at: '2012-01-26T13:51:50.417-07:00',
      modified_by: '1',
      modified_at: '2012-01-26T13:51:50.417-07:00',
    },
    {
      id: 8,
      name: 'Ladawan',
      logo: 'https://via.placeholder.com/150',
      concept: 'concept blah',
      priority: 1,
      status: 'active',
      created_by: '1',
      created_at: '2012-01-26T13:51:50.417-07:00',
      modified_by: '1',
      modified_at: '2012-01-26T13:51:50.417-07:00',
    },
    {
      id: 9,
      name: 'Ladawan',
      logo: 'https://via.placeholder.com/150',
      concept: 'concept blah',
      priority: 1,
      status: 'active',
      created_by: '1',
      created_at: '2012-01-26T13:51:50.417-07:00',
      modified_by: '1',
      modified_at: '2012-01-26T13:51:50.417-07:00',
    },
    {
      id: 10,
      name: 'Ladawan',
      logo: 'https://via.placeholder.com/150',
      concept: 'concept blah',
      priority: 1,
      status: 'active',
      created_by: '1',
      created_at: '2012-01-26T13:51:50.417-07:00',
      modified_by: '1',
      modified_at: '2012-01-26T13:51:50.417-07:00',
    },
    {
      id: 11,
      name: 'Ladawan',
      logo: 'https://via.placeholder.com/150',
      concept: 'concept blah',
      priority: 2,
      status: 'inactive',
      created_by: '1',
      created_at: '2012-01-26T13:51:50.417-07:00',
      modified_by: '1',
      modified_at: '2012-01-26T13:51:50.417-07:00',
    },
  ],
}

mock.onGet('/api/property-asset/brands').reply(() => {
  return [200, JSON.parse(JSON.stringify(data.brands)).reverse()]
})

// POST : Add new Item
mock.onPost('/api/property-asset/brands/').reply((request) => {
  // Get event from post data
  const item = JSON.parse(request.data).item

  const length = data.brands.length
  let lastIndex = 0
  if (length) {
    lastIndex = data.brands[length - 1].id
  }
  item.id = lastIndex + 1

  data.brands.push(item)

  return [201, { id: item.id }]
})

// Update Product
mock.onPost(/\/api\/property-asset\/brands\/\d+/).reply((request) => {
  const itemId = request.url.substring(request.url.lastIndexOf('/') + 1)
  const item = data.brands.find((item) => item.id === parseInt(itemId))
  Object.assign(item, JSON.parse(request.data).item)

  return [200, item]
})

// DELETE: Remove Item
mock.onDelete(/\/api\/property-asset\/brands\/\d+/).reply((request) => {
  const itemId = request.url.substring(request.url.lastIndexOf('/') + 1)

  const itemIndex = data.brands.findIndex((p) => p.id === itemId)
  data.brands.splice(itemIndex, 1)
  return [200]
})
