<template>
  <input
    v-model="$attrs.value"
    v-imask="mask"
    @accept="onAccept"
    class="vs-inputx vs-input--input normal hasValue"
  />
</template>

<script>
import { IMaskDirective } from 'vue-imask'

export default {
  name: "LhInputNumber",
  props: {
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 1000000000000
    },
    thousandsSeparator: {
      type: String,
      default: ','
    },
    scale: {
      type: Number,
      default: 2,
    }
  },
  data() {
    return {
      mask: {
        mask: Number,
        thousandsSeparator: this.thousandsSeparator,
        scale: this.scale,  // digits after point, 0 for integers
        signed: false,  // disallow negative
        padFractionalZeros: false,  // if true, then pads zeros at end to the length of scale
        normalizeZeros: true,  // appends or removes zeros at ends
        radix: '.',  // fractional delimiter
        mapToRadix: ['.'],
        min: this.min,
        max: this.max
      },

    }
  },
  methods: {
    update(value) {
      this.$emit('input', value)
    },
    onAccept (e) {
      const maskRef = e.detail
      this.value = maskRef.value
      this.$emit('input', maskRef.value)
    },
  },
  directives: {
    imask: IMaskDirective
  }
}
</script>

<style scoped>

</style>
