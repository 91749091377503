import gql from 'graphql-tag'

export const FETCH = gql`
  query {
    result: property_property_category (
      order_by: {
        updated_at: desc
      }
    ){
      id
      slug
      property_category_translates {
        id
        lang
        value
      }
      code
      updated_at
      created_by
      updated_by
    }
  }
`

export const FIND_ONE_BY_ID = gql`
  query($id: Int!) {
    result: property_property_category_by_pk(id: $id) {
      id
      slug
      code
      property_type
      property_category_translates {
        id
        lang
        value
      }
      updated_at
      created_by
      updated_by
    }
  }
`

export const CREATE = gql`
  mutation(
    $propertyCategory: property_property_category_insert_input!
  ) {
    result: insert_property_property_category_one(
      object: $propertyCategory
    ) {
      id
    }
  }
`

export const UPDATE = gql`
  mutation(
    $id: Int!
    $propertyCategory: property_property_category_set_input!
    $propertyTranslate: [property_property_category_translate_insert_input!]!
  ) {
    result: update_property_property_category_by_pk(
      pk_columns: { id: $id }
      _set: $propertyCategory
    ) {
      id
    }

    delete_property_property_category_translate(where: { property_category_id: { _eq: $id } }) {
      affected_rows
    }
    
    insert_property_property_category_translate(objects: $propertyTranslate) {
      returning {
        id
      }
    }
  }
`

export const DELETE = gql`
  mutation($id: Int!) {
    result: delete_property_property_category_by_pk(id: $id) {
      id
    }
  }
`

export const GET_LIST_BY_SLUG = gql`
  query GET_PROPERTY($id: Int, $slug: String) {
    result: property_property_category(where: {
      id: { _neq: $id }
      slug: { _eq: $slug }
    }) {
      id
    }
  }
`