import gql from 'graphql-tag'

export const GET_COMMUNITY_FEATURE_LIST = gql`
  query GET_LIST {
    property_community_feature(
      where: { status: { _neq: "archived" } }
      order_by: { updated_at: desc }
    ) {
      id
      uuid
      updated_at
      created_at
      created_by
      updated_by
      community_feature_translates {
        lang
        value
        attribute_code
      }
    }
  }
`

export const GET_COMMUNITY_FEATURE_ITEM = gql`
  query GET_ITEM($uuid: uuid!) {
    community_feature: property_community_feature(
      where: { status: { _neq: "archived" }, uuid: { _eq: $uuid } }
    ) {
      id
      uuid
      updated_at
      created_at
      created_by
      updated_by
      community_feature_translates {
        lang
        value
        attribute_code
      }
    }
  }
`

export const ADD_COMMUNITY_FEATURE = gql`
  mutation ADD($communityFeature: property_community_feature_insert_input!) {
    insert_property_community_feature_one(object: $communityFeature) {
      id
      uuid
    }
  }
`

export const UPDATE_COMMUNITY_FEATURE = gql`
  mutation Update(
    $communityFeature: property_community_feature_set_input!
    $communityFeatureTranslate: [property_community_feature_translate_insert_input!]!
    $uuid: uuid!
    $lang: String!
  ) {
    update_property_community_feature_by_pk(
      _set: $communityFeature
      pk_columns: { uuid: $uuid }
    ) {
      id
    }
    delete_property_community_feature_translate(
      where: { community_feature_uuid: { _eq: $uuid }, lang: { _eq: $lang } }
    ) {
      affected_rows
    }
    insert_property_community_feature_translate(objects: $communityFeatureTranslate) {
      returning {
        uuid
      }
    }
  }
`

export const DELETE_COMMUNITY_FEATURE = gql`
  mutation DELETE($uuid: uuid!, $revisionData: jsonb!) {
    insert_property_house_revision_one(
      object: {
        revision_uuid: $uuid
        revision_table: "community_feature"
        revision_data: $revisionData
      }
    ) {
      uuid
    }
    delete_property_community_feature_by_pk(uuid: $uuid) {
      uuid
    }
  }
`
