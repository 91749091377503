import gql from 'graphql-tag'

export const ADD_MAGAZINE = gql`
  mutation(
    $title: String!
    $description: String
    $thumbnailId: Int!
    $fileId: Int!
    $startDate: date!
    $endDate: date!
    $is_new: Boolean!
    $lang: String!
    $folder_name: String!
    $created_by: String!
    $updated_by: String!
  ) {
    result: insert_cms_magazine_one(
      object: {
        title: $title
        description: $description
        thumbnail_id: $thumbnailId
        file_id: $fileId
        start_cover_date: $startDate
        end_cover_date: $endDate
        is_new: $is_new 
        lang: $lang
        folder_name: $folder_name
        created_by: $created_by
        updated_by: $updated_by
      }
    ) {
      id
      revision_id
    }
  }
`
export const UPDATE = gql`
  mutation(
    $id: Int!
    $title: String!
    $description: String
    $thumbnailId: Int!
    $fileId: Int!
    $startDate: date!
    $endDate: date!
    $is_new: Boolean!
    $lang: String!
    $folder_name: String!
    $created_by: String!
    $updated_by: String!
  ) {
    result: insert_cms_magazine_one(
      object: {
        id: $id
        title: $title
        description: $description
        thumbnail_id: $thumbnailId
        file_id: $fileId
        start_cover_date: $startDate
        end_cover_date: $endDate
        is_new: $is_new
        lang: $lang
        folder_name: $folder_name
        created_by: $created_by
        updated_by: $updated_by
      }
    ) {
      id
      revision_id
    }
  }
`

export const DELETE_MAGAZINE = gql`
  mutation($revision_id: uuid!) {
    result: update_cms_magazine_by_pk(
      pk_columns: { revision_id: $revision_id }
      _set: { status: "archived" }
    ) {
      id
      revision_id
    }
  }
`
export const GET_MAGAZINE_LIST = gql`
  query($lang: String!) {
    result: cms_magazine(
      where: { status: { _neq: "archived" }, lang: { _eq: $lang } }
      order_by: { updated_at: desc }
    ) {
      id
      revision_id
      title
      description
      start_cover_date
      end_cover_date
      is_new
      thumbnail {
        mime_type
        file_name
        file_size
        file_url
        width
        height
      }
      file {
        mime_type
        file_name
        file_size
        file_url
      }
      created_at
      updated_at
      created_by
      updated_by
    }
  }
`

export const GET_MAGAZINE_ITEM = gql`
  query($revision_id: uuid!) {
    result: cms_magazine_by_pk(revision_id: $revision_id) {
      id
      revision_id
      title
      description
      start_cover_date
      end_cover_date
      status
      thumbnail_id
      file_id
      thumbnail {
        mime_type
        file_name
        file_size
        file_url
        width
        height
        resource_type
      }
      file {
        mime_type
        file_name
        file_size
        file_url
        width
        height
        resource_type
      }
      folder_name
      is_new
      created_at
      updated_at
      created_by
      updated_by
    }
  }
`
