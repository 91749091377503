import gql from 'graphql-tag'

export const FETCH_USER = gql`
  query {
    result: user_user(order_by: { updated_at: desc }) {
      id
      display_name
      avatar_url
      accounts {
        active
        email
        account_roles {
          role
        }
        created_at
      }
      group {
        group_name
      }
      user_group_links {
        user_group {
          id
          group_name
        }
      }
    }
  }
`

export const FETCH_USER_BY_ID = gql`
  query ($id: uuid!){
    result: user_user_by_pk(id: $id) {
      id
      display_name
      avatar_url
      setting
      accounts {
        active
        email
        account_roles {
          role
        }
        account_providers {
          auth_provider_unique_id
        }
        created_at
      }
      group {
        id
        group_name
      }
      user_group_links {
        user_group {
          id
          group_name
        }
      }
    }
  }
`

export const CREATE_USER = gql`
  mutation(
    $display_name: String!
    $group_id: [uuid]
    $email: String!
    $password: String!
    $password_hash: String!
    $role: String!
    $setting: jsonb
  ) {
    register_user(
      display_name: $display_name
      group_id: $group_id
      email: $email
      password: $password
      password_hash: $password_hash
      role: $role
      setting: $setting
      send_email: true
    ) {
      id
      uid
    }
  }
`

export const UPDATE_USER_BY_ID = gql`
  mutation updateUser(
    $id: uuid!,
    $name: String,
    $group_id: [user_user_group_link_insert_input!]!,
    $role: String,
    $is_active: Boolean,
    $setting: jsonb,
    $password: String!,
    $uid: String!,
    $passwordHash: String!
  ) {
    user: update_user_user_by_pk (
      pk_columns: { id: $id }
      _set: {
        display_name: $name,
        setting: $setting
      }
    ) {
      id
    }

    delete_user_user_group_link(where: { user_id: { _eq: $id } }) {
      affected_rows
    }
    
    insert_user_user_group_link(objects: $group_id) {
      returning {
        uuid
      }
    }

    account_role: update_auth_account_role (
      where: { account: { user: { id: { _eq: $id } } } }
      _set: {
        role: $role
      }
    ) {
      affected_rows
    }
    
    account: update_auth_account (
      where: { user: { id: { _eq: $id } } }
      _set: {
        default_role: $role
        active: $is_active
        password_hash: $passwordHash
      }
    ) {
      affected_rows
    }

    result_change_password: change_password (
      new_password: $password, uid: $uid){
      result
    }
  }
`

export const UPDATE_ONLT_USER_INFO_BY_ID = gql`
  mutation updateUser(
    $id: uuid!,
    $name: String,
    $group_id: [user_user_group_link_insert_input!]!,
    $role: String,
    $is_active: Boolean,
    $setting: jsonb
  ) {
    user: update_user_user_by_pk (
      pk_columns: { id: $id }
      _set: {
        display_name: $name,
        setting: $setting
      }
    ) {
      id
    }

    delete_user_user_group_link(where: { user_id: { _eq: $id } }) {
      affected_rows
    }
    insert_user_user_group_link(objects: $group_id) {
      returning {
        uuid
      }
    }

    account_role: update_auth_account_role (
      where: { account: { user: { id: { _eq: $id } } } }
      _set: {
        role: $role
      }
    ) {
      affected_rows
    }
    
    account: update_auth_account (
      where: { user: { id: { _eq: $id } } }
      _set: {
        default_role: $role
        active: $is_active
      }
    ) {
      affected_rows
    }
  }
`

export const DELETE_USER_BY_ID = gql`
  mutation deleteUser($id: uuid!) {
    result:update_user_user_by_pk(
      pk_columns: { id: $id },
      _set: { group_name: $group_name }
    ) {
      id
    }
  }
`

// ------- User Group --------

export const FETCH_USER_GROUP = gql`
  query {
    result: user_user_group(order_by: { created_at: desc }) {
      id
      group_name
      group_permission
      updated_at
    }
  }
`
export const FETCH_USER_GROUP_BY_ID = gql`
  query ($id: uuid!){
    result: user_user_group_by_pk(id: $id) {
      id
      group_name
      group_permission
      updated_at
    }
  }
`

export const CREATE_USER_GROUP = gql`
  mutation createUserGroup($group_name: String!) {
    result:insert_user_user_group_one(object: {
      group_name: $group_name
    }) {
      id
    }
  }
`

export const UPDATE_USER_GROUP_BY_ID = gql`
  mutation updateUserGroup($id: uuid!, $group_name: String!) {
    result:update_user_user_group_by_pk(
      pk_columns: { id: $id },
      _set: { group_name: $group_name }
    ) {
      id
    }
  }
`

export const DELETE_USER_GROUP_BY_ID = gql`
  mutation deleteUserGroup($id: uuid!) {
    result:delete_user_user_group_by_pk(id: $id) {
      id
    }
  }
`

export const FETCH_ACCOUNT_ROLE = gql`
  query {
    result: auth_roles(
      where: { role: { _nin: ["user", "anonymous", "guest", "admin"] } }
    ) {
      role
    }
  }
`

