import map from 'lodash/map'
import get from 'lodash/get'
import omit from 'lodash/omit'
import forEach from 'lodash/forEach'
import isEmpty from 'lodash/isEmpty'
import { createProvider } from '@/vue-apollo'
import {
  buildRequestBody,
  buildFileRequestBody,
} from '@/helper/Utils'

import {
  FETCH,
  FETCH_ALL,
  CREATE,
  UPDATE,
  UPDATE_PRIORITY,
  DELETE,
  FIND_ONE_BY_ID,
  GET_PROJECT_BRAND_ID,
  CREATE_FOLDER_NAME,
  FIND_ONE_BY_ID_LANG,
  GET_LIST_BY_SLUG
} from './constantGql'

import BRAND_ATTRIBUTE from './attributeJson.json'

const getProjectByBrandId = async (brandId) => {
  const apollo = createProvider().clients.defaultClient

  const {
    data: { result },
  } = await apollo.query({
    query: GET_PROJECT_BRAND_ID,
    variables: { brandId },
  })

  return result
}

export default {
  async checkSlugExist({ commit }, data) {
    const apollo = createProvider().clients.defaultClient
    const response = await apollo.query({
      query: GET_LIST_BY_SLUG,
      variables: {
        brand_lang_id: data.brand_lang_id,
        slug: data.slug
      },
    })
  
    const result = get(response, ['data', 'result'], [])
    const isExist = !isEmpty(result)
    commit('SET_IS_SLUG_EXIST', isExist)

    return isExist
  },
  async fetch(context) {
    const apollo = createProvider().clients.defaultClient
    const {
      data: { result },
    } = await apollo.query({
      query: FETCH,
      variables: {
        lang: context.rootState.locale.currentLang,
      },
    })

    // parse data
    const parsedData = map(result, (item) => {
      const { id, revision_id, updated_at, priority, slug } = item
      const currentItem = { id, revision_id, updated_at, priority, slug }

      forEach(item.brand_translates, ({ attribute_code, value }) => {
        currentItem[attribute_code] = value
      })

      forEach(item.brand_files, ({ attribute_code, image }) => {
        currentItem[attribute_code] = image
      })

      return currentItem
    })

    context.commit('SET_ITEMS', parsedData)
  },

  async fetchOne(context, id) {
    const apollo = createProvider().clients.defaultClient
    const {
      data: { result },
    } = await apollo.query({
      query: FIND_ONE_BY_ID,
      variables: { id },
    })

    const parsedResult = {
      id: result.id,
      slug: result.slug,
      brand_lang_id: result.brand_lang_id,
      priority: result.priority,
      lang: result.lang,
      attributes: {},
      file_attributes: {},
      updated_at: result.updated_at,
      created_by: result.created_by,
      updated_by: result.updated_by,
    }

    forEach(result.brand_translates, ({ attribute_code, value }) => {
      parsedResult[attribute_code] = value
    })

    forEach(result.brand_files, ({ attribute_code, image }) => {
      parsedResult[attribute_code] = image
    })

    if (!isEmpty(result.brand_talks)) {
      parsedResult.brand_talks = result.brand_talks.map((brandTalk) => {
        const brandTalkTranslates = get(brandTalk, 'brand_talk_translates', [])
        const brandTalkData = {
          position: brandTalk.position
        }
        forEach(brandTalkTranslates, ({ attribute_code, value }) => {
          brandTalkData[attribute_code] = value
        })
        return brandTalkData
      })
    }

    return parsedResult
  },

  async addItem({ rootState }, data) {
    const apollo = createProvider().clients.defaultClient
    const lang = rootState.locale.currentLang

    const rawFile = get(data, 'brand_files', [])
    const parsedFile = buildFileRequestBody(
      rawFile,
      BRAND_ATTRIBUTE.brand.brand_files,
    )
    data.brand_files = parsedFile
    data.lang = rootState.locale.currentLang

    const body = buildRequestBody(
      { brand: data },
      'brand',
      BRAND_ATTRIBUTE,
      lang,
    )
    
    const {
      data: { result },
    } = await apollo.mutate({
      mutation: CREATE,
      variables: { toCreateItem: body.brand },
    })

    return result
  },

  async updateItem({ rootState }, data) {
    const apollo = createProvider().clients.defaultClient
    const lang = rootState.locale.currentLang
    const rawFile = get(data, 'brand_files', [])
    const parsedFile = buildFileRequestBody(
      rawFile,
      BRAND_ATTRIBUTE.brand.brand_files,
    )
    data.brand_files = parsedFile
    data.lang = lang

    const body = buildRequestBody(
      { brand: data },
      'brand',
      BRAND_ATTRIBUTE,
      lang,
    )
    // const revisionData = await getItemByUuid(data.uuid)

    const translates = body.brand.brand_translates

    const brandTranslate = isEmpty(translates)
      ? []
      : translates.data.map((translate) => ({
          ...translate,
          brand_id: data.id,
        }))

    const files = body.brand.brand_files
    const brandFile = isEmpty(files)
      ? []
      : files.data.map((file) => ({
          ...file,
          brand_id: data.id,
        }))

    const rawBrandTalk = body.brand.brand_talks
    const brandTalk = isEmpty(rawBrandTalk)
      ? []
      : rawBrandTalk.data.map((bt) => ({
          ...bt,
          brand_id: data.id,
        }))

    await apollo.mutate({
      mutation: UPDATE,
      // Parameters
      variables: {
        brand: omit(body.brand, [
          'brand_translates',
          'brand_files',
          'brand_talks',
        ]),
        brandTranslate,
        brandFile,
        brandTalk,
        id: data.id,
        brand_lang_id: data.brand_lang_id,
        slug: data.slug
      },
    })

    // const response = get(rawResponse, ['data', 'update_property_brand_by_pk'])
    // commit('UPDATE_ITEM', response)

    return true
  },

  async createFolderName(context, formData) {
    const apollo = createProvider().clients.defaultClient

    await apollo.mutate({
      mutation: CREATE_FOLDER_NAME,
      variables: {
        folderName: {
          brand_id: formData.id,
          lang: context.rootState.locale.currentLang,
          attribute_code: "brand_folder_name",
          value: get(formData, ['attributes', 'brand_folder_name', 'value'], null)
        }
      },
    })

    return true
  },

  async updateItemPriority(context, formData = []) {
    const apollo = createProvider().clients.defaultClient

    await Promise.all(
      formData.map((item, key) => {
        return apollo.mutate({
          mutation: UPDATE_PRIORITY,
          variables: { id: item.id, priority: key },
        })
      }),
    )

    return true
  },

  async delete(context, id) {
    const projects = await getProjectByBrandId(id)
    let canDelete = true

    if (projects.length > 0) {
      canDelete = false
    }

    if (canDelete) {
      const apollo = createProvider().clients.defaultClient

      const result = await apollo.mutate({
        mutation: DELETE,
        variables: { id },
      })

      return result
    } else {
      throw {
        message: 'ไม่สามารถลบ brand ได้ มีโครงการใช้งานอยู่'
      }
    }
  },

  async fetchByLang(context, { id, lang }) {
    const apollo = createProvider().clients.defaultClient
    const {
      data: { result },
    } = await apollo.query({
      query: FIND_ONE_BY_ID_LANG,
      variables: { id, lang },
    })
    return result
  },

  async fetchAll() {
    const apollo = createProvider().clients.defaultClient
    const {
      data: { result },
    } = await apollo.query({
      query: FETCH_ALL
    })

    // parse data
    return result
  }
}
