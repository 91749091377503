<template>
  <div :class="[customClass || 'vx-col w-full md:w-3/12 md:text-right pt-2']">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'FormLabel',
  props: {
    customClass: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped></style>
