import gql from 'graphql-tag'

export const GET_HOUSE_FUNCTION_LIST = gql`
  query Languages ($lang: String!){
    house_function: property_house_function(
      where: {
        lang: {_eq: $lang}
        status: { _neq: "archived" }
      }
      order_by: { is_required: desc, updated_at: desc }
    ) {
      id
      lang
      uuid
      updated_at
      created_at
      created_by
      updated_by
      is_required
      house_function_translates {
        lang
        value
        attribute_code
      }
    }
  }
`

export const GET_HOUSE_FUNCTION_ITEM = gql`
  query Languages($uuid: uuid!) {
    house_function: property_house_function(
      where: { status: { _neq: "archived" }, uuid: { _eq: $uuid } }
    ) {
      id
      lang
      uuid
      updated_at
      created_at
      created_by
      updated_by
      is_required
      house_function_translates {
        lang
        value
        attribute_code
      }
    }
  }
`

export const ADD_HOUSE_FUNCTION = gql`
  mutation HouseFunction($houseFunction: property_house_function_insert_input!) {
    insert_property_house_function_one(object: $houseFunction) {
      id
      uuid
    }
  }
`

export const UPDATE_HOUSE_FUNCTION = gql`
  mutation HouseFunction(
    $houseFunction: property_house_function_set_input!
    $houseFunctionTranslate: [property_house_function_translate_insert_input!]!
    $uuid: uuid!
    $lang: String!
  ) {
    update_property_house_function_by_pk(
      _set: $houseFunction
      pk_columns: { uuid: $uuid }
    ) {
      id
    }
    delete_property_house_function_translate(
      where: { house_function_uuid: { _eq: $uuid }, lang: { _eq: $lang } }
    ) {
      affected_rows
    }
    insert_property_house_function_translate(objects: $houseFunctionTranslate) {
      returning {
        uuid
      }
    }
  }
`

export const DELETE_HOUSE_FUNCTION = gql`
  mutation HouseFunction($uuid: uuid!, $revisionData: jsonb!) {
    insert_property_house_revision_one(
      object: {
        revision_uuid: $uuid
        revision_table: "house_function"
        revision_data: $revisionData
      }
    ) {
      uuid
    }
    delete_property_house_function_by_pk(uuid: $uuid) {
      uuid
    }
  }
`

export const GET_EXIST_LANG_BY_ID = gql`
  query GET_EXIST_PROJECT_ID_BY_LANG($id: Int!) {
    house_function: property_house_function(where: {
      status: { _neq: "archived" },
      id: {_eq: $id}
    }) {
      lang
    }
  }
`
