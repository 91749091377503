import isEmpty from 'lodash/isEmpty'
export default {
  ADD_HOUSE_FUNCTION_FEATURE(state, item) {
    state.items.unshift(item)
  },
  SET_ITEMS(state, items) {
    state.items = items
  },
  SET_ITEM(state, item) {
    state.item = item
  },
  UPDATE_ITEM(state, item) {
    if (!isEmpty(state.items)) {
      const itemIndex = state.items.findIndex((p) => p.id === item.id)
      Object.assign(state.items[itemIndex], item)
    }
  },
  DELETE_ITEM(state, uuid = '') {
    const ItemIndex = state.items.findIndex((p) => p.uuid === uuid)
    state.items.splice(ItemIndex, 1)
  },
}
