import get from 'lodash/get'
import { createProvider } from '@/vue-apollo'
import { FETCH } from './constantGql'
import {
  buildList,
} from '@/helper/Utils'

export default {
  async fetch({ commit, rootState }) {
    const apollo = createProvider().clients.defaultClient
    const response = await apollo.query({ query: FETCH })

    const lang = rootState.locale.currentLang
    const provinces = get(response, ['data', 'provinces'], [])

    const provinceData = buildList(provinces, 'province_translates', lang)

    commit('SET_ITEMS', provinceData)
    return provinceData
  },
}
