export default {
  items: [],
  pageList: [
    {
      label: 'หน้า Home',
      value: 'home',
    },
    {
      label: 'บ้านเดี่ยว',
      value: 'singlehome',
    },
    {
      label: 'บ้านตกแต่งพร้อมขาย',
      value: 'furnished-home',
    },
    {
      label: 'ทาว์โฮม',
      value: 'townhome',
    },
    {
      label: 'คอนโดมิเนียม',
      value: 'condominium',
    },
    {
      label: 'ข่าวโครงการใหม่',
      value: 'newproject',
    },
    {
      label: 'โครงการในต่างจังหวัด',
      value: 'major-cities',
    },
    {
      label: 'Ladawan',
      value: 'ladawan',
    },
    {
      label: 'แบบบ้าน',
      value: 'home-series',
    },
    {
      label: 'Motivo Magazine',
      value: 'motivo-magazine',
    },
    {
      label: 'สอบถามข้อมูลโครงการ',
      value: 'contact-us',
    },
    {
      label: 'แจ้งปัญหาการใช้งานบนเว็บไซต์',
      value: 'report',
    },
  ],
  loading: false,
}
