import get from 'lodash/get'
import omit from 'lodash/omit'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import isArray from 'lodash/isArray'
import orderBy from 'lodash/orderBy'
import moment from 'moment'
import env from '@/env'
import { deleteBr } from './Utils'

const getAttributeValueWithDefaultValue = (data, codes = []) => {
  const result = {}
  codes.forEach((code) => {
    result[code] = get(data, code, null)
  })

  return result
}

export const getBasicInfo = (projectDetail, oldProject = null) => {
  // initial basic info
  const projectCategoryLinks = get(projectDetail, ['project_category_links'], [])

  let urls = []
  if (projectCategoryLinks.length > 0) {
    urls = projectCategoryLinks.map((catLink) => `${env.VUE_APP_WEBSITE_URL}/${projectDetail.lang}/${catLink.url}`)
  }
  return {
    title: get(projectDetail, 'title', null),
    project_old_title: oldProject ? get(oldProject, [oldProject.lang, 'title'], null) : null,
    project_original_language: oldProject ? oldProject.lang : null,
    brand_id: get(projectDetail, 'brand_id', null),
    group_id: get(projectDetail, 'group_id', null),
    urls,
    slug: projectDetail.slug,
    show_brand_talk: get(projectDetail, 'show_brand_talk', 'true') === 'true',
  }
}

export const getPropertyInfo = (projectDetail, lang = 'th') => {
  const info = getAttributeValueWithDefaultValue(projectDetail, [
    'area_rai',
    'area_wa',
    'area_ngan',
    'project_code',
    'company_code',
    'room_unit',
    'neighborhood',
    'plot',
  ])
  const pageLinks = get(projectDetail, 'project_page_links', []).map(
    (pageLink) => pageLink.page_id,
  )
  const propertyTypes = get(projectDetail, 'project_category_links', []).map(
    (catLink) => catLink.property_category_id,
  )
  let propertyList = get(projectDetail, 'project_properties', []).map((property) => {
    const projectPropertyUnits = get(property, 'project_property_units', []).map(
      (unit) => ({
        ...unit[lang],
        ...omit(unit, lang),
      }),
    )

    const projectPropertyBuildings = get(property, 'project_property_buildings', []).map(
      (building) => ({
        ...building[lang],
        ...omit(building, lang),
      }),
    )

    const property_category_id = get(property, ['property_category', 'id'], null)

    return property_category_id ? {
      ...property[lang],
      property_category_id,
      project_property_units: isEmpty(projectPropertyUnits) ? [{}] : projectPropertyUnits,
      project_property_buildings: isEmpty(projectPropertyBuildings)
        ? [{}]
        : projectPropertyBuildings,
      ...omit(property, [lang, 'project_property_units', 'project_property_buildings']),
    } : null
  })

  propertyList = propertyList.filter((property) => !isEmpty(property))

  if (isEmpty(propertyList)) {
    propertyList = [
      {
        project_property_units: [{}],
        project_property_buildings: [{}],
      },
    ]
  }

  info.propertyList = propertyList
  info.pageLinks = pageLinks
  info.propertyTypes = propertyTypes

  return info
}

export const getInfo = (projectDetail) => {
  let result = getAttributeValueWithDefaultValue(projectDetail, [
    'concept',
    'highlight',
    'facility',
    'security',
    'ocpb',
    'location',
    'parking',
    'price',
    'price_to',
    'price_from',
  ])

  result = deleteBr(result)

  let priceType = 'price'

  if (!isNil(result.price_from) || !isNil(result.price_to)) {
    priceType = 'price_range'
  } else if (!isNil(result.price_per_metre)) {
    priceType = 'price_per_metre'
  }

  if (result.price) {
    const price = get(result, 'price', '0')
    result.price = new Intl.NumberFormat().format(price)
  } else if (result.price_per_metre) {
    const pricePerMetre = get(result, 'price_per_metre', '0')
    result.price_per_metre = new Intl.NumberFormat().format(pricePerMetre)
  } else if (result.price_to && result.price_from) {
    const priceTo = get(result, 'price_to', '0')
    const priceFrom = get(result, 'price_from', '0')

    result.price_to = new Intl.NumberFormat().format(priceTo)
    result.price_from = new Intl.NumberFormat().format(priceFrom)
  } else {
    priceType = "no_price"
  }

  result.price_type = priceType
  return result
}

export const getContactInfo = (projectDetail) => {
  const result = getAttributeValueWithDefaultValue(projectDetail, [
    'open_time',
    'close_time',
    'telephone',
    'email_address',
    'direct_telephone',
    'line_contact',
    'sale_name',
    'common_fee',
    'common_fee_unit',
  ])

  const projectCommonFeeDate = get(projectDetail, 'common_fee_date', null)
  result.common_fee_date = !projectCommonFeeDate
    ? moment().format()
    : moment(projectCommonFeeDate, 'DD/MM/YYYY').format()

  const projectOfficeDay = get(projectDetail, 'office_day', '')
  const projectOfficeDayType = get(projectDetail, 'office_day_type', 'all_day')

  result.office_day_type = projectOfficeDayType
  if (!isEmpty(projectOfficeDay)) {
    result.office_days = projectOfficeDay.split(',')
  } else {
    result.office_days = []
  }

  const locationFlm = get(projectDetail, 'location_flm', '')
  if (!isEmpty(locationFlm)) {
    result.location_flm = locationFlm.split(',')
  } else {
    result.location_flm = []
  }

  // delete @lh.co.th
  const emailAddress = get(projectDetail, 'email_address', '')
  if (!isEmpty(emailAddress)) {
    // get only name email
    result.email_address = emailAddress.split('@')[0]
  }

  return result
}

export const getSeoInfo = (projectDetail) => {
  const result = getAttributeValueWithDefaultValue(projectDetail, [
    'meta_title',
    'meta_keyword',
    'meta_description',
  ])

  return deleteBr(result)
}

export const getAdminInfo = (projectDetail) => {
  const result = getAttributeValueWithDefaultValue(projectDetail, [
    'project_status',
    'url_register',
  ])

  const publishedDate = get(projectDetail, 'published_date', null)
  result.published_date = !publishedDate
    ? null
    : moment(publishedDate, 'DD/MM/YYYY').format() 

  const status = get(projectDetail, 'project_status', null)
  const newProjectEndDate = get(projectDetail, 'new_project_end_date', null)
  result.new_project_end_date = !newProjectEndDate || status !== 'new_project'
    ? moment().add(6, 'M').format()
    : moment(newProjectEndDate, 'DD/MM/YYYY').format()

  return result
}

export const getFile = (projectDetail, attribute = [], lang = 'th') => {
  const projectFiles = get(projectDetail, 'project_files', [])
  const result = {
    id: projectDetail.id,
  }

  attribute.forEach((code) => {
    result[code] = []
  })
  projectFiles.forEach((value) => {
    if (isEmpty(value.public_id) || isEmpty(value.image)) {
      return
    }

    if (attribute.includes(value.attribute_code)) {
      result[value.attribute_code].push({
        ...value.image,
        ...get(value, [lang]),
        attribute_code: value.attribute_code,
        public_id: value.public_id,
      })
    }
  })

  // order value
  const keys = Object.keys(result)
  keys.forEach((key) => {
    if (isArray(result[key])) {
      result[key] = orderBy(result[key], ['image_index'], ['asc'])
    }
  })
  return result
}

export const getVideoInfo = (projectDetail, attribute = [], lang = 'th') => {
  const projectFiles = get(projectDetail, 'project_files', [])
  const result = {
    id: projectDetail.id,
  }

  projectFiles.forEach((value) => {
    if (isEmpty(value.public_id) || isEmpty(value.image)) {
      return
    }

    if (attribute.includes(value.attribute_code)) {
      if (isEmpty(result[value.attribute_code])) result[value.attribute_code] = []
      result[value.attribute_code].push({
        ...value.image,
        ...get(value, [lang]),
        public_id: value.public_id,
        attribute_code: value.attribute_code,
      })
    }
  })

  const temp = getAttributeValueWithDefaultValue(projectDetail, [
    'video_type',
    'video_url',
    'image_360_url',
  ])

  return {
    ...result,
    ...temp,
  }
}

export const getUnitInfo = (projectDetail, lang = 'th') => {
  const result = {
    id: projectDetail.id,
  }
  const rawUnitPlanList = get(projectDetail, 'project_unit_plans', [])
  const unitPlanList = isEmpty(rawUnitPlanList)
    ? [{}]
    : rawUnitPlanList.map((unitPlan) => {
        return {
          ...unitPlan[lang],
          ...omit(unitPlan, [lang, 'typename', 'public_id', 'image']),
          unit_plan_image: !isEmpty(unitPlan.image) ? [
            {
              ...unitPlan.image,
            },
          ] : [],
        }
      })

  result.unitPlanList = unitPlanList

  return result
}

export const flatImage = (image) => {
  if (image.image) {
    return flatImage(image.image)
  }
  return image
}

export const getFacilityPropertyInfo = (projectDetail, lang = 'th') => {
  const rawPropertyFacilityList = get(projectDetail, 'project_property_facilities', [])
  const result = {
    id: projectDetail.id,
  }

  const propertyFacilityList = isEmpty(rawPropertyFacilityList)
    ? [{}]
    : rawPropertyFacilityList.map((facility) => {
        return {
          ...facility[lang],
          ...omit(facility, [lang, 'typename'], 'public_id', 'image'),
          property_facility_image: !isEmpty(facility.image) ? [
            {
              ...facility.image,
            },
          ] : [],
        }
      })

  result.propertyFacilityList = propertyFacilityList

  return result
}

export const getProgressInfo = (projectDetail, lang = 'th') => {
  const rawProgressList = get(projectDetail, 'project_property_progresses', [])
  const result = {
    id: projectDetail.id,
  }

  const progressList = isEmpty(rawProgressList)
    ? [
        {
          projectPropertyProgressFiles: [],
        },
      ]
    : rawProgressList.map((progress) => {
        const parseValue = {
          ...omit(progress, [lang, 'typename', 'project_property_progress_files']),
          ...progress[lang],
        }

        let projectPropertyProgressFiles = []

        const rawFiles = get(progress, 'project_property_progress_files', [])
        if (rawFiles.length > 0) {
          const files = rawFiles.map((file) => {
            return {
              ...omit(file, [lang, 'typename']),
              ...flatImage(file.image),
              ...file[lang],
            }
          })
          projectPropertyProgressFiles = files
        }

        parseValue.projectPropertyProgressFiles = orderBy(projectPropertyProgressFiles, ['image_index'], ['asc'])

        return parseValue
      })

  result.progressList = progressList

  return result
}

export const getLocationInfo = (projectDetail, lang = 'th') => {
  const result = getAttributeValueWithDefaultValue(projectDetail, [
    'latitude',
    'longitude',
    'map',
  ])
  const facilityList = get(projectDetail, 'project_facilities', [{}]).map((facility) => ({
    ...facility[lang],
  }))

  result.facilityList = facilityList
  return result
}

export const getBanner = (projectDetail, lang = 'th') => {
  const projectFiles = get(projectDetail, 'project_files', [])
  const startDate = get(projectDetail, 'project_banner_start_date', '')
  const endDate = get(projectDetail, 'project_banner_end_date', '')
  const colour = get(projectDetail, 'project_banner_activity_background_colour', null)
  const event = {
    event_desktop_banner: [],
    event_mobile_banner: [],
    event_type: get(projectDetail, 'project_banner_type', ''),
    event_background_color: isEmpty(colour) ? {} : JSON.parse(colour),
    event_activity_image: [],
    event_activity_content: get(projectDetail, 'project_banner_activity_content', ''),
    event_video_type: get(projectDetail, 'project_banner_video_type', ''),
    event_video_url: get(projectDetail, 'project_banner_video_url', ''),
    event_video_thumbnail: [],
    event_video_file: [],
  }

  projectFiles.forEach((value) => {
    if (isEmpty(value.public_id) || isEmpty(value.image)) {
      return
    }
    const translateValue = get(value, [lang])

    if (value.attribute_code === 'project_banner_image') {
      const temp_image_start_date = get(translateValue, 'image_start_date', null)
      const temp_image_end_date = get(translateValue, 'image_end_date', null)
      const image_start_date = !isEmpty(temp_image_start_date)
        ? moment(temp_image_start_date, 'DD/MM/YYYY').format()
        : null
      const image_end_date = !isEmpty(temp_image_end_date)
        ? moment(temp_image_end_date, 'DD/MM/YYYY').format()
        : null

      event.event_desktop_banner.push({
        ...value.image,
        ...translateValue,
        image_start_date,
        image_end_date,
        public_id: value.public_id,
        attribute_code: value.attribute_code,
      })
    } else if (value.attribute_code === 'project_banner_video_file') {
      event.event_video_file.push({
        ...value.image,
        ...translateValue,
        public_id: value.public_id,
        attribute_code: value.attribute_code,
      })
    } else if (value.attribute_code === 'project_banner_video_thumbnail') {
      event.event_video_thumbnail.push({
        ...value.image,
        ...translateValue,
        public_id: value.public_id,
        attribute_code: value.attribute_code,
      })
    } else if (value.attribute_code === 'project_banner_activity_image') {
      event.event_activity_image.push({
        ...value.image,
        ...translateValue,
        public_id: value.public_id,
        attribute_code: value.attribute_code,
      })
    }
  })

  const isEvent = get(projectDetail, 'has_project_banner_image', false)

  const result = {
    id: projectDetail.id,
    is_event: isEvent === 'true' || isEvent === true,
    page_url: {
      value: 'project',
    },
    event,
    event_start_date: isEmpty(startDate) ? '' : moment(startDate, 'DD/MM/YYYY').format(),
    event_end_date: isEmpty(endDate) ? '' : moment(endDate, 'DD/MM/YYYY').format(),
  }

  return result
}
