const psEnv = Object.keys(process.env).reduce((config, name) => {
  if (name.startsWith('VUE_APP_')) return { ...config, [name]: process.env[name] }
  return config
}, {})

export default (function getEnv() {
  try {
    return { ...window.env, ...psEnv }
  } catch (_) {
    return psEnv
  }
})()
