var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.vueAppClasses,attrs:{"id":"app"}},[_c('transition',{attrs:{"name":"fade"}},[(_vm.toggler)?_c('div',{staticClass:"fixed w-full",style:({
        zIndex: '1000001'
      })},[_c('div',{staticClass:"fixed bg-dark w-full h-screen opacity-75",style:({
          zIndex: '1000001'
        }),on:{"click":function($event){_vm.toggler = !_vm.toggler}}}),_c('vs-button',{staticClass:"absolute top-0 right-0 p-2 m-8",style:({
          zIndex: '1000002'
        }),attrs:{"radius":"","color":"warning","type":"filled","size":"large","icon-pack":"feather","icon":"icon-x"},on:{"click":function($event){_vm.toggler = !_vm.toggler}}}),_c('div',{staticClass:"flex flex-col justify-center items-center h-screen"},[_c('div',{staticClass:"flex justify-center",style:({
            zIndex: '1000001'
          })},[_c('img',{staticClass:"object-center w-10/12 p-4 rounded bg-white",attrs:{"src":_vm.sources[0],"alt":"Image preview"}})])])],1):_vm._e()]),_c('router-view',{on:{"setAppClasses":_vm.setAppClasses}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }