import gql from 'graphql-tag'

export const GET_UNIT = gql`
  query Languages {
    units: property_unit(where: { attribute_code: { _eq: "distance_unit" } }) {
      id
      unit_labels {
        lang
        value
      }
    }
  }
`
