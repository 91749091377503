import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import forEach from 'lodash/forEach'
import { createProvider } from '@/vue-apollo'
import { CREATE, UPDATE, DELETE, FETCH, FIND_ONE_BY_ID, GET_LIST_BY_SLUG } from './constantGql'

const checkNameExist = (existDataList, name, lang, id = null) => {
  const existData = existDataList.find((type) => {
    return type.property_category_translates.find((translate) => {
      return translate.lang === lang && translate.value === name && parseInt(id) !== translate.id
    }) 
  })
  
  if (existData) throw "มีชื่อนี้อยู่ในระบบแล้ว"
}

export default {
  async checkSlugExist(_, data) {
    const apollo = createProvider().clients.defaultClient
    const response = await apollo.query({
      query: GET_LIST_BY_SLUG,
      variables: {
        id: data.id,
        slug: data.slug
      },
    })
  
    const result = get(response, ['data', 'result'], [])
    const isExist = !isEmpty(result)

    return isExist
  },
  async fetch({ commit }) {
    const apollo = createProvider().clients.defaultClient
    const response = await apollo.query({ query: FETCH })
    const propertyTypes =  get(response, ['data', 'result'], [])
    commit('SET_ITEMS', propertyTypes)
    return propertyTypes
  },

  async fetchOne(context, id) {
    const apollo = createProvider().clients.defaultClient
    const response = await apollo.query({
      query: FIND_ONE_BY_ID,
      variables: { id },
    })
    return get(response, ['data', 'result'])
  },

  async addItem(context, data) {
    const { title, code, property_type, created_by, updated_by, slug } = data

    const propertyCategory = {
      code,
      property_type,
      created_by,
      updated_by,
      slug,
      property_category_translates: {
        data: []
      }
    }

    const existTypes = await context.dispatch('fetch')

    forEach(title, ({ value }, key) => {
      if (!isEmpty(value)) {
        try {
          checkNameExist(existTypes, value, key)
        } catch (e) {
          throw e
        }

        propertyCategory.property_category_translates.data.push({
          attribute_code: 'project_category_name',
          lang: key,
          value,
        })
      }
    })

    const apollo = createProvider().clients.defaultClient
    const {
      data: { result },
    } = await apollo.mutate({ mutation: CREATE, variables: {propertyCategory} })

    return result.id
  },

  async update(context, data) {
    const { id, title, code, property_type, created_by, updated_by, slug } = data

    const existTypes = await context.dispatch('fetch')
    const langs = ['th', 'en', 'cn']
    const propertyTranslate = []

    langs.forEach((lang) => {
      const titleLang = get(title, [lang, 'value'], null)
      const titleId = get(title, [lang, 'id'], null)

      try {
        checkNameExist(existTypes, titleLang, lang, titleId)
      } catch (e) {
        throw e
      }

      if (titleLang) propertyTranslate.push({
        lang,
        property_category_id: id,
        value: titleLang,
        attribute_code: "project_category_name"
      })
    })
    
    const variables = {
      id,
      propertyCategory: {
        code,
        slug,
        property_type,
        created_by,
        updated_by
      },
      propertyTranslate
    }

    const apollo = createProvider().clients.defaultClient
    const {
      data: { result },
    } = await apollo.mutate({ mutation: UPDATE, variables })
    return result.id
  },

  async delete({ dispatch }, id) {
    const apollo = createProvider().clients.defaultClient
    const response = await apollo.mutate({
      mutation: DELETE,
      variables: { id },
    })

    console.log(response)

    await dispatch('fetch')
  },
}
