import get from 'lodash/get'
import omit from 'lodash/omit'
import { createProvider } from '@/vue-apollo'
import {
  FETCH,
  CREATE_WITH_POSITION,
  CREATE_WITHOUT_POSITION,
  UPDATE_WITH_POSITION,
  UPDATE_WITHOUT_POSITION,
  DELETE,
  FIND_ONE_BY_ID,
  FETCH_ALL
} from './constantGql'

export default {
  async fetchAll() {
    const apollo = createProvider().clients.defaultClient
    const {
      data: { result },
    } = await apollo.query({
      query: FETCH_ALL
    })

    // parse data
    return result
  },
  async fetch(context) {
    const apollo = createProvider().clients.defaultClient
    const response = await apollo.query({
      query: FETCH,
      variables: {
        lang: context.rootState.locale.currentLang,
      },
    })

    context.commit('SET_ITEMS', get(response, ['data', 'result']))
  },

  async fetchOne(context, id) {
    const apollo = createProvider().clients.defaultClient
    const response = await apollo.query({
      query: FIND_ONE_BY_ID,
      variables: { id },
    })
    return get(response, ['data', 'result'])
  },

  async addItem(context, data) {
    const apollo = createProvider().clients.defaultClient
    let query = CREATE_WITHOUT_POSITION
    const variables = {
      toCreateItem: {
        ...omit(data, ['id', 'created_at']),
        lang: context.rootState.locale.currentLang,
      },
      lang: context.rootState.locale.currentLang
    }
    if (data.position) {
      query = CREATE_WITH_POSITION
      variables.positionToClean = data.position
    }

    try {
      const {
        data: { result },
      } = await apollo.mutate({
        mutation: query,
        variables,
      })
      
      return result
    } catch (e) {
      if (e.message.split('unique constraint "content_slug_lang_key"').length > 1) {
        throw 'ไม่สามารถบันทึกข้อมูลได้ เนื่องจากชื่อ หรือ URL พิเศษซ้ำกับข้อมูลที่มีอยู่ในระบบ'
      }
      throw 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง'
    }
  },

  async updateItem(context, { initialData, formData }) {
    const apollo = createProvider().clients.defaultClient

    let query = UPDATE_WITHOUT_POSITION
    const variables = {
      id: formData.id,
      toUpdateItem: formData,
      toDeleteItem: omit(initialData, ['status']),
      lang: context.rootState.locale.currentLang,
      customSlug: formData.custom_slug
    }

    if (formData.position) {
      query = UPDATE_WITH_POSITION
      variables.positionToClean = formData.position
    }

    const {
      data: { updateResult },
    } = await apollo.mutate({
      mutation: query,
      variables,
    })

    return updateResult.id
  },

  async delete(context, id) {
    const apollo = createProvider().clients.defaultClient

    const {
      data: { result: toDeleteItem },
    } = await apollo.mutate({
      mutation: FIND_ONE_BY_ID,
      variables: { id },
    })

    const result = await apollo.mutate({
      mutation: DELETE,
      variables: {
        id,
        toDeleteItem: {
          ...omit(toDeleteItem, ['__typename', 'updated_at']),
          status: 'archived',
        },
      },
    })

    return result
  },
}
