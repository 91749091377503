import gql from 'graphql-tag'

export const GET_PROVINCE_LIST = gql`
  query Languages {
    provinces: property_province {
      id
      country_id
      province_translates(where: { lang: { _eq: "th" } }) {
        id
        lang
        value
      }
    }
  }
`

export const GET_DISTRICT_LIST = gql`
  query Languages {
    districts: property_district {
      id
      province_id
      district_translates(where: { lang: { _eq: "th" } }) {
        id
        lang
        value
      }
    }
  }
`

export const GET_SUB_DISTRICT_LIST = gql`
  query Languages {
    subDistricts: property_sub_district {
      id
      district_id
      postcode
      sub_district_translates(where: { lang: { _eq: "th" } }) {
        id
        lang
        value
      }
    }
  }
`
