import gql from 'graphql-tag'

export const GET_HOUSE_ICON_LIST = gql`
  query GET_LIST($lang: String!) {
    house_icon_activity: property_house_icon_activity(
      where: { status: { _neq: "archived" }, lang: { _eq: $lang } }
      order_by: { updated_at: desc }
    ) {
      id
      lang
      uuid
      updated_at
      created_at
      updated_by
      created_by
      start_date
      end_date
      house_icon_activity_translates {
        lang
        value
        attribute_code
      }
      house_icon_activity_files {
        uuid
        attribute_code
        image
        house_icon_activity_file_translates {
          lang
          value
          attribute_code
        }
      }
    }
  }
`

export const GET_HOUSE_ICON_ITEM = gql`
  query GET_ITEM($uuid: uuid!) {
    house_icon_activity: property_house_icon_activity(
      where: { status: { _neq: "archived" }, uuid: { _eq: $uuid } }
    ) {
      id
      lang
      uuid
      updated_at
      created_at
      updated_by
      created_by
      start_date
      end_date
      house_icon_activity_translates {
        lang
        value
        attribute_code
      }
      house_icon_activity_files {
        uuid
        attribute_code
        image
        house_icon_activity_file_translates {
          lang
          value
          attribute_code
        }
      }
    }
  }
`

export const ADD_HOUSE_ICON = gql`
  mutation ADD($houseIconActivity: property_house_icon_activity_insert_input!) {
    insert_property_house_icon_activity_one(object: $houseIconActivity) {
      id
      uuid
    }
  }
`

export const UPDATE_HOUSE_ICON = gql`
  mutation update(
    $houseIconActivity: property_house_icon_activity_set_input!
    $houseIconActivityTranslate: [property_house_icon_activity_translate_insert_input!]!
    $houseIconActivityFile: [property_house_icon_activity_file_insert_input!]!
    $uuid: uuid!
  ) {
    update_property_house_icon_activity_by_pk(
      _set: $houseIconActivity
      pk_columns: { uuid: $uuid }
    ) {
      id
    }
    delete_property_house_icon_activity_translate(
      where: { house_icon_activity_uuid: { _eq: $uuid } }
    ) {
      affected_rows
    }
    insert_property_house_icon_activity_translate(objects: $houseIconActivityTranslate) {
      returning {
        uuid
      }
    }
    delete_property_house_icon_activity_file(
      where: { house_icon_activity_uuid: { _eq: $uuid } }
    ) {
      affected_rows
    }
    insert_property_house_icon_activity_file(objects: $houseIconActivityFile) {
      returning {
        uuid
      }
    }
  }
`

export const DELETE_HOUSE_ICON = gql`
  mutation DELETE($uuid: uuid!, $revisionData: jsonb!) {
    insert_property_house_revision_one(
      object: {
        revision_uuid: $uuid
        revision_table: "house_icon_activity"
        revision_data: $revisionData
      }
    ) {
      uuid
    }
    delete_property_house_icon_activity_by_pk(uuid: $uuid) {
      uuid
    }
  }
`
