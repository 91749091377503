import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'

import { createProvider } from '@/vue-apollo'
import { buildList, buildRequestBody, groupAttributeByLang } from '@/helper/Utils'
import {
  GET_HOUSE_FUNCTION_FEATURE_LIST,
  GET_HOUSE_FUNCTION_FEATURE_ITEM,
  ADD_HOUSE_FUNCTION_FEATURE,
  UPDATE_HOUSE_FUNCTION_FEATURE,
  DELETE_HOUSE_FUNCTION_FEATURE,
} from './constantGql'

import HOUSE_FUNCTION_FEATURE_ATTRIBUTE from './attributeJson.json'

const getItemByUuid = async (uuid) => {
  const apollo = createProvider().clients.defaultClient
  const response = await apollo.query({
    query: GET_HOUSE_FUNCTION_FEATURE_ITEM,
    variables: {
      uuid,
    },
  })

  const rawData = get(response, ['data', 'house_function_feature', 0], [])
  const parsedList = groupAttributeByLang(rawData)

  return parsedList
}

export default {
  async fetch({ commit, rootState }) {
    const apollo = createProvider().clients.defaultClient
    const lang = rootState.locale.currentLang
    const response = await apollo.query({
      query: GET_HOUSE_FUNCTION_FEATURE_LIST,
      variables: {
        lang,
      },
    })
    const rawList = get(response, ['data', 'house_function_feature'], [])
    const parsedData = buildList(
      rawList,
      'house_function_feature_translates',
      rootState.locale.currentLang,
    )

    commit('SET_ITEMS', parsedData)

    return parsedData
  },
  async addItem({ commit, rootState }, data) {
    const apollo = createProvider().clients.defaultClient

    const lang = rootState.locale.currentLang
    data.lang = lang
    
    const body = buildRequestBody(
      { house_function_feature: data },
      'house_function_feature',
      HOUSE_FUNCTION_FEATURE_ATTRIBUTE,
      lang,
    )

    const response = await apollo.mutate({
      mutation: ADD_HOUSE_FUNCTION_FEATURE,
      // Parameters
      variables: {
        houseFunctionFeature: body.house_function_feature,
      },
    })

    const id = get(
      response,
      ['data', 'insert_property_house_function_feature_one', 'uuid'],
      null,
    )

    commit(
      'ADD_HOUSE_FUNCTION_FEATURE',
      get(response, ['data', 'insert_property_house_function_feature_one']),
    )

    return id
  },

  async updateItem({ commit, rootState }, data) {
    const apollo = createProvider().clients.defaultClient

    const lang = rootState.locale.currentLang
    const body = buildRequestBody(
      { house_function_feature: data },
      'house_function_feature',
      HOUSE_FUNCTION_FEATURE_ATTRIBUTE,
      lang,
    )
    // const revisionData = await getItemByUuid(data.uuid)

    const translates = body.house_function_feature.house_function_feature_translates
    const houseFunctionFeatureTranslate = isEmpty(translates)
      ? []
      : translates.data.map((translate) => ({
          ...translate,
          house_function_feature_uuid: data.uuid,
        }))

    const rawResponse = await apollo.mutate({
      mutation: UPDATE_HOUSE_FUNCTION_FEATURE,
      // Parameters
      variables: {
        houseFunctionFeature: omit(body.house_function_feature, [
          'house_function_feature_translates',
        ]),
        houseFunctionFeatureTranslate,
        uuid: data.uuid,
        lang,
        // revisionData
      },
    })

    const response = get(rawResponse, [
      'data',
      'update_property_house_function_feature_by_pk',
    ])

    commit('UPDATE_ITEM', response)

    return response
  },

  async delete({ commit }, uuid) {
    const revisionData = await getItemByUuid(uuid)

    const apollo = createProvider().clients.defaultClient

    await apollo.mutate({
      mutation: DELETE_HOUSE_FUNCTION_FEATURE,
      // Parameters
      variables: {
        uuid,
        revisionData,
      },
    })

    commit('DELETE_ITEM', uuid)

    return uuid
  },

  async fetchOne({ commit }, uuid) {
    const parsedList = await getItemByUuid(uuid)

    commit('SET_ITEM', parsedList)

    return parsedList
  },
}
