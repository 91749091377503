import gql from 'graphql-tag'

export const GET_ROOM_FEATURE_LIST = gql`
  query Languages {
    property_room_feature(
      where: { status: { _neq: "archived" } }
      order_by: { created_at: desc }
    ) {
      id
      uuid
      updated_at
      created_at
      created_by
      updated_by
      room_feature_translates {
        lang
        value
        attribute_code
      }
    }
  }
`

export const GET_ROOM_FEATURE_ITEM = gql`
  query Languages($uuid: uuid!) {
    room_feature: property_room_feature(
      where: { status: { _neq: "archived" }, uuid: { _eq: $uuid } }
    ) {
      id
      uuid
      updated_at
      created_at
      created_by
      updated_by
      room_feature_translates {
        lang
        value
        attribute_code
      }
    }
  }
`

export const ADD_ROOM_FEATURE = gql`
  mutation ADD($roomFeature: property_room_feature_insert_input!) {
    insert_property_room_feature_one(object: $roomFeature) {
      id
      uuid
    }
  }
`

export const UPDATE_ROOM_FEATURE = gql`
  mutation Update(
    $roomFeature: property_room_feature_set_input!
    $roomFeatureTranslate: [property_room_feature_translate_insert_input!]!
    $uuid: uuid!
    $lang: String!
  ) {
    update_property_room_feature_by_pk(_set: $roomFeature, pk_columns: { uuid: $uuid }) {
      id
    }
    delete_property_room_feature_translate(
      where: { room_feature_uuid: { _eq: $uuid }, lang: { _eq: $lang } }
    ) {
      affected_rows
    }
    insert_property_room_feature_translate(objects: $roomFeatureTranslate) {
      returning {
        uuid
      }
    }
  }
`

export const DELETE_ROOM_FEATURE = gql`
  mutation DELETE($uuid: uuid!, $revisionData: jsonb!) {
    insert_property_house_revision_one(
      object: {
        revision_uuid: $uuid
        revision_table: "room_feature"
        revision_data: $revisionData
      }
    ) {
      uuid
    }
    delete_property_room_feature_by_pk(uuid: $uuid) {
      uuid
    }
  }
`
