import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import { createProvider } from '@/vue-apollo'
import { FETCH, FETCH_ALL, CREATE, UPDATE, DELETE, FIND_ONE_BY_ID } from './constantGql'

export default {
  async fetchAllLang({ commit }) {
    const apollo = createProvider().clients.defaultClient
    const response = await apollo.query({
      query: FETCH_ALL
    })

    commit('SET_ALL_ITEMS', get(response, ['data', 'result']))

    return response
  },
  async fetch({ commit, rootState }) {
    const apollo = createProvider().clients.defaultClient
    const response = await apollo.query({
      query: FETCH,
      variables: {
        lang: rootState.locale.currentLang,
      },
    })

    commit('SET_ITEMS', get(response, ['data', 'result']))
  },
  async fetchOne(context, revision_id) {
    const apollo = createProvider().clients.defaultClient
    const response = await apollo.query({
      query: FIND_ONE_BY_ID,
      variables: { revision_id },
    })
    return get(response, ['data', 'result'])
  },
  async addItem(context, payload) {
    const variables = {
      page_url: get(payload.page_url, 'value'),
      desktop_banner: {
        data: {
          type: 'gallery',
          items: payload.desktop_banner,
        },
      },
      is_event: payload.is_event,
      folder_name: payload.folder_name,
      created_by: payload.created_by,
      updated_by: payload.updated_by,
      lang: context.rootState.locale.currentLang,
    }

    if (!isEmpty(payload.mobile_banner)) {
      variables['mobile_banner'] = {
        data: {
          type: 'gallery',
          items: payload.mobile_banner,
        },
      }
    }

    if (payload.is_event) {
      variables['custom_banner'] = {
        ...payload.event,
        event_start_date: payload.event_start_date,
        event_end_date: payload.event_end_date
      }
    }

    const apollo = createProvider().clients.defaultClient
    const {
      data: { result },
    } = await apollo.mutate({
      mutation: CREATE,
      variables,
    })

    return result
  },
  async updateItem(context, payload) {
    const variables = {
      page_url: get(payload.page_url, 'value'),
      desktop_banner: {
        data: {
          type: 'gallery',
          items: payload.desktop_banner,
        },
      },
      folder_name: payload.folder_name,
      is_event: payload.is_event,
      lang: context.rootState.locale.currentLang,
      created_by: payload.created_by,
      updated_by: payload.updated_by,
    }

    if (!isEmpty(payload.mobile_banner)) {
      variables['mobile_banner'] = {
        data: {
          type: 'gallery',
          items: payload.mobile_banner,
        },
      }
    }

    if (payload.is_event) {
      console.log('has event', payload.event)
      variables['custom_banner'] = {
        ...payload.event,
        event_start_date: payload.event_start_date,
        event_end_date: payload.event_end_date
      }
    }

    if (payload.id) {
      variables['id'] = payload.id
    }

    const apollo = createProvider().clients.defaultClient
    const {
      data: { result },
    } = await apollo.mutate({
      mutation: UPDATE,
      variables,
    })

    return result
  },
  async delete({ dispatch }, revision_id) {
    const apollo = createProvider().clients.defaultClient
    const response = await apollo.mutate({
      mutation: DELETE,
      variables: { revision_id },
    })

    console.log(response)

    await dispatch('fetch')
  },
}
