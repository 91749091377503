import gql from 'graphql-tag'

export const FETCH = gql`
  query {
    provinces: property_province (
      where: {
        is_show: {_eq: true}
      }
    ){
      id
      province_translates {
        lang
        value
      }
    }
  }
`
