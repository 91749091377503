import gql from 'graphql-tag'

export const GET_HOUSE_MODEL_LIST = gql`
  query GET_LIST ($lang: String!){
    house_model: property_house_model(
      where: {
        status: { _neq: "archived" }
        lang: { _eq: $lang }
      }
      order_by: { updated_at: desc }
    ) {
      id
      uuid
      updated_at
      created_at
      status
      house_model_translates {
        lang
        value
        attribute_code
      }
      house_serie {
        id
        house_serie_translates {
          attribute_code
          lang
          value
        }
      }
    }
  }
`

export const GET_HOUSE_MODEL_ITEM = gql`
  query GET_ITEM($uuid: uuid!) {
    house_model: property_house_model(
      where: { status: { _neq: "archived" }, uuid: { _eq: $uuid } }
    ) {
      id
      uuid
      updated_at
      created_at
      created_by
      updated_by
      status
      property_category_id
      lang
      house_serie {
        id
        uuid
        house_serie_translates {
          lang
          value
          attribute_code
        }
        house_serie_files {
          uuid
          attribute_code
          image
          house_serie_file_translates {
            lang
            value
            attribute_code
          }
        }
      }
      house_model_functions {
        value
        house_function_id
      }
      house_model_translates {
        lang
        value
        attribute_code
      }
      house_model_files {
        uuid
        attribute_code
        image
        is_default
        house_model_file_translates {
          lang
          value
          attribute_code
        }
      }
    }
  }
`

export const ADD_HOUSE_MODEL = gql`
  mutation ADD($houseModel: property_house_model_insert_input!) {
    insert_property_house_model_one(object: $houseModel) {
      id
      uuid
    }
  }
`

export const UPDATE_HOUSE_MODEL = gql`
  mutation update(
    $houseModel: property_house_model_set_input!
    $houseModelTranslate: [property_house_model_translate_insert_input!]!
    $houseModelFile: [property_house_model_file_insert_input!]!
    $houseModelFunction: [property_house_model_function_insert_input!]!
    $uuid: uuid!
  ) {
    update_property_house_model_by_pk(_set: $houseModel, pk_columns: { uuid: $uuid }) {
      id
    }
    delete_property_house_model_translate(where: { house_model_uuid: { _eq: $uuid } }) {
      affected_rows
    }
    insert_property_house_model_translate(objects: $houseModelTranslate) {
      returning {
        uuid
      }
    }
    delete_property_house_model_file(where: { house_model_uuid: { _eq: $uuid } }) {
      affected_rows
    }
    insert_property_house_model_file(objects: $houseModelFile) {
      returning {
        uuid
      }
    }
    delete_property_house_model_function(where: { house_model_uuid: { _eq: $uuid } }) {
      affected_rows
    }
    insert_property_house_model_function(objects: $houseModelFunction) {
      returning {
        uuid
      }
    }
  }
`

export const DELETE_HOUSE_MODEL = gql`
  mutation DELETE($uuid: uuid!, $revisionData: jsonb!) {
    insert_property_house_revision_one(
      object: {
        revision_uuid: $uuid
        revision_table: "house_model"
        revision_data: $revisionData
      }
    ) {
      uuid
    }
    delete_property_house_model_by_pk(uuid: $uuid) {
      uuid
    }
  }
`

export const GET_HOUSE_MODEL_ALL_LIST = gql`
  query GET_LIST {
    house_model: property_house_model(
      where: {
        status: { _neq: "archived" }
      }
      order_by: { updated_at: desc }
    ) {
      id
      lang
      uuid
      updated_at
      created_at
      status
      house_model_translates {
        lang
        value
        attribute_code
      }
      house_serie {
        id
        house_serie_translates {
          attribute_code
          lang
          value
        }
      }
    }
  }
`