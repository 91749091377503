import isNil from 'lodash/isNil'
import find from 'lodash/find'
import omit from 'lodash/omit'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import mapKeys from 'lodash/mapKeys'
import snakeCase from 'lodash/snakeCase'
import pluralize from 'pluralize'
import transform from 'lodash/transform'
import isPlainObject from 'lodash/isPlainObject'
import pick from 'lodash/pick'
import some from 'lodash/some'
import isNull from 'lodash/isNull'
import moment from 'moment'
import env from '@/env'
import uniqBy from 'lodash/uniqBy'

import {
  getBasicInfo,
  getPropertyInfo,
  getInfo,
  getContactInfo,
  getSeoInfo,
  getAdminInfo,
  getFile,
  getVideoInfo,
  getUnitInfo,
  getFacilityPropertyInfo,
  getProgressInfo,
  getLocationInfo,
  getBanner,
} from '@/helper/BuildProjectInitialData'

import { PROJECT_ATTRIBUTE } from '@/store/property-asset/project/constantGql.js'

const DEFAULT_LANG = 'th'

export const insertBr = (data) => {
  if (typeof data === 'string') return data.replace(/\n/g, '<br>')

  const keys = Object.keys(data)
  const newData = {}
  keys.forEach((key) => {
    if (!isNil(data[key]) && typeof data[key] === 'string') {
      newData[key] = data[key].replace(/\n/g, '<br>')
    } else {
      newData[key] = data[key]
    }
  })

  return newData
}

export const deleteBr = (data) => {
  if (typeof data === 'string') return data.replace(/<br>/g, '\n')

  const keys = Object.keys(data)
  const newData = {}
  keys.forEach((key) => {
    if (!isNil(data[key]) && typeof data[key] === 'string') {
      newData[key] = data[key].replace(/<br>/g, '\n')
    } else {
      newData[key] = data[key]
    }
  })

  return newData
}

export const getValueByAttributeCode = (dataList = [], code) => {
  const objectData = find(dataList, (data) => {
    return data.attribute_code === code
  })

  return get(objectData, 'value', null)
}

export const extractRelation = (rawData, key = null) => {
  if (isNil(key) || isEmpty(rawData)) return rawData
  return rawData.map((data) => ({
    ...omit(data, key),
    ...data[key],
  }))
}

export const buildRow = (data, translateKey = null, lang = 'th') => {
  const translateData = get(data, translateKey, [])
  const attribute = {}

  // extract code to key object
  translateData.forEach((translate) => {
    // default attribute code is title
    const attributeCode = isNil(translate['attribute_code'])
      ? 'title'
      : translate['attribute_code']
    if (
      (translate.lang === DEFAULT_LANG && !(attributeCode in attribute)) ||
      translate.lang === lang
    ) {
      attribute[attributeCode] = translate.value
    }
  })

  const result = {
    ...omit(data, translateKey),
    ...attribute,
  }

  return mapKeys(result, (_, key) => key)
}

export const buildList = (rawData, translateKey = null, lang = 'th') => {
  if (isEmpty(rawData)) return []
  const parserData = rawData.map((data) => {
    return buildRow(data, translateKey, lang)
  })

  return parserData
}

export const buildRequestBody = (
  data,
  mainKey,
  attributeList = [],
  lang = 'th',
  level = 1,
) => {
  if (isEmpty(mainKey) || isEmpty(data) || isEmpty(attributeList)) {
    return null
  }

  const dataKeys = Object.keys(data)
  const mainTableAttribute = attributeList.main || []
  const translateAttribute = attributeList.translate || []
  const relationAttribute = Object.keys(omit(attributeList, ['translate', 'main'])) || []
  const translateKey = `${snakeCase(pluralize(mainKey, 1))}_translates`

  let requestData = null

  if (Array.isArray(data)) {
    requestData = data.map((d) =>
      buildRequestBody(d, mainKey, attributeList, lang, level),
    )
  } else {
    requestData = {}
    dataKeys.forEach((key) => {
      if (isNil(data[key])) return

      if (mainTableAttribute.indexOf(key) > -1) {
        requestData[key] = data[key]
      } else if (
        translateAttribute.indexOf(key) > -1 &&
        !isNil(data[key]) &&
        data[key] !== ''
      ) {
        if (isEmpty(requestData[translateKey])) {
          requestData[translateKey] = {
            data: [],
          }
        }

        requestData[translateKey].data.push({
          attribute_code: key,
          lang,
          value: `${data[key]}`,
        })
      } else if (relationAttribute.indexOf(key) > -1) {
        const relationData = buildRequestBody(
          data[key],
          key,
          attributeList[key],
          lang,
          level + 1,
        )
        if (isEmpty(relationData)) return

        const filterRelationData = Array.isArray(relationData)
          ? relationData.filter(Boolean)
          : relationData

        if (level === 1) {
          requestData[key] = filterRelationData
        } else {
          if (isEmpty(requestData[key])) {
            requestData[key] = {
              data: null,
            }
          }
          requestData[key].data = filterRelationData
        }
      }
    })
  }

  return requestData
}

export const buildFileRequestBody = (files = [], attributeList = {}) => {
  const request = []
  files.forEach((file) => {
    let requestObject = {}
    file.is_default = file.image_selected
    const translateAttribute = get(attributeList, 'translate', [])
    const mainAttribute = get(attributeList, 'main', [])
    translateAttribute.push('attribute_code')
    const mainValue = pick(file, mainAttribute)
    const translateValue = pick(file, translateAttribute)
    const image = omit(file, translateAttribute)

    requestObject = {
      ...mainValue,
      ...translateValue,
    }
    requestObject.image = image

    request.push(requestObject)
  })
  return request
}

export const buildFileResponseBody = (files = [], lang = 'th') => {
  const response = []
  files.forEach((file) => {
    const translate = file[lang]
    const fileObject = {
      ...omit(file, ['image', lang]),
      ...get(file, 'image', {}),
      ...get(file, ['image', lang], {}),
      ...translate
    }

    response.push(fileObject)
  })
  return response
}

const getCategory = (categories, id) => {
  if (isEmpty(categories)) return {}

  return categories.find((cat) => cat.id === id)
}

const getCategoryByCode = (categories, code) => {
  if (isEmpty(categories)) return {}

  return categories.find((cat) => cat.code === code)
}

export const groupAttributeByLang = (data) => {
  return transform(
    data,
    (result, value, key) => {
      if (Array.isArray(value)) {
        if (key.includes('_translates')) {
          value.forEach((v) => {
            if (!result[v.lang]) result[v.lang] = {}
            result[v.lang][v.attribute_code] = v.value
          })
        } else {
          value.forEach((v) => {
            if (isEmpty(result[key])) result[key] = []
            result[key].push(groupAttributeByLang(v))
          })
        }
      } else if (isPlainObject(value)) {
        result[key] = groupAttributeByLang(value)
      } else if (!isPlainObject(value) && !Array.isArray(value)) {
        result[key] = value
      }
    },
    {},
  )
}

const getImageAttribute = (image, attribute_code) => {
  const {
    image_alt_text = null,
    image_description = null,
    image_detail = null,
    image_end_date = null,
    image_ex_url = null,
    image_start_date = null,
    public_id = null,
  } = image

  const convertedImage = {
    image_alt_text,
    image_description,
    image_detail,
    image_end_date: isNil(image_end_date)
      ? null
      : moment(image_end_date).format('DD/MM/YYYY'),
    image_ex_url,
    image_start_date: isNil(image_start_date)
      ? null
      : moment(image_start_date).format('DD/MM/YYYY'),
    public_id,
    attribute_code,
    image,
  }
  return convertedImage
}

export const checkUrlExist = (fullUrl, projectId, lang, projectPageUrls, newUrls) => {
  if (!isEmpty(newUrls) && newUrls.includes(fullUrl)) return true
  return some(projectPageUrls, (page) => {
    if (page.type === 'simple_content') {
      const pageProjectId = get(page, ['project_page_links', 0, 'project_id'], null)
      if (page.url === fullUrl) {
        if (page.lang === lang && projectId === pageProjectId && !isNil(projectId)) {
          return false
        }
        return true
      }
    }
    return false
  })
}

export const generateUrl = (projectId, fullUrl, lang, category, projectPageUrls = [], newUrls = []) => {
  let hasUrl = checkUrlExist(fullUrl, projectId, lang, projectPageUrls, newUrls)

  let newTitleUrl = fullUrl.split('/')[1]
  let newFullUrl = fullUrl

  while (hasUrl) {
    const splitUrl = newTitleUrl.split('/')
    const lastChar = !isEmpty(splitUrl) ? splitUrl[splitUrl.length - 1] : '0'
    const lastIndex = parseInt(lastChar)
    const index = !Number.isNaN(lastIndex) ? lastIndex : 0

    // delete old index
    if (index > 0) {
      splitUrl.pop()
    }

    newTitleUrl = `${splitUrl.join('')}/${index + 1}`
    newFullUrl = `${category.code}/${newTitleUrl}`
    hasUrl = checkUrlExist(newFullUrl, projectId, lang, projectPageUrls, newUrls)
  }

  return newFullUrl
}

export const buildProjectBody = (
  formData,
  categories,
  projectPageUrls = [],
  lang = 'th'
) => {
  
  const projectPropertyType = get(formData, ['property', 'propertyTypes'], [])
  const rawPageLinks = get(formData, ['property', 'pageLinks'], [])
  // add ladawan links
  const categoryPage = projectPageUrls.filter((page) => page.type === 'category')

  let isLadawan = false
  let isLadawanCatId = null
  const project_page_links = rawPageLinks.map((pageId) => {
    const page = categoryPage.find((page) => page.id === pageId)
    const ladawanCat = getCategoryByCode(categories, 'ladawan')

    if (get(page, 'url', null) === 'ladawan') {
      if (projectPropertyType.indexOf(ladawanCat.id) === -1) {
        projectPropertyType.push(ladawanCat.id)
      }
      isLadawanCatId = ladawanCat.id
      isLadawan = true
    }

    return {
      page_id: pageId,
      project_id: formData.id,
    }
  })

  const title = get(formData.basicInfo, 'title', '').trim()
  formData.basicInfo.title = title
  const slug = formData.basicInfo.slug || title
  
  // const rawOldUrls = get(formData.basicInfo, 'urls', [])
  // const oldUrls = rawOldUrls.map((url) => url.replace(`${env.VUE_APP_WEBSITE_URL}/${lang}/`, ''))
  const newUrls = []
  const project_category_links = projectPropertyType.map((catId) => {
    const category = getCategory(categories, catId)
    if (!isLadawan && category.code === 'ladawan') return null
    // const titleUrl = `${title.replace(/\s+/g, '-')}`
    const newUrl = `${category.code}/${slug.replace(/\s+/g, '-')}`
    // const newUrl = generateUrl(formData.id, fullUrl, lang, category, projectPageUrls, newUrls)
    newUrls.push(newUrl)
    // newUrl = generateUrl(formData.id, fullUrl, lang, category, projectPageUrls, newUrls)
    
    // if (isEmpty(oldUrls) || oldUrls.indexOf(newUrl) === -1) {
      // newUrl = generateUrl(formData.id, newUrl, lang, category, projectPageUrls, newUrls)
    // }
    
    return {
      url: newUrl,
      property_category_id: category.id,
    }
  })

  const project_files = []
  if (!isEmpty(formData.map)) {
    const mapSectionKeys = Object.keys(formData.map)
    mapSectionKeys.forEach((key) => {
      if (!isEmpty(formData.map[key])) {
        const convertedImage = getImageAttribute(formData.map[key][0], key)
        project_files.push(convertedImage)
      }
    })
  }

  if (!isEmpty(formData.leadImage.lead_image)) {
    formData.leadImage.lead_image.forEach((image) => {
      const convertedImage = getImageAttribute(image, 'lead_image')
      project_files.push(convertedImage)
    })
  }

  if (!isEmpty(formData.gallery.gallery)) {
    formData.gallery.gallery.forEach((image) => {
      const convertedImage = getImageAttribute(image, 'gallery')
      project_files.push(convertedImage)
    })
  }

  if (!isEmpty(formData.promotion_gallery.promotion_gallery)) {
    formData.promotion_gallery.promotion_gallery.forEach((image) => {
      const convertedImage = getImageAttribute(image, 'promotion_gallery')
      project_files.push(convertedImage)
    })
  }

  if (!isEmpty(formData.videoInfo)) {
    const keys = Object.keys(formData.videoInfo)
    keys.forEach((key) => {
      if (
        ['video_thumbnail', 'video_file', 'image_360_thumbnail'].includes(key) &&
        !isEmpty(formData.videoInfo[key])
      ) {
        const convertedImage = getImageAttribute(formData.videoInfo[key][0], key)
        project_files.push(convertedImage)
      }
    })
  }

  if (!isEmpty(formData.masterPlan.master_plan)) {
    formData.masterPlan.master_plan.forEach((image) => {
      const convertedImage = getImageAttribute(image, 'master_plan')
      project_files.push(convertedImage)
    })
  }

  // parse contact section
  if (formData.contact.office_day_type === 'all_day') {
    formData.contact.office_day = null
  } else if (!isEmpty(formData.contact.office_days)) {
    formData.contact.office_day = formData.contact.office_days.join(',')
  }

  // parse contact section
  if (!isEmpty(formData.contact.location_flm)) 
  {
    formData.contact.location_flm = formData.contact.location_flm.join(',')
  }

  // parse contact section
  if (!isEmpty(formData.contact.email_address)) {
    // split domain
    const emailStr = formData.contact.email_address.split('@')[0]
    const email = `${emailStr}@lh.co.th`
    formData.contact.email_address = email
  }

  // parse unit plans
  const project_unit_plans = []
  formData.unitPlan.unitPlanList.forEach((unitPlan) => {
    let result = {
      ...omit(unitPlan, ['unit_plan_image', '__typename']),
    }

    if (!isEmpty(unitPlan.unit_plan_image)) {
      const convertedImage = getImageAttribute(
        unitPlan.unit_plan_image[0],
        'unit_plan_name',
      )
      result = {
        ...result,
        ...convertedImage,
      }
    }

    if (!isEmpty(result)) project_unit_plans.push(result)
  })

  const project_property_facilities = []
  formData.propertyFacility.propertyFacilityList.forEach(
    (facility) => {
      if (!isEmpty(facility)) {
        let result = {
          ...omit(facility, ['property_facility_image', '__typename']),
        }
        if (!isEmpty(facility.property_facility_image)) {
          const convertedImage = getImageAttribute(
            facility.property_facility_image[0],
            'property_facility_image',
          )
          result = {
            ...result,
            ...convertedImage,
          }
        }
        if (!isEmpty(result)) project_property_facilities.push(result)
      }
    },
  )

  const project_property_progresses = []
  formData.progressInfo.progressList.forEach(
    (progress) => {
      if (
        !isEmpty(progress.progress_date) ||
        !isEmpty(progress.progress_percent) ||
        !isEmpty(progress.projectPropertyProgressFiles)
      ) {
        const result = {
          progress_percent: !progress.progress_percent ? null : progress.progress_percent,
          ...omit(progress, ['projectPropertyProgressFiles', 'progress_percent', '__typename'])
        }
        const files = []
        if (!isEmpty(progress.projectPropertyProgressFiles)) {
          progress.projectPropertyProgressFiles.forEach((image) => {
            const convertedImage = getImageAttribute(image, 'project_progress_image')
            files.push(convertedImage)
          })
          if (!isEmpty(files)) result.project_property_progress_files = files
        }

        if (!isEmpty(result)) project_property_progresses.push(result)
      }
    },
  )

  // check price type
  const infoData = {
    ...formData.info
  }

  const convertPriceToNumber = (price) => {
    if (price) return Number(price.replace(/[^0-9.-]+/g, ""))
    return null
  }
  
  const priceType = infoData.price_type
  if (priceType === 'price') {
    delete infoData.price_from
    delete infoData.price_to
    delete infoData.price_per_metre
    infoData.price = convertPriceToNumber(infoData.price)
  } else if (priceType === 'price_range') {
    delete infoData.price
    delete infoData.price_per_metre
    infoData.price_to = convertPriceToNumber(infoData.price_to)
    infoData.price_from = convertPriceToNumber(infoData.price_from)
  } else if (priceType === 'price_per_metre') {
    delete infoData.price
    delete infoData.price_from
    delete infoData.price_to
    infoData.price_per_metre = convertPriceToNumber(infoData.price_per_metre)
  } else {
    delete infoData.price
    delete infoData.price_from
    delete infoData.price_to
    delete infoData.price_per_metre
  }

  // parse banner
  const has_project_banner_image = get(formData, ['banner', 'is_event'], false)
  let banner = {}
  const bannerImagePc = get(formData, ['banner', 'event', 'event_desktop_banner'], [])
  if (has_project_banner_image) {
    const project_banner_type = get(
      formData,
      ['banner', 'event', 'event_type'],
      'gallery',
    )

    if (!isEmpty(bannerImagePc)) {
      bannerImagePc.forEach((image) => {
        const convertedImage = getImageAttribute(image, 'project_banner_image')
        project_files.push(convertedImage)
      })
    }

    const bannerVideoFile = get(formData, ['banner', 'event', 'event_video_file'], [])
    if (!isEmpty(bannerVideoFile)) {
      bannerVideoFile.forEach((image) => {
        const convertedImage = getImageAttribute(image, 'project_banner_video_file')
        project_files.push(convertedImage)
      })
    }

    const bannerVideoThumbnail = get(
      formData,
      ['banner', 'event', 'event_video_thumbnail'],
      [],
    )
    if (!isEmpty(bannerVideoThumbnail)) {
      bannerVideoThumbnail.forEach((image) => {
        const convertedImage = getImageAttribute(image, 'project_banner_video_thumbnail')
        project_files.push(convertedImage)
      })
    }

    const bannerActivityImage = get(
      formData,
      ['banner', 'event', 'event_activity_image'],
      [],
    )
    if (!isEmpty(bannerActivityImage)) {
      bannerActivityImage.forEach((image) => {
        const convertedImage = getImageAttribute(image, 'project_banner_activity_image')
        project_files.push(convertedImage)
      })
    }

    const project_banner_video_url = get(
      formData,
      ['banner', 'event', 'event_video_url'],
      null,
    )
    const project_banner_video_type = get(
      formData,
      ['banner', 'event', 'event_video_type'],
      null,
    )

    const project_banner_activity_content = get(
      formData,
      ['banner', 'event', 'event_activity_content'],
      null,
    )
    const tempColour = get(formData, ['banner', 'event', 'event_background_color'], null)
    const project_banner_activity_background_colour = !isEmpty(tempColour)
      ? JSON.stringify(tempColour)
      : null

    const bannerStartDate = get(formData, ['banner', 'event_start_date'], null)
    const bannerEndDate = get(formData, ['banner', 'event_end_date'], null)

    banner = {
      project_banner_video_url,
      project_banner_start_date: isNil(bannerStartDate)
        ? null
        : moment(bannerStartDate).format('DD/MM/YYYY'),
      project_banner_end_date: isNil(bannerEndDate)
        ? null
        : moment(bannerEndDate).format('DD/MM/YYYY'),
      project_banner_video_type,
      project_banner_type,
      project_banner_activity_content,
      project_banner_activity_background_colour,
    }

    Object.keys(banner).forEach((key) => banner[key] === null && delete banner[key])
  }

  const newProjectEndDate = get(formData.admin, ['new_project_end_date'], null)
  const publishedDate = get(formData.admin, ['published_date'], null)
  const commonFeeDate = get(formData.contact, ['common_fee_date'], null)

  // filter empty
  const propertyList = get(formData, ['property', 'propertyList'], [])
  if (isLadawan) {
    propertyList.push({
      property_category_id: isLadawanCatId
    })
  }
  
  const unqiPropertyList = uniqBy(propertyList, 'property_category_id')
  const filterEmptyPropertyList = unqiPropertyList.map((property) => {
    const newProperty = {
      ...property
    }

    const units = get(property, 'project_property_units', [])
    const buildings = get(property, 'project_property_buildings', [])

    if (!isEmpty(units)) {
      newProperty.project_property_units = units.filter((unit) => unit.unit_name || unit.unit_size)
    }

    if (!isEmpty(buildings)) {
      newProperty.project_property_buildings = buildings.filter((building) => building.building_name || building.building_level || building.building_unit)
    }

    return newProperty
  })
  
  const today = moment().format()
  let projectStatus = 'for_approve'
  if (!isNil(publishedDate)) {
    if (moment(today).isSameOrAfter(publishedDate, 'day')) {
      projectStatus = 'published'
    } else {
      projectStatus = 'for_flm'
    }
  }

  const rawFacilityList = get(formData, ['location', 'facilityList'], [])

  const facilityList = rawFacilityList.filter((item) => !isEmpty(item) && !isNull(item.facility_distance_unit))

  const normalizeData = {
    id: formData.id,
    created_by: formData.created_by,
    updated_by: formData.updated_by,
    project_original_language: formData.project_original_language || null,
    lang,
    project_house_sell_links: formData.project_house_sell_links,
    project_house_sell_gallery_links: formData.project_house_sell_gallery_links,
    project_zones: get(formData, 'zoneList', []),
    project_facilities: facilityList,
    project_properties: filterEmptyPropertyList,
    project_page_links,
    project_category_links: project_category_links.filter((link) => !isEmpty(link)),
    project_files,
    project_unit_plans,
    project_property_facilities,
    project_property_progresses,
    ...pick(formData.videoInfo, PROJECT_ATTRIBUTE.project.translate),
    ...pick(formData.property, PROJECT_ATTRIBUTE.project.translate),
    ...formData.basicInfo,
    ...insertBr(infoData),
    ...insertBr(formData.seo),
    ...pick(formData.location, PROJECT_ATTRIBUTE.project.translate),
    ...omit(formData.contact, ['common_fee_date']),
    ...omit(formData.admin, ['published_date', 'new_project_end_date']),
    new_project_end_date: isNil(newProjectEndDate)
      ? null
      : moment(newProjectEndDate).format('DD/MM/YYYY'),
    published_date: isNil(publishedDate)
      ? null
      : moment(publishedDate).format('DD/MM/YYYY'),
    status: projectStatus,
    common_fee_date: isNil(commonFeeDate)
      ? null
      : moment(commonFeeDate).format('DD/MM/YYYY'),
    has_project_banner_image,
    ...banner,
  }

  return normalizeData
}

export const buildFormProjectData = (formData, productDetail, lang, oldProject) => {
  const attributeByLang = productDetail[lang]

  const parseProjectData = {
    ...omit(productDetail, lang, {}),
    ...attributeByLang,
  }

  formData.id = productDetail.id
  formData.created_by = productDetail.created_by
  formData.updated_by = productDetail.updated_by
  formData.updated_at = productDetail.updated_at
  formData.slug = productDetail.slug
  formData.project_house_sell_links = isNil(productDetail.project_house_sell_links) ? [] : productDetail.project_house_sell_links
  formData.project_house_sell_gallery_links = isNil(productDetail.project_house_sell_gallery_links) ? [] : productDetail.project_house_sell_gallery_links

  // initial basic info
  formData.basicInfo = getBasicInfo(parseProjectData, oldProject)

  // initial zone info
  const zoneList = get(productDetail, 'project_zones', [{}])
  formData.zoneList = JSON.parse(JSON.stringify(zoneList))

  // initial property
  formData.property = getPropertyInfo(parseProjectData, lang)

  // initial info
  formData.info = getInfo(parseProjectData)

  // initial contactgetSeoInfo
  formData.contact = getContactInfo(parseProjectData)

  // initial
  formData.seo = getSeoInfo(parseProjectData)

  // initial map
  formData.map = getFile(
    parseProjectData,
    ['map_file', 'map_img', 'logo', 'brochure'],
    lang,
  )

  // initial lead image
  formData.leadImage = getFile(parseProjectData, ['lead_image'], lang)

  // initial lead gallery
  formData.gallery = getFile(parseProjectData, ['gallery'], lang)

  // initial promotion gallery
  formData.promotion_gallery = getFile(parseProjectData, ['promotion_gallery'], lang)

  // initail video
  formData.videoInfo = getVideoInfo(
    parseProjectData,
    ['video_thumbnail', 'video_file', 'image_360_thumbnail'],
    lang,
  )

  // initail video
  formData.masterPlan = getFile(parseProjectData, ['master_plan'], lang)

  // initial unit
  formData.unitPlan = getUnitInfo(parseProjectData, lang)

  // initial property facility
  formData.propertyFacility = getFacilityPropertyInfo(parseProjectData, lang)

  // initial progress info
  formData.progressInfo = getProgressInfo(parseProjectData, lang)

  // initial location
  formData.location = getLocationInfo(parseProjectData, lang)

  // initial admin
  formData.admin = getAdminInfo(parseProjectData)

  // initial banenr
  formData.banner = getBanner(parseProjectData, lang)

  return formData
}

export const checkNameExist = (existDataList, name, keyName, id = null, idKey = 'id') => {
  const existData = existDataList.find((data) => {
    return data[keyName] === name && parseInt(id) !== data[idKey]
  })
  
  if (existData) throw "มีชื่อนี้อยู่ในระบบแล้ว"
}

export const getImageUrl = (public_id) => {
  return `${env.VUE_APP_IMAGE_URL}/${public_id}`
}

export const getImagePublicId = (public_id) => {
  return `${env.VUE_APP_CLOUDINARY_DEFAULT_FOLDER}/${public_id}`
}

export const getUniqueValue = (items = [], value, key) => {
  let canUse = false
  let newValue = value
  while (!canUse) {
    canUse = items.every((item) => {
      return item[key] !== newValue
    })

    if (!canUse) newValue = `${newValue}_copy`
  }

  return newValue
}