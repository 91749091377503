import gql from 'graphql-tag'

export const GET_HOUSE_SERIE_LIST = gql`
  query Languages($lang: String!) {
    house_serie: property_house_serie(
      where: { status: { _neq: "archived" }, lang: { _eq: $lang } }
      order_by: { updated_at: desc }
    ) {
      id
      lang
      uuid
      updated_at
      created_at
      status
      house_serie_translates {
        lang
        value
        attribute_code
      }
      house_serie_files {
        uuid
        attribute_code
      }
    }
  }
`

export const GET_HOUSE_SERIE_ITEM = gql`
  query Languages($uuid: uuid!) {
    house_serie: property_house_serie(
      where: { status: { _neq: "archived" }, uuid: { _eq: $uuid } }
    ) {
      id
      uuid
      updated_at
      created_at
      created_by
      updated_by
      status
      lang
      house_serie_translates {
        lang
        value
        attribute_code
      }
      house_serie_files {
        uuid
        attribute_code
        image
        house_serie_file_translates {
          lang
          value
          attribute_code
        }
      }
    }
  }
`

export const ADD_HOUSE_SERIE = gql`
  mutation HouseSerie($houseSerie: property_house_serie_insert_input!) {
    insert_property_house_serie_one(object: $houseSerie) {
      id
      uuid
    }
  }
`

export const UPDATE_HOUSE_SERIE = gql`
  mutation update(
    $houseSerie: property_house_serie_set_input!
    $houseSerieTranslate: [property_house_serie_translate_insert_input!]!
    $houseSerieFile: [property_house_serie_file_insert_input!]!
    $uuid: uuid!
  ) {
    update_property_house_serie_by_pk(_set: $houseSerie, pk_columns: { uuid: $uuid }) {
      id
    }
    delete_property_house_serie_translate(where: { house_serie_uuid: { _eq: $uuid } }) {
      affected_rows
    }
    insert_property_house_serie_translate(objects: $houseSerieTranslate) {
      returning {
        uuid
      }
    }
    delete_property_house_serie_file(where: { house_serie_uuid: { _eq: $uuid } }) {
      affected_rows
    }
    insert_property_house_serie_file(objects: $houseSerieFile) {
      returning {
        uuid
      }
    }
  }
`

export const DELETE_HOUSE_SERIE = gql`
  mutation DELETE($uuid: uuid!, $revisionData: jsonb!) {
    insert_property_house_revision_one(
      object: {
        revision_uuid: $uuid
        revision_table: "house_serie"
        revision_data: $revisionData
      }
    ) {
      uuid
    }
    delete_property_house_serie_by_pk(uuid: $uuid) {
      uuid
    }
  }
`


export const GET_HOUSE_SERIE_ALL_LIST = gql`
  query LIST_HOUSE_SERIE {
    house_serie: property_house_serie(
      where: { status: { _neq: "archived" } }
      order_by: { updated_at: desc }
    ) {
      id
      lang
      uuid
      updated_at
      created_at
      status
      house_serie_translates {
        lang
        value
        attribute_code
      }
      house_serie_files {
        uuid
        attribute_code
      }
    }
  }
`