import get from 'lodash/get'
import omit from 'lodash/omit'
import isEmpty from 'lodash/isEmpty'

import { createProvider } from '@/vue-apollo'
import {
  buildList,
  buildRow,
  buildRequestBody,
  groupAttributeByLang,
  buildFileRequestBody,
  buildFileResponseBody,
  checkNameExist
} from '@/helper/Utils'
import {
  GET_HOUSE_MODEL_LIST,
  GET_HOUSE_MODEL_ITEM,
  ADD_HOUSE_MODEL,
  UPDATE_HOUSE_MODEL,
  DELETE_HOUSE_MODEL,
  GET_HOUSE_MODEL_ALL_LIST,
} from './constantGql'

import HOUSE_MODEL_ATTRIBUTE from './attributeJson.json'

const getItemByUuid = async (uuid) => {
  const apollo = createProvider().clients.defaultClient
  const response = await apollo.query({
    query: GET_HOUSE_MODEL_ITEM,
    variables: {
      uuid,
    },
  })

  const rawData = get(response, ['data', 'house_model', 0], [])
  const parsedList = groupAttributeByLang(rawData)

  return parsedList
}

const fetchList = async (lang) => {
  const apollo = createProvider().clients.defaultClient

    const response = await apollo.query({
      query: GET_HOUSE_MODEL_LIST,
      variables: {
        lang
      },
    })
    const rawList = get(response, ['data', 'house_model'], [])
    let parsedData = buildList(rawList, 'house_model_translates', lang)

    parsedData = parsedData.map((data) => {
      const newData = {
        ...omit(data, 'house_serie'),
      }
      const houseSerie = buildRow(data.house_serie, 'house_serie_translates', lang)
      newData.house_serie_title = get(houseSerie, 'house_serie_title', '')

      return newData
    })

    return parsedData
}

const fetchAllList = async () => {
  const apollo = createProvider().clients.defaultClient
  const response = await apollo.query({
    query: GET_HOUSE_MODEL_ALL_LIST
  })

  return get(response, ['data', 'house_model'], [])
}

export default {
  async fetch({ commit, rootState }) {

    const lang = rootState.locale.currentLang

    const result = await fetchList(lang)

    commit('SET_ITEMS', result)

    return result
  },
  async fetchAllLang() {

    const result = await fetchAllList()

    return result
  },
  async addItem({ commit, rootState, dispatch }, data) {
    const apollo = createProvider().clients.defaultClient

    const lang = rootState.locale.currentLang

    const existHouseModels = await dispatch('fetch')
    checkNameExist(existHouseModels, data.house_model_title, 'house_model_title')

    const rawFile = get(data, 'house_model_files', [])
    const parsedFile = buildFileRequestBody(
      rawFile,
      HOUSE_MODEL_ATTRIBUTE.house_model.house_model_files,
    )
    data.house_model_files = parsedFile

    const body = buildRequestBody(
      { house_model: { ...data, lang } },
      'house_model',
      HOUSE_MODEL_ATTRIBUTE,
      lang,
    )

    const response = await apollo.mutate({
      mutation: ADD_HOUSE_MODEL,
      // Parameters
      variables: {
        houseModel: body.house_model,
      },
    })

    commit('ADD_ITEM', get(response, ['data', 'insert_property_house_model_one', 'uuid']))

    return get(response, ['data', 'insert_property_house_model_one', 'uuid'], null)
  },

  async duplicateItem(context, data) {
    const existHouseModels = await context.dispatch('fetch')
    let canUseName = false
    // data.house_model_title = data.house_model_title.concat('_copy')

    while (!canUseName) {
      try {
        checkNameExist(existHouseModels, data.house_model_title, 'house_model_title')
        canUseName = true
      } catch (e) {
        data.house_model_title = data.house_model_title.concat('_copy')
      }
    }
    return await context.dispatch('addItem', data)
  },
  
  async updateItem({ commit, rootState, dispatch }, data) {
    const apollo = createProvider().clients.defaultClient

    const existHouseModels = await dispatch('fetch')
    checkNameExist(existHouseModels, data.house_model_title, 'house_model_title', data.id)

    const lang = rootState.locale.currentLang
    const rawFile = get(data, 'house_model_files', [])
    const parsedFile = buildFileRequestBody(
      rawFile,
      HOUSE_MODEL_ATTRIBUTE.house_model.house_model_files,
    )
    data.house_model_files = parsedFile

    const body = buildRequestBody(
      { house_model: { ...data, lang } },
      'house_model',
      HOUSE_MODEL_ATTRIBUTE,
      lang,
    )
    // const revisionData = await getItemByUuid(data.uuid)

    const translates = body.house_model.house_model_translates

    const houseModelTranslate = isEmpty(translates)
      ? []
      : translates.data.map((translate) => ({
          ...translate,
          house_model_uuid: data.uuid,
        }))

    const files = body.house_model.house_model_files
    const houseModelFile = isEmpty(files)
      ? []
      : files.data.map((file) => ({
          ...file,
          house_model_uuid: data.uuid,
        }))

    const tempHouseModelFunction = body.house_model.house_model_functions

    const houseModelFunction = isEmpty(tempHouseModelFunction.data)
      ? []
      : tempHouseModelFunction.data.filter((f) => f.value)
      .map((f) => ({
          ...f,
          house_function_id: parseInt(f.house_function_id),
          value: parseInt(f.value),
          house_model_uuid: data.uuid,
        }))

    const rawResponse = await apollo.mutate({
      mutation: UPDATE_HOUSE_MODEL,
      // Parameters
      variables: {
        houseModel: omit(body.house_model, [
          'house_model_translates',
          'house_model_files',
          'house_model_functions',
        ]),
        houseModelTranslate,
        houseModelFile,
        houseModelFunction,
        uuid: data.uuid,
      },
    })

    const response = get(rawResponse, ['data', 'update_property_house_model_by_pk'])

    commit('UPDATE_ITEM', response)

    return response
  },

  async delete(_, uuid) {
    const apollo = createProvider().clients.defaultClient
    const revisionData = await getItemByUuid(uuid)

    try {
      await apollo.mutate({
        mutation: DELETE_HOUSE_MODEL,
        // Parameters
        variables: {
          uuid,
          revisionData,
        },
      })
      return uuid
    } catch {
      throw { "message": "ไม่สามารถลบแบบบ้านนี้ได้เนื่องจากมี บ้านพร้อมขายใช้งานอยู่"}
    }
  },

  async fetchOne({ commit, rootState }, uuid) {
    const parsed = await getItemByUuid(uuid)
    const lang = rootState.locale.currentLang
    parsed.house_model_files = buildFileResponseBody(parsed.house_model_files, lang)

    commit('SET_ITEM', parsed)

    return parsed
  },
}
