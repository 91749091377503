import get from 'lodash/get'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import { createProvider } from '@/vue-apollo'
import {
  buildList,
  buildRow,
  extractRelation,
  buildRequestBody,
  groupAttributeByLang,
} from '@/helper/Utils'
import {
  GET_PROJECT_LIST,
  GET_PROJECT_WITH_GALLERY,
  GET_PROJECT_NAMES,
  GET_PROJECT_BY_ID,
  GET_PROJECT_BY_UUID,
  GET_EXIST_PROJECT_ID_BY_LANG,
  ADD_PROJECT,
  UPDATE_PROJECT,
  PROJECT_ATTRIBUTE,
  DELETE_PROJECT,
  GET_PROJECT_TITLE_BY_ID_LANG,
  GET_EXIST_PROJECT_ID_BY_SLUG
} from './constantGql'
// import mockData from './mockData.json'

const parseProjectData = (project = [], lang = 'th') => {
  const projectData = buildRow(project, 'project_translates', lang)

  let projectUser = get(project, 'user_group', {})
  projectUser = isNil(projectUser) ? {} : projectUser

  const projectZones = extractRelation(get(project, 'project_zones', []), 'zone')
  const projectFacilities = get(project, 'project_facilities', [])

  const zoneList = buildList(projectZones, 'zone_translates', lang)
  const facilityList = buildList(projectFacilities, 'project_facility_translates', lang)

  projectData.zone = zoneList.map((zone) => zone.zone_title).join(',')
  projectData.facilityList = facilityList
  projectData.username = projectUser.group_name
  projectData.userId = projectUser.id

  return projectData
}
export default {
  async checkSlugExist({ commit, rootState }, data) {
    const lang = rootState.locale.currentLang
    const apollo = createProvider().clients.defaultClient

    const slug = data.basicInfo.slug || data.basicInfo.title
    const response = await apollo.query({
      query: GET_EXIST_PROJECT_ID_BY_SLUG,
      variables: {
        lang,
        id: data.id,
        slug: slug.replace(/\s+/g, '-')
      },
    })
  
    const projectList = get(response, ['data', 'property_project'], [])
    const isExist = !isEmpty(projectList)
    commit('SET_IS_SLUG_EXIST', isExist)

    return isExist
  },
  
  async addProject({ commit, rootState }, data) {
    const apollo = createProvider().clients.defaultClient

    const lang = rootState.locale.currentLang
    data.lang = lang

    const body = buildRequestBody({ project: data }, 'project', PROJECT_ATTRIBUTE, lang)

    const responseProject = await apollo.mutate({
      mutation: ADD_PROJECT,
      // Parameters
      variables: {
        project: body.project,
      },
    })

    const id = get(responseProject, ['data', 'insert_property_project_one', 'id'], null)

    commit('ADD_PROJECT', get(responseProject, ['data', 'insert_property_project_one']))

    return id
  },

  async updateProject({ commit, rootState }, data) {
    const apollo = createProvider().clients.defaultClient

    // console.log('projectFiles: ', projectFiles)

    const lang = rootState.locale.currentLang

    const body = buildRequestBody({ project: data }, 'project', PROJECT_ATTRIBUTE, lang)

    // console.log('body: ', body)

    const responseProject = await apollo.mutate({
      mutation: UPDATE_PROJECT,
      // Parameters
      variables: {
        project: body.project,
        lang,
        uuid: data.uuid,
        id: data.id,
      },
    })

    commit(
      'UPDATE_PROJECT',
      get(responseProject, ['insert_property_project', 'returning']),
    )
  },

  async fetchDataListItems({ commit, rootState }) {
    const lang = rootState.locale.currentLang
    const apollo = createProvider().clients.defaultClient
    const response = await apollo.query({
      query: GET_PROJECT_LIST,
      variables: {
        lang,
      },
    })

    const projectList = get(response, ['data', 'property_project'], [])
    const parsedList = projectList.map((project) => parseProjectData(project, lang))

    commit('SET_PROJECTS', parsedList)

    return parsedList
  },

  async fetchProjectGallery({ commit, rootState }, customLang = null) {
    const lang = !customLang ? rootState.locale.currentLang : customLang
    const apollo = createProvider().clients.defaultClient
    const response = await apollo.query({
      query: GET_PROJECT_WITH_GALLERY,
      variables: {
        lang,
      },
    })

    const projectList = get(response, ['data', 'property_project'], [])
    const parsedList = projectList.map((project) => parseProjectData(project, lang))
    
    commit('SET_GALLERY_PROJECTS', parsedList)

    return parsedList
  },

  async fetchProjectName({ commit, rootState }, customLang = null) {
    const lang = !customLang ? rootState.locale.currentLang : customLang
    const apollo = createProvider().clients.defaultClient
    const response = await apollo.query({
      query: GET_PROJECT_NAMES,
      variables: {
        lang,
      },
    })

    const projectList = get(response, ['data', 'property_project'], [])
    const parsedList = projectList.map((project) => parseProjectData(project, lang))
    
    commit('SET_NAMES', parsedList)

    return parsedList
  },

  async fetchItem({ commit, rootState }, id) {
    const apollo = createProvider().clients.defaultClient
    const lang = rootState.locale.currentLang
    try {
      const response = await apollo.query({
        query: GET_PROJECT_BY_ID,
        variables: {
          lang,
          projectId: id,
        },
      })
  
      const project = get(response, ['data', 'property_project', 0], [])
      const parsedList = groupAttributeByLang(project)
  
      commit('SET_PROJECT', parsedList)

      return parsedList
    } catch (e) {
      commit('SET_PROJECT', null)
    }
  },
  async fetchItemByUUID({ commit, rootState }, uuid) {
    const apollo = createProvider().clients.defaultClient
    const lang = rootState.locale.currentLang
    try {
      const response = await apollo.query({
        query: GET_PROJECT_BY_UUID,
        variables: {
          lang,
          projectUuid: uuid,
        },
      })
  
      const project = get(response, ['data', 'property_project', 0], [])
      const parsedList = groupAttributeByLang(project)
  
      commit('SET_PROJECT', parsedList)
    } catch (e) {
      commit('SET_PROJECT', null)
    }
    
  },
  async delete(_, { uuid, email }) {
    const apollo = createProvider().clients.defaultClient

    await apollo.mutate({
      mutation: DELETE_PROJECT,
      // Parameters
      variables: {
        uuid,
        email
      },
    })

    // commit('REMOVE_PROJECT', uuid)

    return uuid
  },
  async fetchExistProjectLang(_, projectId) {
    const apollo = createProvider().clients.defaultClient

    const response = await apollo.query({
      query: GET_EXIST_PROJECT_ID_BY_LANG,
      variables: {
        projectId,
      },
    })
    
    const projects = get(response, ['data', 'property_project'], [])
    const existLang = []
    projects.forEach((project) => {
      existLang.push(project.lang)
    })

    return existLang
  },
  async fetchProjectByIdLang (_, { projectId, lang }) {
    const apollo = createProvider().clients.defaultClient

    const response = await apollo.query({
      query: GET_PROJECT_TITLE_BY_ID_LANG,
      variables: {
        projectId,
        lang
      },
    })
    
    const project = get(response, ['data', 'property_project', 0], [])
    const parsedList = groupAttributeByLang(project)

    return parsedList
  }
}
