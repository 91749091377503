import gql from 'graphql-tag'

export const CREATE_WITH_POSITION = gql`
  mutation(
    $toCreateItem: cms_content_insert_input!
    $positionToClean: Int
    $lang: String!
  ) {
    cleanPosition: update_cms_content(
      where: {position: {_eq: $positionToClean}, _and: { position: {_neq: null} }, lang: {_eq: $lang}}, 
      _set: {show_on_home: false, position: null}
    ) {
      affected_rows
      returning {
        id
      }
    },
    result: insert_cms_content_category_one(
      object: { category_id: 1, content: { data: $toCreateItem } }
    ) {
      id,
      content {
        id
      }
    }
  }
`

export const CREATE_WITHOUT_POSITION = gql`
  mutation(
    $toCreateItem: cms_content_insert_input!
    $lang: String!
  ) {
    result: insert_cms_content_category_one(
      object: { category_id: 1, content: { data: $toCreateItem } }
    ) {
      id,
      content {
        id
      }
    }
  }
`

export const UPDATE_WITHOUT_POSITION = gql`
  mutation(
    $id: Int!
    $toUpdateItem: cms_content_set_input!
    $toDeleteItem: cms_content_revision_insert_input!
    $lang: String!
    $customSlug: String!
  ) {
    updateResult: update_cms_content_by_pk(pk_columns: { id: $id }, _set: $toUpdateItem) {
      id
    }

    revisionResult: insert_cms_content_revision_one(object: $toDeleteItem) {
      id
      revision_id
    }

    update_cms_content(_set: { custom_slug: $customSlug, slug: $customSlug }, where: {
      parent_id: {
        _eq: $id
      }
      lang: {
        _neq: "th"
      }
    }) {
      affected_rows
    }
  }
`

export const UPDATE_WITH_POSITION = gql`
  mutation(
    $id: Int!
    $toUpdateItem: cms_content_set_input!
    $toDeleteItem: cms_content_revision_insert_input!
    $positionToClean: Int
    $lang: String!
    $customSlug: String!
  ) {
    cleanPosition:update_cms_content(
      where: { _and: { position: {_eq: $positionToClean, _is_null: false} }, lang: {_eq: $lang}, id: { _neq: $id }}, 
      _set: {show_on_home: false, position: null}
    ) {
      affected_rows
      returning {
        id
      }
    },
    updateResult: update_cms_content_by_pk(pk_columns: { id: $id }, _set: $toUpdateItem) {
      id
    }

    revisionResult: insert_cms_content_revision_one(object: $toDeleteItem) {
      id
      revision_id
    }

    update_cms_content(_set: { custom_slug: $customSlug, slug: $customSlug }, where: {
      parent_id: {
        _eq: $id
      }
      lang: {
        _neq: "th"
      }
    }) {
      affected_rows
    }
  }
`

export const DELETE = gql`
  mutation($id: Int!, $toDeleteItem: cms_content_revision_insert_input!) {
    deleteResult: delete_cms_content_by_pk(id: $id) {
      id
    }

    revisionResult: insert_cms_content_revision_one(object: $toDeleteItem) {
      id
      revision_id
    }
  }
`

export const FETCH_ALL = gql`
  query {
    result: cms_content(
      where: {
        status: { _neq: "archived" }
        content_categories: { category_id: { _eq: 1 } }
      }
      order_by: { updated_at: desc }
    ) {
      id
      slug
      custom_slug
      lang
      project_id
      title
      description
      show_on_home
      position
      published_date
      revision_id
      updated_at
      created_by
      updated_by
    }
  }
`

export const FETCH = gql`
  query($lang: String!) {
    result: cms_content(
      where: {
        status: { _neq: "archived" }
        lang: { _eq: $lang }
        content_categories: { category_id: { _eq: 1 } }
      }
      order_by: { updated_at: desc, position: asc }
    ) {
      id
      slug
      custom_slug
      lang
      project_id
      title
      description
      show_on_home
      position
      published_date
      revision_id
      updated_at
      created_by
      updated_by
    }
  }
`

export const FIND_ONE_BY_ID = gql`
  query($id: Int!) {
    result: cms_content_by_pk(id: $id) {
      id
      lang
      revision_id
      project_id
      title
      slug
      custom_slug
      description
      show_on_home
      position
      content
      thumbnail
      seo_title
      seo_h1
      seo_description
      seo_keywords
      seo_image
      published_date
      folder_name
      created_by
      updated_by
      updated_at
    }
  }
`
