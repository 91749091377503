<template>
  <div class="vx-row mb-6">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'FormLayout',
}
</script>

<style scoped></style>
