import gql from 'graphql-tag'

export const FETCH = gql`
  query($parent_id: Int) {
    result: cms_category(
      where: { parent_id: { _eq: $parent_id } }
      order_by: { name: asc }
    ) {
      id
      parent_id
      name
    }
  }
`
