<!-- =========================================================================================
  File Name: App.vue
  Description: Main vue file - APP
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div 
    id="app" 
    :class="vueAppClasses">
    <transition name="fade">
      <div v-if="toggler" 
        :style="{
          zIndex: '1000001'
        }"
        class="fixed w-full"
      >
        <div
          class="fixed bg-dark w-full h-screen opacity-75" 
          :style="{
            zIndex: '1000001'
          }"
          @click="toggler = !toggler"
        />
        <vs-button radius 
          color="warning" 
          type="filled" 
          size="large"
          icon-pack="feather" 
          icon="icon-x" 
          class="absolute top-0 right-0 p-2 m-8"
          :style="{
            zIndex: '1000002'
          }"
          @click="toggler = !toggler"
        />

        <div class="flex flex-col justify-center items-center h-screen">
          <div class="flex justify-center"
            :style="{
              zIndex: '1000001'
            }">
              <img :src="sources[0]" alt="Image preview" class="object-center w-10/12 p-4 rounded bg-white">
          </div>
        </div>
      </div>
    </transition>
    <router-view @setAppClasses="setAppClasses" />
  </div>
</template>

<script>
import Vue from 'vue'
import env from '@/env'
import themeConfig from '@/../themeConfig.js'
import jwt from '@/http/requests/auth/jwt/index.js'

export default {
  name: env.VUE_APP_NAME,
  data() {
    return {
      toggler: false,
      sources: [],
      sourcesKey: 0,
      vueAppClasses: [],
    }
  },
  watch: {
    '$store.state.theme'(val) {
      this.toggleClassInBody(val)
    },
    '$vs.rtl'(val) {
      document.documentElement.setAttribute('dir', val ? 'rtl' : 'ltr')
    },
  },
  mounted() {
    this.toggleClassInBody(themeConfig.theme)
    this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth)

    const vh = window.innerHeight * 0.01
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`)

    window.addEventListener("click", this.handleClickImage)
  },
  beforeDestroy() {
    window.removeEventListener("click", this.handleClickImage)
  },
  async created() {
    // jwt
    jwt.init()

    const dir = this.$vs.rtl ? 'rtl' : 'ltr'
    document.documentElement.setAttribute('dir', dir)

    window.addEventListener('resize', this.handleWindowResize)
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed() {
    window.removeEventListener('resize', this.handleWindowResize)
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    async handleClickImage(i) {
      if (i.target.nodeName.toLowerCase() === "img") {
        const src = i.target.src

        if ((i.target.alt && (i.target.alt.search('th') >= 0  
          || i.target.alt.search('en') >= 0 
          || i.target.alt.search('cn') >= 0
          )) || src.search('base64') >= 0
          || i.target.alt.search('login') >= 0
        ) {
          return
        }

        this.sources = [src]
        const splitSrc = src.split('/')
        const envWebsite = splitSrc.find(v => v === env.VUE_APP_CLOUDINARY_DEFAULT_FOLDER)
        // console.log('index', splitSrc.indexOf(envWebsite), splitSrc.slice(splitSrc.indexOf(envWebsite)))
        this.sources = [`${env.VUE_APP_IMAGE_URL}/f_auto/${splitSrc.slice(splitSrc.indexOf(envWebsite)).join('/')}`]
        Vue.nextTick().then(() => {
          this.toggler = true
        })

      }
    },
    toggleClassInBody(className) {
      if (className === 'dark') {
        if (document.body.className.match('theme-semi-dark'))
          document.body.classList.remove('theme-semi-dark')
        document.body.classList.add('theme-dark')
      } else if (className === 'semi-dark') {
        if (document.body.className.match('theme-dark'))
          document.body.classList.remove('theme-dark')
        document.body.classList.add('theme-semi-dark')
      } else {
        if (document.body.className.match('theme-dark'))
          document.body.classList.remove('theme-dark')
        if (document.body.className.match('theme-semi-dark'))
          document.body.classList.remove('theme-semi-dark')
      }
    },
    setAppClasses(classesStr) {
      this.vueAppClasses.push(classesStr)
    },
    handleWindowResize() {
      this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth)

      // Set --vh property
      document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`)
    },
    handleScroll() {
      this.$store.commit('UPDATE_WINDOW_SCROLL_Y', window.scrollY)
    },
  },
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
