import gql from 'graphql-tag'

export const CREATE = gql`
  mutation($toCreateItem: cms_csr_insert_input!) {
    result: insert_cms_csr_one(object: $toCreateItem) {
      uuid
      id
    }
  }
`
export const UPDATE = gql`
  mutation (
    $csr: [cms_csr_insert_input!]!
    $id: Int!
    $lang: String!
  ) {
    update_cms_csr(
      _set: { status: "archived" }
      where: {
        id: { _eq: $id }
        lang: { _eq: $lang }
      }
    ) {
      affected_rows
    }
    updateResult: insert_cms_csr(objects: $csr) {
      returning {
        uuid
      }
    }
  }
`

export const DELETE = gql`
  mutation ($uuid: uuid!) {
    update_cms_csr(where: {uuid: {_eq: $uuid}}, _set: {status: "archived"}) {
      affected_rows 
    }
  }
`

export const FETCH = gql`
  query($lang: String!) {
    result: cms_csr(
      where: { status: { _neq: "archived" }, lang: { _eq: $lang } }
      order_by: { updated_at: desc }
    ) {
      uuid
      title
      thumbnail
      url
      id
      status
      lang
      activity_date
      published_date
      created_by
      updated_by
      created_at
      updated_at
      show_homepage
    }
  }
`

export const FIND_ONE_BY_ID = gql`
  query($id: Int!) {
    result: cms_csr(
      where: {
        status: { _neq: "archived" }, id: { _eq: $id }
      }
      order_by: { updated_at: desc }
    ) {
      uuid
      title
      thumbnail
      url
      id
      status
      lang
      activity_date
      published_date
      created_by
      updated_by
      created_at
      updated_at
      show_homepage
      folder_name
    }
  }
`
