import get from 'lodash/get'
import { createProvider } from '@/vue-apollo'
import { FETCH } from './constantGql'

export default {
  async fetchByParentId(context, parentId = null) {
    const apollo = createProvider().clients.defaultClient
    const response = await apollo.query({
      query: FETCH,
      variables: { parent_id: parentId },
    })

    context.commit('SET_ITEMS', get(response, ['data', 'result']))

    return get(response, ['data', 'result'])
  },
}
