// import moment from 'moment'
// import get from 'lodash/get'
import filter from 'lodash/filter'

export default {
  // getItem: state => (brandId) => state.brands.find((brand) => brand.id == brandId),
  promotionActive: state => {
    return filter(state.items, (v) => {
      // const startDate = moment(`${get(v, 'start_date')} 00:00:00`)
      // const endDate = moment(`${get(v, 'end_date')} 23:59:59`)
      // const nowDate = moment()

      // console.log('query promotion', { startDate, endDate, nowDate, condition: nowDate.isSameOrAfter(startDate)  && nowDate.isSameOrBefore(endDate)})

      // return nowDate.isSameOrAfter(startDate)  && nowDate.isSameOrBefore(endDate)
      return !v.is_approved
    }) 
  }
}
