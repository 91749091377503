import get from 'lodash/get'
import { createProvider } from '@/vue-apollo'
import { buildList } from '@/helper/Utils'
import { GET_UNIT } from './constantGql'

export default {
  async fetchUnitItems({ commit }) {
    // const lang = rootState.locale.currentLang
    const apollo = createProvider().clients.defaultClient
    const response = await apollo.query({
      query: GET_UNIT,
    })

    const rawList = get(response, ['data', 'units'], [])
    const parsedData = buildList(rawList, 'unit_labels', 'th')

    commit('SET_UNITS', parsedData)
  },
}
