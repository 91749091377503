export default {
  ADD_ZONE(state, item) {
    state.zones.unshift(item)
  },
  SET_ZONES(state, zones) {
    state.zones = zones
  },
  SET_ITEMS(state, items) {
    state.items = items
  },
  UPDATE_ZONE(state, item) {
    const itemIndex = state.zones.findIndex((p) => p.id === item.id)
    Object.assign(state.brands[itemIndex], item)
  },
  REMOVE_ZONE(state, itemId) {
    const ItemIndex = state.zones.findIndex((p) => p.id === itemId)
    state.zones.splice(ItemIndex, 1)
  },
}
