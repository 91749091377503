import get from 'lodash/get'
import { createAssetWithObjectCld } from '@/helper/damUtils'
import { createProvider } from '@/vue-apollo'
import {
  GET_MAGAZINE_ITEM,
  GET_MAGAZINE_LIST,
  ADD_MAGAZINE,
  UPDATE,
  DELETE_MAGAZINE,
} from './constantGql'

export default {
  async fetch({ commit, rootState }) {
    const apollo = createProvider().clients.defaultClient
    const response = await apollo.query({
      query: GET_MAGAZINE_LIST,
      variables: {
        lang: rootState.locale.currentLang,
      },
    })
    commit('SET_MAGAZINES', get(response, ['data', 'result']))
  },
  async fetchOne(context, revision_id) {
    console.log('fetch one', revision_id)
    const apollo = createProvider().clients.defaultClient
    const response = await apollo.query({
      query: GET_MAGAZINE_ITEM,
      variables: { revision_id },
    })
    return get(response, ['data', 'result'])
  },
  async addItem(context, data) {
    const { title, description, is_new, startDate, endDate, folder_name, created_by, updated_by } = data
    let thumbnailId = null
    let documentId = null

    if (data.thumbnail.length > 0) {
      thumbnailId = await createAssetWithObjectCld(data.thumbnail[0])
    }

    if (data.document.length > 0) {
      documentId = await createAssetWithObjectCld(data.document[0])
    }

    const variables = {
      title,
      description,
      thumbnailId,
      fileId: documentId,
      startDate,
      endDate,
      is_new,
      lang: context.rootState.locale.currentLang,
      folder_name,
      created_by,
      updated_by
    }

    const apollo = createProvider().clients.defaultClient
    const {
      data: { result },
    } = await apollo.mutate({
      mutation: ADD_MAGAZINE,
      variables,
    })

    return result
  },
  async updateItem(context, data) {
    const { id, title, description, is_new, startDate, endDate, folder_name, created_by, updated_by } = data
    let thumbnailId = null
    let documentId = null

    if (data.thumbnail.length > 0) {
      thumbnailId = await createAssetWithObjectCld(data.thumbnail[0])
    }

    if (data.document.length > 0) {
      documentId = await createAssetWithObjectCld(data.document[0])
    }

    const variables = {
      title,
      description,
      thumbnailId,
      fileId: documentId,
      startDate,
      endDate,
      is_new,
      lang: context.rootState.locale.currentLang,
      folder_name,
      created_by,
      updated_by
    }

    if (id) {
      variables['id'] = id
    }

    const apollo = createProvider().clients.defaultClient
    const {
      data: { result },
    } = await apollo.mutate({
      mutation: UPDATE,
      variables,
    })

    return result
  },
  async delete({ dispatch }, revision_id) {
    const apollo = createProvider().clients.defaultClient
    const response = await apollo.mutate({
      mutation: DELETE_MAGAZINE,
      variables: { revision_id },
    })
    console.log(response)
    await dispatch('fetch')
  },
}
