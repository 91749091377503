// NOTE
// Please use your own firebase details below. For more details visit: https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/documentation/development/firebaseIntegration.html

import firebase from 'firebase/app'
import env from '@/env'

// Initialize Firebase
const config = {
  apiKey: env.VUE_APP_FIREBASE_API_KEY,
  authDomain: env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: env.VUE_APP_FIREBASE_DB_URL,
  projectId: env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: env.VUE_APP_FIREBASE_BUCKET,
  messagingSenderId: env.VUE_APP_FIREBASE_SENDER_ID,
  appId: env.VUE_APP_FIREBASE_APP_ID,
  measurementId: env.VUE_APP_FIREBASE_MEASUREMENT_ID,
}

firebase.initializeApp(config)
