import gql from 'graphql-tag'

export const GET_PAGE = gql`
  query Languages($type: [String!]!, $lang: String!) {
    pages: cms_page(
      where: { type: { _in: $type }, lang: { _eq: $lang }, status: { _eq: "published" } }
      order_by: { updated_at: desc }
    ) {
      id
      type
      title
      page_name
      lang
      url
      project_page_links {
        project_uuid
        project_id
      }
    }
  }
`
