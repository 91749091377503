import get from 'lodash/get'
import omit from 'lodash/omit'
import { createProvider } from '@/vue-apollo'
import { FETCH, CREATE, UPDATE, DELETE, FIND_ONE_BY_ID } from './constantGql'

export default {
  async fetch(context) {
    const apollo = createProvider().clients.defaultClient
    const response = await apollo.query({
      query: FETCH,
    })

    context.commit('SET_ITEMS', get(response, ['data', 'result']))
  },

  async fetchOne(context, id) {
    const apollo = createProvider().clients.defaultClient
    const response = await apollo.query({
      query: FIND_ONE_BY_ID,
      variables: { id },
    })
    return get(response, ['data', 'result'])
  },

  async addItem(context, data) {
    const apollo = createProvider().clients.defaultClient
    try {
      const {
        data: { result },
      } = await apollo.mutate({
        mutation: CREATE,
        variables: {
          toCreateItem: {
            ...omit(data, ['id', 'created_at', 'updated_at', 'lang']),
          },
        },
      })
      return result
    } catch (error) {
      if (error.message.indexOf('unique constraint "seo_url_key"') > -1) {
        throw 'ไม่สามารถบันทึกข้อมูลได้ เนื่องจาก URL ซ้ำกับข้อมูลที่มีอยู่ในระบบ'
      } else {
        throw error
      }
    }
  },

  async updateItem(context, { initialData, formData }) {
    const apollo = createProvider().clients.defaultClient

    const {
      data: { updateResult },
    } = await apollo.mutate({
      mutation: UPDATE,
      variables: {
        id: formData.id,
        toUpdateItem: formData,
        toDeleteItem: initialData,
      },
    })

    return updateResult.id
  },

  async delete(context, id) {
    const apollo = createProvider().clients.defaultClient

    const {
      data: { result: toDeleteItem },
    } = await apollo.mutate({
      mutation: FIND_ONE_BY_ID,
      variables: { id },
    })

    const result = await apollo.mutate({
      mutation: DELETE,
      variables: {
        id,
        toDeleteItem: {
          ...omit(toDeleteItem, ['__typename', 'updated_at']),
          status: 'archived',
        },
      },
    })

    return result
  },
}
