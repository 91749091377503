import gql from 'graphql-tag'

export const GET_PROJECT_HOUSE_SELL_LIST = gql`
query GET_LIST ($project_uuid: uuid!, $filter:[Boolean!]!){
  project_house_sell:property_project_house_sell(
    where: {
      status: { _neq: "archived" }
      project_house_sell_links: {
        project_uuid: { _eq: $project_uuid }
      }
      is_sold_out: { _in: $filter }
    }
    order_by: { updated_at: desc }
  ) {
      uuid
      status
      updated_at
      created_at
      created_by
      updated_by
      is_default
      is_sold_out
      house_model {
        property_category {
          property_category_translates(
          where: {
            attribute_code: {
              _in: ["project_category_name"]
            }
          }
        ) {
            attribute_code
            lang
            value
          }
        }
        house_model_translates(
          where: {
            attribute_code: {
              _in: ["house_model_title", "house_model_code"]
            }
          }
        ) {
          attribute_code
          lang
          value
        }
      }
      project_house_sell_translates(
        where: {
          attribute_code: {
            _in: ["project_house_sell_number", "show_list_page", "project_house_sell_start_date", "project_house_sell_end_date", "project_house_sell_sold_out_end_date"]
          }
        }
      ) {
        lang
        value
        attribute_code
      }
    }
  }
`

export const GET_PROJECT_HOUSE_SELL_ITEM = gql`
  query GET_ITEM($uuid: uuid!) {
    project_house_sell:property_project_house_sell(
      where: { status: { _neq: "archived" }, uuid: { _eq: $uuid } }
    ) {
      uuid
      status
      updated_at
      created_at
      created_by
      updated_by
      is_default
      is_sold_out
      project_house_sell_features {
        house_function_feature_id
        value
      }
      house_model {
        id
        uuid
        updated_at
        created_at
        house_serie {
          id
          uuid
          house_serie_translates {
            lang
            value
            attribute_code
          }
          house_serie_files {
            uuid
            attribute_code
            image
            house_serie_file_translates {
              lang
              value
              attribute_code
            }
          }
        }
        house_model_functions {
          value
          house_function_id
        }
        house_model_translates {
          lang
          value
          attribute_code
        }
        house_model_files {
          uuid
          attribute_code
          image
          is_default
          house_model_file_translates {
            lang
            value
            attribute_code
          }
        }
      }
      project_house_sell_translates {
        lang
        value
        attribute_code
      }
      project_house_sell_files (
        order_by: {
          updated_at: asc
        }
      ) {
        uuid
        attribute_code
        image
        is_default
        project_house_sell_file_translates {
          lang
          value
          attribute_code
        }
      }
    }
  }
`

export const ADD_PROJECT_HOUSE_SELL = gql`
  mutation ADD($projectHouseSell: property_project_house_sell_insert_input!) {
    insert_property_project_house_sell_one(object: $projectHouseSell) {
      uuid
    }
  }
`

export const UPDATE_PROJECT_HOUSE_SELL = gql`
  mutation update(
    $projectHouseSell: property_project_house_sell_set_input!
    $projectHouseSellFeature: [property_project_house_sell_feature_insert_input!]!
    $projectHouseSellTranslate: [property_project_house_sell_translate_insert_input!]!
    $projectHouseSellFile: [property_project_house_sell_file_insert_input!]!
    $uuid: uuid!
  ) {
    update_property_project_house_sell_by_pk(_set: $projectHouseSell, pk_columns: { uuid: $uuid }) {
      uuid
    }

    delete_property_project_house_sell_feature(where: { project_house_sell_uuid: { _eq: $uuid } }) {
      affected_rows
    }
    insert_property_project_house_sell_feature(objects: $projectHouseSellFeature) {
      returning {
        uuid
      }
    }

    delete_property_project_house_sell_translate(where: { project_house_sell_uuid: { _eq: $uuid } }) {
      affected_rows
    }
    insert_property_project_house_sell_translate(objects: $projectHouseSellTranslate) {
      returning {
        uuid
      }
    }

    delete_property_project_house_sell_file(where: { project_house_sell_uuid: { _eq: $uuid } }) {
      affected_rows
    }
    insert_property_project_house_sell_file(objects: $projectHouseSellFile) {
      returning {
        uuid
      }
    }
  }
`

export const UPDATE_PROJECT_HOUSE_SELL_BY_UUID = gql`
  mutation update(
    $projectHouseSell: property_project_house_sell_set_input!
    $uuid: uuid!
  ) {
    update_property_project_house_sell_by_pk(_set: $projectHouseSell, pk_columns: { uuid: $uuid }) {
      uuid
    }
  }
`

export const DELETE_PROJECT_HOUSE_SELL = gql`
  mutation DELETE($uuid: uuid!) {
    update_property_project_house_sell_by_pk(_set: { status: "archived" }, pk_columns: { uuid: $uuid }) {
      uuid
    }
  }
`
