import get from 'lodash/get'
import omit from 'lodash/omit'
import isEmpty from 'lodash/isEmpty'

import { createProvider } from '@/vue-apollo'
import {
  buildList,
  buildRow,
  buildRequestBody,
  groupAttributeByLang,
  buildFileRequestBody,
  buildFileResponseBody,
} from '@/helper/Utils'

import {
  GET_PROJECT_HOUSE_SELL_LIST,
  GET_PROJECT_HOUSE_SELL_ITEM,
  ADD_PROJECT_HOUSE_SELL,
  UPDATE_PROJECT_HOUSE_SELL_BY_UUID,
  UPDATE_PROJECT_HOUSE_SELL,
  DELETE_PROJECT_HOUSE_SELL,
} from './constantGql'

import moment from 'moment'

import PROJECT_HOUSE_SELL_ATTRIBUTE from './attributeJson.json'

const isExpired = (data) => {
  const today = moment().format()
  const startDate = data.project_house_sell_start_date
  const soldEndDate = data.project_house_sell_sold_out_end_date
  const endDate = data.project_house_sell_end_date
  
  if (data && data.show_list_page === 'true' && !data.is_sold_out) return false
  if (data.is_sold_out && moment(soldEndDate).isSameOrBefore(endDate, 'day')) return !moment(today).isBetween(startDate, soldEndDate, 'day', '[]')
  return !moment(today).isBetween(startDate, endDate, 'day', '[]')
}

const checkExistNumber = (existDataList, number, uuid) => {
  const existData = existDataList.find((data) => {
    return data['project_house_sell_number'] === number && uuid !== data.uuid && !isExpired(data)
  })
  
  if (existData) throw "มีชื่อนี้อยู่ในระบบแล้ว"
}

const getItemByUuid = async (uuid) => {
  const apollo = createProvider().clients.defaultClient
  const response = await apollo.query({
    query: GET_PROJECT_HOUSE_SELL_ITEM,
    variables: {
      uuid,
    },
  })

  const rawData = get(response, ['data', 'project_house_sell', 0], [])
  const parsedList = groupAttributeByLang(rawData)

  return parsedList
}

export default {
  async fetch({ commit, rootState }, data) {
    if (!data) {
      commit('SET_ITEMS', [])
      return []
    }

    const { project_uuid, filter = [true, false] } = data

    const apollo = createProvider().clients.defaultClient
    const response = await apollo.query({
      query: GET_PROJECT_HOUSE_SELL_LIST,
      variables: {
        project_uuid,
        filter
      }
    })

    const rawList = get(response, ['data', 'project_house_sell'], [])
    const lang = rootState.locale.currentLang
    let parsedData = buildList(rawList, 'project_house_sell_translates', lang)

    parsedData = parsedData.map((data) => {
      const newData = {
        ...omit(data, 'house_model'),
      }

      const rawHouseModel = get(data, ['house_model'], {})
      const parsedHouseModel = buildRow(rawHouseModel, 'house_model_translates', lang)
      newData.house_model_title = get(parsedHouseModel, 'house_model_title', '')
      newData.house_model_code = get(parsedHouseModel, 'house_model_code', '')

      const rawPropertyCategory = get(data, ['house_model', 'property_category'], {})
      const parsedPropertyCategory = buildRow(rawPropertyCategory, 'property_category_translates', lang)
      newData.project_category_name = get(parsedPropertyCategory, 'project_category_name', '')

      return newData
    })

    commit('SET_ITEMS', parsedData)

    return parsedData
  },
  async addItem({ commit, rootState, dispatch }, data) {
    const apollo = createProvider().clients.defaultClient

    const projectHouseSell = await dispatch('fetch', {
      project_uuid: get(rootState.project, 'project.uuid'),
    })

    checkExistNumber(projectHouseSell, data.project_house_sell_number)

    const lang = rootState.locale.currentLang
    const rawFile = get(data, 'project_house_sell_files', [])
    const parsedFile = buildFileRequestBody(
      rawFile,
      PROJECT_HOUSE_SELL_ATTRIBUTE.project_house_sell.project_house_sell_files,
    )
    data.project_house_sell_files = parsedFile

    const body = buildRequestBody(
      { project_house_sell: { ...data, lang } },
      'project_house_sell',
      PROJECT_HOUSE_SELL_ATTRIBUTE,
      lang,
    )

    const response = await apollo.mutate({
      mutation: ADD_PROJECT_HOUSE_SELL,
      // Parameters
      variables: {
        projectHouseSell: body.project_house_sell,
      },
    })

    commit('ADD_ITEM', get(response, ['data', 'insert_property_project_house_sell_one', 'uuid']))

    return get(response, ['data', 'insert_property_project_house_sell_one', 'uuid'], null)
  },

  async updateItem({ commit, rootState, dispatch }, data) {
    const apollo = createProvider().clients.defaultClient

    const projectHouseSell = await dispatch('fetch', {
      project_uuid: get(rootState.project, 'project.uuid'),
    })

    try {
      checkExistNumber(projectHouseSell, data.project_house_sell_number, data.uuid)
    } catch (e) {
      throw e
    }
    

    const lang = rootState.locale.currentLang
    const rawFile = get(data, 'project_house_sell_files', [])
    const parsedFile = buildFileRequestBody(
      rawFile,
      PROJECT_HOUSE_SELL_ATTRIBUTE.project_house_sell.project_house_sell_files,
    )
    data.project_house_sell_files = parsedFile

    const body = buildRequestBody(
      { project_house_sell: { ...data, lang } },
      'project_house_sell',
      PROJECT_HOUSE_SELL_ATTRIBUTE,
      lang,
    )
    // const revisionData = await getItemByUuid(data.uuid)
    const features = body.project_house_sell.project_house_sell_features

    const projectHouseSellFeature = isEmpty(features)
      ? []
      : features.data.map((translate) => ({
          ...translate,
          project_house_sell_uuid: data.uuid,
        }))

    const translates = body.project_house_sell.project_house_sell_translates

    const projectHouseSellTranslate = isEmpty(translates)
      ? []
      : translates.data.map((translate) => ({
          ...translate,
          project_house_sell_uuid: data.uuid,
        }))

    const files = body.project_house_sell.project_house_sell_files
    const projectHouseSellFile = isEmpty(files)
      ? []
      : files.data.map((file) => ({
          ...file,
          project_house_sell_uuid: data.uuid,
        }))

    const rawResponse = await apollo.mutate({
      mutation: UPDATE_PROJECT_HOUSE_SELL,
      // Parameters
      variables: {
        projectHouseSell: omit(body.project_house_sell, [
          'project_house_sell_translates',
          'project_house_sell_files',
          'project_house_sell_features',
          'project_house_sell_links'
        ]),
        projectHouseSellTranslate,
        projectHouseSellFile,
        projectHouseSellFeature,
        uuid: data.uuid,
      },
    })

    const response = get(rawResponse, ['data', 'update_property_project_house_sell_by_pk'])

    commit('UPDATE_ITEM', response)

    return response
  },

  async updateItemByUUID(_, data) {
    const apollo = createProvider().clients.defaultClient

    const rawResponse = await apollo.mutate({
      mutation: UPDATE_PROJECT_HOUSE_SELL_BY_UUID,
      // Parameters
      variables: {
        projectHouseSell: omit(data, 'uuid'),
        uuid: data.uuid,
      },
    })

    const response = get(rawResponse, ['data', 'update_property_project_house_sell_by_pk'])

    return response
  },

  async delete(_, uuid) {
    const apollo = createProvider().clients.defaultClient

    await apollo.mutate({
      mutation: DELETE_PROJECT_HOUSE_SELL,
      // Parameters
      variables: {
        uuid
      },
    })

    return uuid
  },

  async fetchOne({ commit }, uuid) {
    const parsed = await getItemByUuid(uuid)
    parsed.project_house_sell_files = buildFileResponseBody(parsed.project_house_sell_files)

    commit('SET_ITEM', parsed)

    return parsed
  },
}
