import gql from 'graphql-tag'

export const FETCH = gql`
  query {
    result: cms_report(
      order_by: { created_at: desc }
    ) {
      uuid
      email_to
      email_from
      telephone
      message
      created_at
      updated_at
    }
  }
`

