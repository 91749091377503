import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'
import { createProvider } from '@/vue-apollo'
import { buildList, buildRequestBody, groupAttributeByLang } from '@/helper/Utils'
import {
  GET_ROOM_FEATURE_LIST,
  GET_ROOM_FEATURE_ITEM,
  ADD_ROOM_FEATURE,
  UPDATE_ROOM_FEATURE,
  DELETE_ROOM_FEATURE,
} from './constantGql'

import ROOM_FEATURE_ATTRIBUTE from './attributeJson.json'

const getItemByUuid = async (uuid) => {
  const apollo = createProvider().clients.defaultClient
  const response = await apollo.query({
    query: GET_ROOM_FEATURE_ITEM,
    variables: {
      uuid,
    },
  })

  const rawData = get(response, ['data', 'room_feature', 0], [])
  const parsedList = groupAttributeByLang(rawData)

  return parsedList
}

export default {
  async fetch({ commit, rootState }) {
    const apollo = createProvider().clients.defaultClient
    const response = await apollo.query({ query: GET_ROOM_FEATURE_LIST })
    const rawList = get(response, ['data', 'property_room_feature'], [])
    const parsedData = buildList(
      rawList,
      'room_feature_translates',
      rootState.locale.currentLang,
    )
    commit('SET_ITEMS', parsedData)
  },
  async addItem({ commit, rootState }, data) {
    const apollo = createProvider().clients.defaultClient

    const lang = rootState.locale.currentLang
    const body = buildRequestBody(
      { room_feature: data },
      'room_feature',
      ROOM_FEATURE_ATTRIBUTE,
      lang,
    )

    const response = await apollo.mutate({
      mutation: ADD_ROOM_FEATURE,
      // Parameters
      variables: {
        roomFeature: body.room_feature,
      },
    })

    const id = get(response, ['data', 'insert_property_room_feature_one', 'uuid'], null)

    commit(
      'ADD_ROOM_FEATURE',
      get(response, ['data', 'insert_property_room_feature_one']),
    )

    return id
  },

  async updateItem({ commit, rootState }, data) {
    const apollo = createProvider().clients.defaultClient

    const lang = rootState.locale.currentLang
    const body = buildRequestBody(
      { room_feature: data },
      'room_feature',
      ROOM_FEATURE_ATTRIBUTE,
      lang,
    )
    const translates = body.room_feature.room_feature_translates
    const roomFeatureTranslate = isEmpty(translates)
      ? []
      : translates.data.map((translate) => ({
          ...translate,
          room_feature_uuid: data.uuid,
        }))

    const rawResponse = await apollo.mutate({
      mutation: UPDATE_ROOM_FEATURE,
      // Parameters
      variables: {
        roomFeature: omit(body.room_feature, ['room_feature_translates']),
        roomFeatureTranslate,
        uuid: data.uuid,
        lang,
      },
    })

    const response = get(rawResponse, ['data', 'update_property_room_feature_by_pk'])
    commit('UPDATE_ROOM_FEATURE', response)

    return response
  },

  async delete({ commit }, uuid) {
    const apollo = createProvider().clients.defaultClient
    const revisionData = await getItemByUuid(uuid)

    await apollo.mutate({
      mutation: DELETE_ROOM_FEATURE,
      // Parameters
      variables: {
        uuid,
        revisionData,
      },
    })

    commit('DELETE_ROOM_FEATURE', uuid)

    return uuid
  },

  async fetchOne({ commit }, uuid) {
    const parsedList = await getItemByUuid(uuid)

    commit('SET_ITEM', parsedList)

    return parsedList
  },
}
