import get from 'lodash/get'
import omit from 'lodash/omit'
import { createProvider } from '@/vue-apollo'
import { FETCH, CREATE, UPDATE, DELETE, FIND_ONE_BY_ID, GET_EXIST_PROJECT_ID } from './constantGql'
// import { createAssetWithObjectCld, updateAssetWithObjectCld } from '@/helper/damUtils'

export default {
  async fetch(context) {
    const apollo = createProvider().clients.defaultClient
    const response = await apollo.query({
      query: FETCH,
      variables: {
        lang: context.rootState.locale.currentLang,
      },
    })

    context.commit('SET_ITEMS', get(response, ['data', 'result']))
  },

  async fetchOne(context, id) {
    const apollo = createProvider().clients.defaultClient
    const response = await apollo.query({
      query: FIND_ONE_BY_ID,
      variables: { id },
    })
    return get(response, ['data', 'result'])
  },

  async addItem(context, data) {
    const apollo = createProvider().clients.defaultClient
    const {
      data: { result },
    } = await apollo.mutate({
      mutation: CREATE,
      variables: {
        toCreateItem: {
          ...omit(data, ['id', 'created_at']),
          lang: context.rootState.locale.currentLang,
        },
      },
    })

    return result
  },

  async updateItem(context, { initialData, formData }) {
    const apollo = createProvider().clients.defaultClient

    // await apollo.mutate({
    //   mutation: DELETE,
    //   variables: {
    //     id: initialData.id,
    //     toDeleteItem: {
    //       ...initialData,
    //       status: 'archived',
    //     },
    //   },
    // })

    const {
      data: { result },
    } = await apollo.mutate({
      mutation: UPDATE,
      variables: {
        id: initialData.id,
        toDeleteItem: {
          ...omit(initialData, 'revision_id'),
          status: 'archived',
        },
        toUpdateItem: {...omit(formData, ['updated_at', 'id'])},
      },
    })

    return result.id
  },

  async delete(context, id) {
    const apollo = createProvider().clients.defaultClient

    const {
      data: { result: toDeleteItem },
    } = await apollo.mutate({
      mutation: FIND_ONE_BY_ID,
      variables: { id },
    })

    const result = await apollo.mutate({
      mutation: DELETE,
      variables: {
        id,
        toDeleteItem: {
          ...omit(toDeleteItem, ['revision_id', '__typename', 'updated_at']),
          status: 'archived',
        },
      },
    })

    return result
  },
  async fetchExistProjectLang(_, projectId) {
    const apollo = createProvider().clients.defaultClient

    const response = await apollo.query({
      query: GET_EXIST_PROJECT_ID,
      variables: {
        projectId,
      },
    })
    
    const projects = get(response, ['data', 'result'], [])
    const existLang = []
    projects.forEach((project) => {
      existLang.push(project.lang)
    })

    return existLang
  }
}
