import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'
import { createProvider } from '@/vue-apollo'
import {
  buildList,
  buildRequestBody,
  groupAttributeByLang,
  buildFileRequestBody,
  buildFileResponseBody,
  checkNameExist
} from '@/helper/Utils'

import {
  GET_HOUSE_SERIE_LIST,
  GET_HOUSE_SERIE_ITEM,
  ADD_HOUSE_SERIE,
  UPDATE_HOUSE_SERIE,
  DELETE_HOUSE_SERIE,
  GET_HOUSE_SERIE_ALL_LIST
} from './constantGql'

import HOUSE_SERIE_ATTRIBUTE from './attributeJson.json'

const getItemByUuid = async (uuid) => {
  const apollo = createProvider().clients.defaultClient
  const response = await apollo.query({
    query: GET_HOUSE_SERIE_ITEM,
    variables: {
      uuid,
    },
  })

  const rawData = get(response, ['data', 'house_serie', 0], [])
  const parsedList = groupAttributeByLang(rawData)

  return parsedList
}

const fetchAllList = async () => {
  const apollo = createProvider().clients.defaultClient
  const response = await apollo.query({
    query: GET_HOUSE_SERIE_ALL_LIST
  })

  return get(response, ['data', 'house_serie'], [])
}

export default {
  async fetch({ commit, rootState }) {
    const apollo = createProvider().clients.defaultClient
    const response = await apollo.query({
      query: GET_HOUSE_SERIE_LIST,
      variables: {
        lang: rootState.locale.currentLang,
      },
    })
    const rawList = get(response, ['data', 'house_serie'], [])
    const parsedData = buildList(
      rawList,
      'house_serie_translates',
      rootState.locale.currentLang,
    )

    commit('SET_ITEMS', parsedData)

    return parsedData
  },
  async addItem({ dispatch, commit, rootState }, data) {
    const apollo = createProvider().clients.defaultClient
    const lang = rootState.locale.currentLang
    
    const existHouseSeries = await dispatch('fetch')
    checkNameExist(existHouseSeries, data.house_serie_title, 'house_serie_title')

    const rawFile = get(data, 'house_serie_files', [])
    const parsedFile = buildFileRequestBody(
      rawFile,
      HOUSE_SERIE_ATTRIBUTE.house_serie.house_serie_files,
    )
    data.house_serie_files = parsedFile
    data.lang = lang

    const body = buildRequestBody(
      { house_serie: data },
      'house_serie',
      HOUSE_SERIE_ATTRIBUTE,
      lang,
    )

    const response = await apollo.mutate({
      mutation: ADD_HOUSE_SERIE,
      // Parameters
      variables: {
        houseSerie: body.house_serie,
      },
    })

    const id = get(response, ['data', 'insert_property_house_serie_one', 'uuid'], null)

    commit('ADD_ITEM', get(response, ['data', 'insert_property_house_serie_one']))

    return id
  },

  async duplicateItem({ dispatch, commit, rootState }, data) {
    const apollo = createProvider().clients.defaultClient
    const lang = rootState.locale.currentLang

    const existHouseSeries = await dispatch('fetch')
    let canUseName = false
    data.house_serie_title = data.house_serie_title

    while (!canUseName) {
      try {
        checkNameExist(existHouseSeries, data.house_serie_title, 'house_serie_title')
        canUseName = true
      } catch (e) {
        data.house_serie_title = data.house_serie_title.concat('_copy')
      }
    }

    const rawFile = get(data, 'house_serie_files', [])
    const parsedFile = buildFileRequestBody(
      rawFile,
      HOUSE_SERIE_ATTRIBUTE.house_serie.house_serie_files,
    )
    data.house_serie_files = parsedFile
    data.lang = lang

    const body = buildRequestBody(
      { house_serie: data },
      'house_serie',
      HOUSE_SERIE_ATTRIBUTE,
      lang,
    )

    const response = await apollo.mutate({
      mutation: ADD_HOUSE_SERIE,
      // Parameters
      variables: {
        houseSerie: body.house_serie,
      },
    })

    const id = get(response, ['data', 'insert_property_house_serie_one', 'uuid'], null)

    commit('ADD_ITEM', get(response, ['data', 'insert_property_house_serie_one']))

    return id
  },

  async updateItem({ commit, dispatch, rootState }, data) {
    const apollo = createProvider().clients.defaultClient

    const existHouseSeries = await dispatch('fetch')
    checkNameExist(existHouseSeries, data.house_serie_title, 'house_serie_title', data.id)
    
    const lang = rootState.locale.currentLang
    const rawFile = get(data, 'house_serie_files', [])
    const parsedFile = buildFileRequestBody(
      rawFile,
      HOUSE_SERIE_ATTRIBUTE.house_serie.house_serie_files,
    )
    data.house_serie_files = parsedFile

    const body = buildRequestBody(
      { house_serie: data },
      'house_serie',
      HOUSE_SERIE_ATTRIBUTE,
      lang,
    )
    // const revisionData = await getItemByUuid(data.uuid)

    const translates = body.house_serie.house_serie_translates

    const houseSerieTranslate = isEmpty(translates)
      ? []
      : translates.data.map((translate) => ({
          ...translate,
          house_serie_uuid: data.uuid,
        }))

    const files = body.house_serie.house_serie_files
    const houseSerieFile = isEmpty(files)
      ? []
      : files.data.map((file) => ({
          ...file,
          house_serie_uuid: data.uuid,
        }))

    const rawResponse = await apollo.mutate({
      mutation: UPDATE_HOUSE_SERIE,
      // Parameters
      variables: {
        houseSerie: omit(body.house_serie, [
          'house_serie_translates',
          'house_serie_files',
        ]),
        houseSerieTranslate,
        houseSerieFile,
        uuid: data.uuid,
      },
    })

    const response = get(rawResponse, ['data', 'update_property_house_serie_by_pk'])
    commit('UPDATE_ITEM', response)

    return response
  },

  async delete({ commit }, uuid) {
    const apollo = createProvider().clients.defaultClient
    const revisionData = await getItemByUuid(uuid)

    try {
      await apollo.mutate({
        mutation: DELETE_HOUSE_SERIE,
        // Parameters
        variables: {
          uuid,
          revisionData,
        },
      })
      commit('DELETE_ITEM', uuid)
      return uuid

    } catch {
      throw {
        message: "ไม่สามารถลบสไตล์บ้านนี้ได้เนื่องจากมี แบบบ้านใช้งานอยู่"
      }
    }
  },
  async fetchOne({ commit }, uuid) {
    const parsed = await getItemByUuid(uuid)
    parsed.house_serie_files = buildFileResponseBody(parsed.house_serie_files)

    commit('SET_ITEM', parsed)

    return parsed
  },

  async fetchAllLang() {

    const result = await fetchAllList()

    return result
  },
}
