import gql from 'graphql-tag'

export const GET_ZONE_LIST = gql`
  query Languages {
    zones: property_zone (
      order_by: {updated_at: desc}
    ) {
      id
      slug
      province_id
      created_by
      updated_by
      updated_at
      type
      zone_translates {
        id
        lang
        zone_id
        value
        attribute_code
      }
    }
  }
`

export const FETCH = gql`
  query($type: String) {
    result: property_zone(
      where: { type: { _eq: $type } }
      order_by: {updated_at: desc}
    ) {
      id
      slug
      type
      created_by
      updated_by
      updated_at
      zone_translates {
        id
        lang
        value
      }
      province {
        province_translates {
          lang
          value
        }
      }
      updated_at
    }
  }
`

export const FIND_ONE_BY_ID = gql`
  query($id: Int!) {
    result: property_zone_by_pk(id: $id) {
      id
      slug
      province_id
      type
      updated_at
      created_by
      updated_by
      zone_translates {
        id
        lang
        value
      }
    }
  }
`

export const CREATE = gql`
  mutation(
    $zone: property_zone_insert_input!
  ) {
    result: insert_property_zone_one(
      object: $zone
    ) {
      id
    }
  }
`

export const UPDATE = gql`
  mutation(
    $id: Int!
    $zone: property_zone_set_input!
    $zoneTranslate: [property_zone_translate_insert_input!]!
  ) {
    result: update_property_zone_by_pk(
      pk_columns: { id: $id }
      _set: $zone
    ) {
      id
    }

    delete_property_zone_translate(where: { zone_id: { _eq: $id } }) {
      affected_rows
    }
    
    insert_property_zone_translate(objects: $zoneTranslate) {
      returning {
        id
      }
    }
  }
`

export const DELETE = gql`
  mutation($id: Int!) {
    result: delete_property_zone_by_pk(id: $id) {
      id
    }
  }
`

export const GET_PROJECT_ZONE_ID = gql`
  query($zoneId: Int!) {
    result: property_project_zone(where: {
      zone_id: { _eq: $zoneId },
      project: { status: {_neq: "archived"} }
    }) {
      id
    }
  }
`

export const GET_LIST_BY_SLUG = gql`
  query GET_ZONE($id: Int, $slug: String) {
    result: property_zone(where: {
      id: { _neq: $id }
      slug: { _eq: $slug }
    }) {
      id
    }
  }
`