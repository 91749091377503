import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import forEach from 'lodash/forEach'
import { createProvider } from '@/vue-apollo'
import { buildList } from '@/helper/Utils'
import {
  CREATE,
  UPDATE,
  DELETE,
  FETCH,
  FIND_ONE_BY_ID,
  GET_ZONE_LIST,
  GET_PROJECT_ZONE_ID,
  GET_LIST_BY_SLUG
} from './constantGql'

const checkNameExist = (existDataList, name, lang, id = null) => {
  const existData = existDataList.find((type) => {
    return type.zone_translates.find((translate) => {
      return translate.lang === lang && translate.value === name && parseInt(id) !== translate.id
    }) 
  })
  
  if (existData) throw "มีชื่อนี้อยู่ในระบบแล้ว"
}

const getProjectByZoneId = async (zoneId) => {
  const apollo = createProvider().clients.defaultClient

  const {
    data: { result },
  } = await apollo.query({
    query: GET_PROJECT_ZONE_ID,
    variables: { zoneId },
  })

  return result
}


export default {
  async checkSlugExist(_, data) {
    const apollo = createProvider().clients.defaultClient
    const response = await apollo.query({
      query: GET_LIST_BY_SLUG,
      variables: {
        id: data.id,
        slug: data.slug
      },
    })
  
    const result = get(response, ['data', 'result'], [])
    const isExist = !isEmpty(result)

    return isExist
  },
  async fetchZoneListItems({ commit, rootState }) {
    const lang = rootState.locale.currentLang
    const apollo = createProvider().clients.defaultClient
    const response = await apollo.query({ query: GET_ZONE_LIST })
    const rawZoneList = get(response, ['data', 'zones'], [])
    const parsedZoneData = buildList(rawZoneList, 'zone_translates', lang)
    commit('SET_ZONES', parsedZoneData)
  },

  async fetch({ commit }) {
    const apollo = createProvider().clients.defaultClient
    const response = await apollo.query({ query: FETCH })
    const zones = get(response, ['data', 'result'], [])
    commit('SET_ITEMS', zones)
    return zones
  },

  async fetchOne(context, id) {
    const apollo = createProvider().clients.defaultClient
    const response = await apollo.query({
      query: FIND_ONE_BY_ID,
      variables: { id },
    })
    return get(response, ['data', 'result'])
  },

  async addItem({ dispatch }, data) {
    const { provinceId, title, type, created_by, updated_by, slug } = data

    const existZones = await dispatch('fetch')

    const zone = {
      province_id: provinceId,
      type,
      slug,
      created_by,
      updated_by,
      zone_translates: {
        data: []
      },
    }
    forEach(title, ({ value }, key) => {
      if (!isEmpty(value)) {
        try {
          checkNameExist(existZones, value, key)
        } catch (e) {
          throw e
        }
        zone.zone_translates.data.push({
          attribute_code: 'zone_title',
          lang: key,
          value,
        })
      }
    })

    const apollo = createProvider().clients.defaultClient
    const {
      data: { result },
    } = await apollo.mutate({ mutation: CREATE, variables: { zone } })

    return result.id
  },

  async update(context, data) {
    const { id, title, provinceId, type, created_by, updated_by, slug } = data
    const langs = ['th', 'en', 'cn']
    const zoneTranslate = []
    const existZones = await context.dispatch('fetch')

    langs.forEach((lang) => {
      const titleLang = get(title, [lang, 'value'], null)
      const titleId = get(title, [lang, 'id'], null)

      try {
        checkNameExist(existZones, titleLang, lang, titleId)
      } catch (e) {
        throw e
      }

      if (titleLang) zoneTranslate.push({
        lang,
        zone_id: id,
        value: titleLang,
        attribute_code: "zone_title"
      })
    })

    const variables = {
      id,
      zone: {
        slug,
        type,
        created_by,
        updated_by,
        province_id: provinceId,
      },
      zoneTranslate
    }

    const apollo = createProvider().clients.defaultClient
    const {
      data: { result },
    } = await apollo.mutate({ mutation: UPDATE, variables })
    return result.id
  },

  async delete({ dispatch }, id) {
    const projects = await getProjectByZoneId(id)
    let canDelete = true

    if (projects.length > 0) {
      canDelete = false
    }

    if (canDelete) {
      const apollo = createProvider().clients.defaultClient
      const response = await apollo.mutate({
        mutation: DELETE,
        variables: { id },
      })

      await dispatch('fetch')
      return response
    } else {
      throw { message: "ไม่สามารถลบ zone ได้ มีโครงการใช้งานอยู่" }
    }
  },
}
