<template>
  <input
    v-model="$attrs.value"
    v-imask="mask"
    @accept="onAccept"
    class="vs-inputx vs-input--input normal hasValue"
  />
</template>

<script>
import IMask from 'imask'
import { IMaskDirective } from 'vue-imask'

export default {
  name: "LhInputTime",
  data() {
    return {
      mask: {
        mask: 'HH.mm',
        blocks: {
          HH: {
            mask: IMask.MaskedRange,
            from: 0,
            to: 23
          },
          mm: {
            mask: IMask.MaskedRange,
            from: 0,
            to: 59
          },
        }
      },

    }
  },
  methods: {
    update(value) {
      this.$emit('input', value)
    },
    onAccept (e) {
      const maskRef = e.detail
      this.value = maskRef.value
      this.$emit('input', maskRef.value)
    },
  },
  directives: {
    imask: IMaskDirective
  }
}
</script>

<style scoped>

</style>
