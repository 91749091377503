import get from 'lodash/get'
import { createProvider } from '@/vue-apollo'
import { FETCH } from './constantGql'

export default {
  async fetch(context) {
    const apollo = createProvider().clients.defaultClient
    const response = await apollo.query({
      query: FETCH,
    })

    context.commit('SET_ITEMS', get(response, ['data', 'result']))
  }
}
