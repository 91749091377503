<template>
  <div
    :class="!customClass ? 'vx-col w-full md:w-6/12' : customClass"
  >
    <slot v-bind="$attrs"/>
  </div>
</template>

<script>
export default {
  name: 'FormContent',
  props: {
    customClass: {
      type: String,
      default: ''
    }
  },
}
</script>

<style scoped></style>
