import get from 'lodash/get'
import omit from 'lodash/omit'
import { createProvider } from '@/vue-apollo'
import { FETCH, CREATE, UPDATE, DELETE, FIND_ONE_BY_ID } from './constantGql'

export default {
  async fetch({ commit }) {
    const apollo = createProvider().clients.defaultClient
    const response = await apollo.query({
      query: FETCH,
    })

    commit('SET_ITEMS', get(response, ['data', 'result'], []))

    return get(response, ['data', 'result'], [])
  },

  async fetchOne(context, id) {
    const apollo = createProvider().clients.defaultClient
    const response = await apollo.query({
      query: FIND_ONE_BY_ID,
      variables: { id },
    })
    return get(response, ['data', 'result'])
  },

  async addItem(context, data) {
    const apollo = createProvider().clients.defaultClient
    const {
      data: { result },
    } = await apollo.mutate({
      mutation: CREATE,
      variables: {
        toCreateItem: {
          ...omit(data, ['id', 'created_at']),
        },
      },
    })

    return result
  },

  async updateItem(context, { formData }) {
    const apollo = createProvider().clients.defaultClient

    const {
      data: { updateResult },
    } = await apollo.mutate({
      mutation: UPDATE,
      variables: {
        id: formData.id,
        toUpdateItem: formData,
      },
    })

    return updateResult.id
  },

  async delete(context, id) {
    const apollo = createProvider().clients.defaultClient

    const result = await apollo.mutate({
      mutation: DELETE,
      variables: {
        id,
      },
    })

    return result
  },
}
