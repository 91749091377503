import gql from 'graphql-tag'

export const FETCH_ACCOUNT_BY_PROVIDER = gql`
  query ($authProvider:String!, $authProviderUniqueId: String!){
    result: auth_account_provider (
      where: {
        auth_provider: {_eq: $authProvider}
        auth_provider_unique_id: {_eq: $authProviderUniqueId}
      }
    ) {
      account {
        default_role
        user {
          display_name
          setting
          group {
            group_name
          }
          user_group_links {
            user_group {
              group_name
            }
          }
        }
      }
    }
  }
`
