export default {
  SET_USERS(state, value) {
    state.users = value
  },
  SET_USER_GROUPS(state, value) {
    state.groups = value
  },
  SET_ACCOUNT_ROLES(state, value) {
    state.roles = value
  },
  SET_LOADING(state, loading) {
    state.loading = loading
  },
}
