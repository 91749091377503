import gql from 'graphql-tag'

export const CREATE = gql`
  mutation($toCreateItem: cms_promotion_insert_input!) {
    result: insert_cms_promotion_one(object: $toCreateItem) {
      id
      revision_id
    }
  }
`

export const UPDATE = gql`
  mutation(
    $id: Int!,
    $toDeleteItem: cms_promotion_revision_insert_input!,
    $toUpdateItem: cms_promotion_set_input!
  ){
    result: update_cms_promotion_by_pk(pk_columns: { id: $id }, _set: $toUpdateItem) {
      id
    }
    revisionResult: insert_cms_promotion_revision_one(object: $toDeleteItem) {
      id
      revision_id
    }
  }
`

export const DELETE = gql`
  mutation($id: Int!, $toDeleteItem: cms_promotion_revision_insert_input!) {
    deleteResult: delete_cms_promotion_by_pk(id: $id) {
      id
    }

    revisionResult: insert_cms_promotion_revision_one(object: $toDeleteItem) {
      id
      revision_id
    }
  }
`

export const FETCH = gql`
  query($lang: String!) {
    result: cms_promotion(
      where: { status: { _neq: "archived" }, lang: { _eq: $lang } }
      order_by: { updated_at: desc }
    ) {
      id
      lang
      project_id
      title
      start_date
      end_date
      is_approved
      revision_id
      updated_at
      created_by
      updated_by
    }
  }
`

export const FIND_ONE_BY_ID = gql`
  query($id: Int!) {
    result: cms_promotion_by_pk(id: $id) {
      id
      project_id
      lang
      title
      description
      folder_name
      start_date
      end_date
      gallery
      thumbnail
      external_link
      is_approved
      revision_id
      updated_at
      created_by
      updated_by
    }
  }
`

export const GET_EXIST_PROJECT_ID = gql`
  query($projectId: Int!) {
    result: cms_promotion(
      where: { status: { _neq: "archived" }, project_id: { _eq: $projectId } }
      order_by: { updated_at: desc }
    ) {
      lang
    }
  }
`
