import gql from 'graphql-tag'

export const CREATE = gql`
  mutation(
    $title: String!
    $description: String!
    $content: String
    $media_type: String!
    $media_content: jsonb!
    $link_url: String
    $start_date: date!
    $end_date: date!
    $lang: String!
    $folder_name: String!
    $created_by: String!
    $updated_by: String!
  ) {
    result: insert_cms_highlight_one(
      object: {
        title: $title
        description: $description
        content: $content
        media_type: $media_type
        media_content: $media_content
        link_url: $link_url
        start_date: $start_date
        end_date: $end_date
        lang: $lang
        folder_name: $folder_name
        created_by: $created_by
        updated_by: $updated_by
      }
    ) {
      id
      revision_id
    }
  }
`

export const UPDATE = gql`
  mutation(
    $id: Int!
    $title: String!
    $description: String!
    $content: String
    $media_type: String!
    $media_content: jsonb!
    $link_url: String
    $start_date: date!
    $end_date: date!
    $lang: String!
    $folder_name: String!
    $created_by: String!
    $updated_by: String!
  ) {
    result: insert_cms_highlight_one(
      object: {
        id: $id
        title: $title
        description: $description
        content: $content
        media_type: $media_type
        media_content: $media_content
        link_url: $link_url
        start_date: $start_date
        end_date: $end_date
        lang: $lang
        folder_name: $folder_name
        created_by: $created_by
        updated_by: $updated_by
      }
    ) {
      id
      revision_id
    }
  }
`

export const DELETE = gql`
  mutation($revision_id: uuid!) {
    result: update_cms_highlight_by_pk(
      pk_columns: { revision_id: $revision_id }
      _set: { status: "archived" }
    ) {
      id
      revision_id
    }
  }
`

export const FETCH = gql`
  query($lang: String!) {
    result: cms_highlight(
      where: { status: { _neq: "archived" }, lang: { _eq: $lang } }
      order_by: { updated_at: desc }
    ) {
      id
      revision_id
      title
      description
      content
      media_type
      media_content
      link_url
      start_date
      end_date
      lang
      folder_name
      updated_by
      updated_at
      created_by
    }
  }
`

export const FIND_ONE_BY_ID = gql`
  query($revision_id: uuid!) {
    result: cms_highlight_by_pk(revision_id: $revision_id) {
      id
      revision_id
      title
      description
      content
      media_type
      media_content
      link_url
      start_date
      end_date
      lang
      folder_name
      updated_at
      updated_by
      created_by
    }
  }
`
