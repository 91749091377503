/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from 'vue'
import env from '@/env'
import App from './App.vue'
import * as Sentry from '@sentry/browser'
import { Vue as VueIntegration } from '@sentry/integrations'
import isNil from 'lodash/isNil'
// import { Notifier } from '@airbrake/browser'

// const airbrake = new Notifier({
//   environment: env.VUE_APP_AIRBRAKE_ENV,
//   projectId: env.VUE_APP_AIRBRAKE_PROJECT_ID,
//   projectKey: env.VUE_APP_AIRBRAKE_PROJECT_KEY
// })

// Vue.config.errorHandler = function (err, vm, info) {
//   airbrake.notify({
//     error: err,
//     params: {info}
//   })
// }

Sentry.init({
  dsn: env.VUE_APP_SENTRY_DSN,
  integrations: [new VueIntegration({ Vue, attachProps: true })],
  config: {
    environment: env.VUE_APP_SENTRY_ENVIRONMENT,
  }, // Additional config
})

// Vuesax Component Framework
import Vuesax from 'vuesax'
import 'material-icons/iconfont/material-icons.css' //Material Icons
import 'vuesax/dist/vuesax.css' // Vuesax
Vue.use(Vuesax)

import SweetModal from 'sweet-modal-vue/src/plugin.js'
Vue.use(SweetModal)

import VueCompositionApi from '@vue/composition-api'
Vue.use(VueCompositionApi)

// axios
import axios from './axios.js'
Vue.prototype.$http = axios

// API Calls
import './http/requests'

// mock
import './fake-db/index.js'

// Theme Configurations
import '../themeConfig.js'

// Firebase
import '@/firebase/firebaseConfig'

// ACL
import acl from './acl/acl'

// Globally Registered Components
import './globalComponents.js'

// Styles: SCSS
import './assets/scss/main.scss'

// Tailwind
import '@/assets/css/main.css'

// Vue Router
import router from './router'

// Vuex Store
import store from './store/store'

// i18n
import i18n from './i18n/i18n'

// Vuexy Admin Filters
import './filters/filters'

//Import Froala Editor 
import 'froala-editor/js/plugins.pkgd.min.js'
//Import third party plugins
import 'froala-editor/js/third_party/embedly.min'
import 'froala-editor/js/third_party/font_awesome.min'
import 'froala-editor/js/third_party/spell_checker.min'
import 'froala-editor/js/third_party/image_tui.min'

// Import and use Vue Froala lib.
import VueFroala from 'vue-froala-wysiwyg'
Vue.use(VueFroala)

// Clipboard
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

// VeeValidate
import { extend, ValidationObserver, ValidationProvider, localize } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import th from 'vee-validate/dist/locale/th.json'

localize('th', th)
localize('th')

Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule])
})

extend('lh_required_cld', {
  validate(value) {
    if (value.length > 0) {
      return true
    }

    return 'กรุณาเลือกไฟล์'
  },
})

extend('lh_mimes', {
  validate(value, args) {
    let valid = true

    value.forEach((image) => {
      if (value.length > 0 && !args.includes(image.format) && valid) {
        valid = false
      }
    })

    if (valid) return true

    return `{_field_} ประเภทไฟล์: ${args.join(' ,')}`
  },
})

extend('lh_image_resolution', {
  validate(value, args) {
    // if (
    //   value.length > 0 &&
    //   value[0].height === parseInt(args.height) &&
    //   value[0].width === parseInt(args.width)
    // ) {
    //   return true
    // }
    let valid = true

    if (value.length > 0) {
      value.forEach((image) => {
        // bypass when no attribute height and widht
        if (isNil(image.height) || isNil(image.width)) return

        if ((image.height !== parseInt(args.height) ||
        image.width !== parseInt(args.width)) && valid) {
          valid = false
        }
      })
    }

    if (valid) return true

    return `{_field_} ความกว้างต้องเท่ากับ ${args.width} px ความสูงต้องเท่ากับ ${args.height} px`
  },
  params: ['width', 'height'],
})

extend('lh_image_max_resolution', {
  validate(value, args) {
    if (value.length > 0) {
      if (isNil(value[0].height) || isNil(value[0].width)) return true

      if (value[0].height > parseInt(args.height) && value[0].width > parseInt(args.width)) {
        return `{_field_} ความสูงต้องน้อยกว่าหรือเท่ากับ ${args.height} px และความกว้างต้องน้อยกว่าหรือเท่ากับ ${args.height} px`
      } else if (value[0].height > parseInt(args.height)) {
        return `{_field_} ความสูงต้องน้อยกว่าหรือเท่ากับ ${args.height} px`
      } else if (value[0].width > parseInt(args.width)) {
        return `{_field_} ความกว้างต้องน้อยกว่าหรือเท่ากับ ${args.width} px`
      }
    }
    return true
  },
  params: ['width', 'height'],
})

extend('lh_image_min_width', {
  validate(value, args) {
    if (isNil(value[0].width) || (value.length > 0 && value[0].width >= parseInt(args.width))) {
      return true
    }

    return `{_field_} ความกว้างต้องมากกว่าหรือเท่ากับ ${args.width} px`
  },
  params: ['width'],
})

extend('lh_image_min_height', {
  validate(value, args) {
    if (isNil(value[0].height) || (value.length > 0 && value[0].height >= parseInt(args.height))) {
      return true
    }

    return `{_field_} ความสูงต้องมากกว่าหรือเท่ากับ ${args.height} px`
  },
  params: ['height'],
})

extend('lh_image_max_width', {
  validate(value, args) {
    if (isNil(value[0].width) || (value.length > 0 && value[0].width <= parseInt(args.width))) {
      return true
    }

    return `{_field_} ความกว้างต้องน้อยกว่าหรือเท่ากับ ${args.width} px`
  },
  params: ['width'],
})

extend('lh_image_max_height', {
  validate(value, args) {
    if (isNil(value[0].height) || (value.length > 0 && value[0].height <= parseInt(args.height))) {
      return true
    }

    return `{_field_} ความสูงต้องน้อยกว่าหรือเท่ากับ ${args.height} px`
  },
  params: ['height'],
})

extend('lh_image_max', {
  validate(value, args) {
    if (value.length <= parseInt(args[0])) {
      return true
    }

    return `{_field_} ต้องน้อยกว่า ${args[0]} รูป`
  },
})

import isURL from 'validator/lib/isURL'
extend('url', {
  validate(value) {
    if (isURL(value)) {
      return true
    }

    return '{_field_} ไม่ถูกต้อง'
  },
})

extend('price', {
  validate(value) {
    const priceRegex = RegExp('^\\$?([0-9]{1,3},([0-9]{3},)*[0-9]{3}|[0-9]+)(\\.[0-9][0-9])?$')
    if (priceRegex.test(value)) {
      return true
    }

    return '{_field_} ไม่ถูกต้อง'
  },
})

extend('path_url', {
  validate(value, args) {
    const parseArgs = {
      ...args,
      require_protocol: args.require_protocol === 'true',
      require_host: args.require_host === 'true',
      require_tld: args.require_tld === 'true',
    }
    if (isURL(value, parseArgs)) {
      return true
    }

    return '{_field_} ไม่ถูกต้อง'
  },
  params: ['require_protocol', 'require_host', 'require_tld'],
})

extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target
  },
  message: 'รหัสผ่านไม่ตรงกันกรุณากรอกใหม่อีกครั้ง'
})

extend('special_charecter', {
  validate(value) {
    const format = /[!@#$%^&*)(+=.?]/g
    if (format.test(value)) {
      return 'ไม่สามารถใช้อักขระพิเศษได้'
    }
    return true
  },
})


extend('lh_email', {
  validate(value) {
    if (value.indexOf('@') > -1) {
      return `กรุณากรอกอีเมลโดยไม่ต้องใส่ @`
    }
    return true
  },
})


import FormLayout from './components/FormLayout.vue'
extend('lh_size', {
  validate(value, args) {
    // let valid = true
    const variableSize = args[0].split('_')
    const unit = variableSize[1]
    let size = parseInt(variableSize[0]) + 1
    switch (unit) {
      case 'KB':
        size *= 1024
        break
      case 'MB':
        size *= 1048576
        break
      default:
        size *= 1
    }

    if (value.length > 0) {
      const fileSize = value[0].bytes
      if (fileSize > (size - 1)) return `ไฟล์มีขนาดเกิน ${variableSize[0]} ${unit}`
    }

    return true
  },
})

extend('lh_ratio', {
  validate(value, args) {
    let valid = true

    if (value.length > 0) {
      value.forEach((image) => {
        // bypass when no attribute height and width
        if (isNil(image.height) || isNil(image.width)) return
        
        if ((image.height / parseFloat(args.height) !==
        image.width / parseFloat(args.width)) && valid) {
          valid = false
        }
      })
    }

    if (valid) return true

    return `รูปภาพต้องมีอัตราส่วน (ratio) ${args.width}:${args.height}`
  },
  params: ['width', 'height'],
})

import FormLabel from './components/FormLabel.vue'
import FormContent from './components/FormContent.vue'
import LhInputNumber from './components/LhInputNumber.vue'
import LhInputTime from './components/LhInputTime.vue'

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('FormLayout', FormLayout)
Vue.component('FormLabel', FormLabel)
Vue.component('FormContent', FormContent)
Vue.component('LhInputNumber', LhInputNumber)
Vue.component('LhInputTime', LhInputTime)

// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from 'vue2-hammer'
Vue.use(VueHammer)

// PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import { createProvider } from './vue-apollo'

// Feather font icon
require('./assets/css/iconfont.css')

// Vue select css
// Note: In latest version you have to add it separately
// import 'vue-select/dist/vue-select.css';

Vue.config.productionTip = false

Vue.prototype.$eventBus = new Vue()

new Vue({
  router,
  store,
  i18n,
  acl,
  apolloProvider: createProvider(),
  render: (h) => h(App),
}).$mount('#app')
