export default {
  SET_PROVINCE(state, items) {
    state.geo.provinces = items
  },
  SET_DISTRICT(state, items) {
    state.geo.districts = items
  },
  SET_SUB_DISTRICT(state, items) {
    state.geo.subDistricts = items
  },
}
