import get from 'lodash/get'
import omit from 'lodash/omit'
import { createProvider } from '@/vue-apollo'
import { FETCH, CREATE, UPDATE, DELETE, FIND_ONE_BY_ID, FETCH_ALL } from './constantGql'

export default {
  async fetch(context) {
    const apollo = createProvider().clients.defaultClient
    const response = await apollo.query({
      query: FETCH,
      variables: {
        lang: context.rootState.locale.currentLang,
      },
    })

    context.commit('SET_ITEMS', get(response, ['data', 'result']))
  },

  async fetchAll() {
    const apollo = createProvider().clients.defaultClient
    const {
      data: { result },
    } = await apollo.query({
      query: FETCH_ALL
    })

    // parse data
    return result
  },

  async fetchOne(context, id) {
    const apollo = createProvider().clients.defaultClient
    const response = await apollo.query({
      query: FIND_ONE_BY_ID,
      variables: { id },
    })
    return get(response, ['data', 'result'])
  },

  async addItem(context, data) {
    const apollo = createProvider().clients.defaultClient
    const {
      data: { result },
    } = await apollo.mutate({
      mutation: CREATE,
      variables: {
        categoryId: data.sub_category_id,
        toCreateItem: {
          ...omit(data, ['id', 'created_at', 'sub_category_id', 'updated_at']),
          lang: context.rootState.locale.currentLang,
        },
      },
    })

    return result.content
  },

  async updateItem(context, { initialData, formData }) {
    const apollo = createProvider().clients.defaultClient

    const {
      data: { updateResult },
    } = await apollo.mutate({
      mutation: UPDATE,
      variables: {
        id: formData.id,
        categoryId: formData.sub_category_id,
        toUpdateItem: omit(formData, ['sub_category_id', 'updated_at']),
        toDeleteItem: omit(initialData, ['sub_category_id', 'status']),
        customSlug: formData.custom_slug
      },
    })

    return updateResult.id
  },

  async delete(context, id) {
    const apollo = createProvider().clients.defaultClient

    const {
      data: { result: toDeleteItem },
    } = await apollo.mutate({
      mutation: FIND_ONE_BY_ID,
      variables: { id },
    })

    const result = await apollo.mutate({
      mutation: DELETE,
      variables: {
        id,
        toDeleteItem: {
          ...omit(toDeleteItem, ['__typename', 'updated_at', 'content_categories']),
          status: 'archived',
        },
      },
    })

    return result
  },
}
