import gql from 'graphql-tag'

export const CREATE = gql`
  mutation($toCreateItem: cms_seo_insert_input!) {
    result: insert_cms_seo_one(object: $toCreateItem) {
      id
    }
  }
`
export const UPDATE = gql`
  mutation(
    $id: Int!
    $toUpdateItem: cms_seo_set_input!
    $toDeleteItem: cms_seo_revision_insert_input!
  ) {
    updateResult: update_cms_seo_by_pk(pk_columns: { id: $id }, _set: $toUpdateItem) {
      id
    }

    revisionResult: insert_cms_seo_revision_one(object: $toDeleteItem) {
      id
      revision_id
    }
  }
`

export const DELETE = gql`
  mutation($id: Int!, $toDeleteItem: cms_seo_revision_insert_input!) {
    deleteResult: delete_cms_seo_by_pk(id: $id) {
      id
    }

    revisionResult: insert_cms_seo_revision_one(object: $toDeleteItem) {
      id
      revision_id
    }
  }
`

export const FETCH = gql`
  query {
    result: cms_seo(
      where: { status: { _neq: "archived" } }
      order_by: { updated_at: desc }
    ) {
      id
      seo_name
      url
      updated_at
      updated_by
      created_by
    }
  }
`

export const FIND_ONE_BY_ID = gql`
  query($id: Int!) {
    result: cms_seo_by_pk(id: $id) {
      id
      revision_id
      url
      seo_name
      meta_title
      meta_h1
      meta_description
      meta_keyword
      meta_thumbnail
      geo_title
      geo_detail
      folder_name
      updated_at
      updated_by
      created_by
    }
  }
`
