import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { createProvider } from '@/vue-apollo'
import {
  FETCH_USER,
  FETCH_USER_BY_ID,
  FETCH_USER_GROUP,
  FETCH_USER_GROUP_BY_ID,
  FETCH_ACCOUNT_ROLE,
  CREATE_USER,
  CREATE_USER_GROUP,
  UPDATE_USER_BY_ID,
  UPDATE_USER_GROUP_BY_ID,
  // DELETE_USER_BY_ID,
  DELETE_USER_GROUP_BY_ID,
  UPDATE_ONLT_USER_INFO_BY_ID
} from './constantGql'
import bcrypt from 'bcryptjs'

// import axios from 'axios'

// // Set custom user claims on this newly created user.
// return admin.auth().setCustomUserClaims(user.uid, customClaims)

export default {
  // ------- User Group --------
  async fetchUserGroup(context) {
    const apollo = createProvider().clients.defaultClient
    const response = await apollo.query({
      query: FETCH_USER_GROUP,
    })
    context.commit('SET_USER_GROUPS', get(response, ['data', 'result']))
    return get(response, ['data', 'result'])
  },
  async fetchUserGroupById(context, id) {
    const apollo = createProvider().clients.defaultClient
    const response = await apollo.query({
      query: FETCH_USER_GROUP_BY_ID,
      variables: { id }
    })
    return get(response, ['data', 'result'])
  },
  async createUserGroup(context, formData) {
    const apollo = createProvider().clients.defaultClient
    const response = await apollo.mutate({
      mutation: CREATE_USER_GROUP,
      variables: {
        group_name: formData.group_name
      }
    })
    return get(response, ['data', 'result'])
  },
  async updateUserGroup(context, formData) {
    const apollo = createProvider().clients.defaultClient
    const response = await apollo.mutate({
      mutation: UPDATE_USER_GROUP_BY_ID,
      variables: formData
    })
    return get(response, ['data', 'result'])
  },
  async deleteUserGroup(context, id) {
    const apollo = createProvider().clients.defaultClient
    const response = await apollo.mutate({
      mutation: DELETE_USER_GROUP_BY_ID,
      variables: { id }
    })
    return get(response, ['data', 'result'])
  },
  // ------- User Role --------
  async fetchAccountRole(context) {
    const apollo = createProvider().clients.defaultClient
    const response = await apollo.query({
      query: FETCH_ACCOUNT_ROLE,
    })
    context.commit('SET_ACCOUNT_ROLES', get(response, ['data', 'result']))
    return get(response, ['data', 'result'])
  },
  // ------- User --------
  async fetchUser(context) {
    const apollo = createProvider().clients.defaultClient
    const response = await apollo.query({
      query: FETCH_USER,
    })
    context.commit('SET_USERS', get(response, ['data', 'result']))
    return get(response, ['data', 'result'])
  },
  async fetchUserById(context, id) {
    const apollo = createProvider().clients.defaultClient
    const response = await apollo.query({
      query: FETCH_USER_BY_ID,
      variables: { id }
    })
    return get(response, ['data', 'result'])
  },
  // async deleteUser(context, id) {
  //   const apollo = createProvider().clients.defaultClient
  //   const response = await apollo.mutate({
  //     mutation: DELETE_USER_BY_ID,
  //     variables: { id }
  //   })
  //   return get(response, ['data', 'result'])
  // },
  async registerUser(_, { formData }) {
    const apollo = createProvider().clients.defaultClient

    const dummyPassword = formData.password
    const salt = bcrypt.genSaltSync(10)
    const password_hash = bcrypt.hashSync(dummyPassword, salt)

    try {
      const {
        data: { register_user: result },
      } = await apollo.mutate({
        mutation: CREATE_USER,
        variables: {
          display_name: formData.display_name,
          group_id: formData.group_id,
          email: formData.email,
          password: formData.password,
          password_hash,
          role: formData.role,
          setting: formData.setting
        },
      })
  
      return result
    }
    catch (e) {
      const errors = e.graphQLErrors
      const code = get(errors, [0, 'extensions', 'internal', 'response', 'body', 'code'], null)

      if (code === 'auth/email-already-exists') throw { message: 'อีเมลนี้ถูกใช้งานไปแล้ว กรุณากรอกอีเมลใหม่' }
      if (!isEmpty(errors)) throw errors[0]
    }

    // await firebase.database().ref(`metadata/${result.uid}`).set({
    //   account_id: result.id,
    //   role: formData.role,
    // })
  },
  async updateUser(_, { formData }) {
    const apollo = createProvider().clients.defaultClient
    const grouId = formData.group_id.map((id) => ({
      user_id: formData.id,
      group_id: id
    }))
    
    const variables = {
      id: formData.id,
      name: formData.display_name,
      group_id: grouId,
      role: formData.role,
      is_active: formData.is_active,
      setting: formData.setting
    }
    if (formData.password) {
      const dummyPassword = formData.password
      const salt = bcrypt.genSaltSync(10)
      const password_hash = bcrypt.hashSync(dummyPassword, salt)

      variables.password = get(formData, 'password', '')
      variables.uid = formData.uid
      variables.passwordHash = password_hash

      const response = await apollo.mutate({
        mutation: UPDATE_USER_BY_ID,
        variables
      })
      return get(response, ['data', 'result']) 
    } else {
      const response = await apollo.mutate({
        mutation: UPDATE_ONLT_USER_INFO_BY_ID,
        variables
      })
      return get(response, ['data', 'result']) 
    }
  },
}
