import Vue from 'vue'
import { AclInstaller, AclCreate, AclRule } from 'vue-acl'
import router from '@/router'

Vue.use(AclInstaller)

let initialRole = 'editor'

const userInfo = JSON.parse(localStorage.getItem('userInfo'))

if (userInfo && userInfo.userRole) initialRole = userInfo.userRole

export default new AclCreate({
  initial: initialRole,
  notfound: '/pages/not-authorized',
  router,
  acceptLocalRules: true,
  globalRules: {
    admin: new AclRule('admin').generate(), 
    super_editor: new AclRule('super_editor').or('admin').generate(),
    editor: new AclRule('editor').or('super_editor').or('admin').generate(),
    sale: new AclRule('marketing').or('sale').or('editor').or('super_editor').or('admin').generate(),
    sale_not_editor: new AclRule('marketing').or('sale').or('super_editor').or('admin').generate(),
    marketing: new AclRule('marketing').or('sale').or('editor').or('super_editor').or('admin').generate(),  // Same as marketing for now.
    guest: new AclRule('guest').or('marketing').or('sale').or('editor').or('super_editor').or('admin').generate(),
  }
})
