/*=========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from 'vue'
import Vuex from 'vuex'

import state from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

Vue.use(Vuex)

import dam from './dam'
import magazine from './cms/magazine'
import promotion from './promotion'
import tvc from './cms/tvc'
import zone from './property-asset/zone'
import brand from './property-asset/brand'
import propertyType from './property-asset/propertyType'
import province from './property-asset/province'
import houseSerie from './property-asset/houseSerie'
import houseFunction from './property-asset/houseFunction'
import houseFunctionFeature from './property-asset/houseFunctionFeature'
import condoCommunityFeature from './property-asset/communityFeature'
import condoRoomFeature from './property-asset/roomFeature'
import houseModel from './property-asset/houseModel'
import houseIconActivity from './property-asset/houseIconActivity'
import project from './property-asset/project/moduleProjectList'
import contentCategory from './content-category'
import projectReview from './project-review'
import community from './community'
import livingTip from './living-tip'
import seo from './seo'
import landingPage from './landing-page'
import banner from './banner'
import redirection from './redirection'
import bannerHighlight from './banner-highlight'
import auth from './auth/index.js'
import eCommerce from './eCommerce/moduleECommerce.js'
import cld from './cld'
import locale from './locale'
import category from './property-asset/category/moduleCategoryList.js'
import user from './property-asset/user/moduleUserList.js'
import page from './property-asset/page/modulePageList.js'
import geo from './property-asset/geo/moduleGeoList.js'
import facility from './property-asset/facility/moduleFacilityList.js'
import projectHouseSell from './property-asset/projectHouseSell'
import houseSellGallery from './property-asset/houseSellGallery'
import footer from './cms/footer'
import users from './user'
import contactUs from './contact-us'
import report from './report'
import projectVdo from './cms/projectVdo'
import csr from './cms/csr'
import blacklistEmail from './blacklist-email'

export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    dam,
    auth,
    brand,
    magazine,
    promotion,
    tvc,
    zone,
    propertyType,
    province,
    project,
    contentCategory,
    community,
    projectReview,
    livingTip,
    landingPage,
    houseFunction,
    houseFunctionFeature,
    banner,
    bannerHighlight,
    eCommerce,
    cld,
    locale,
    houseSerie,
    condoCommunityFeature,
    condoRoomFeature,
    houseModel,
    houseIconActivity,
    category,
    page,
    geo,
    facility,
    user,
    projectHouseSell,
    houseSellGallery,
    seo,
    redirection,
    footer,
    users, // new user table
    contactUs,
    report,
    projectVdo,
    csr,
    blacklistEmail
  },
  strict: process.env.NODE_ENV !== 'production',
})
