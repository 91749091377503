import gql from 'graphql-tag'

export const FETCH = gql`
  query {
    result: cms_contact_us(
      order_by: { created_at: desc }
    ) {
      uuid
      name
      email
      telephone
      message
      created_at
      updated_at
    }
  }
`

