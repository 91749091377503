import gql from 'graphql-tag'

export const GET_HOUSE_FUNCTION_FEATURE_LIST = gql`
  query HouseFunctionFeature (
    $lang: String!
  ){
    house_function_feature: property_house_function_feature(
      where: {
        lang: { _eq: $lang }
        status: { _neq: "archived" }
      }
      order_by: { created_at: asc }
    ) {
      id
      lang
      uuid
      updated_at
      created_at
      created_by
      updated_by
      house_function_feature_translates {
        lang
        value
        attribute_code
      }
    }
  }
`

export const GET_HOUSE_FUNCTION_FEATURE_ITEM = gql`
  query HouseFunctionFeature($uuid: uuid!) {
    house_function_feature: property_house_function_feature(
      where: { status: { _neq: "archived" }, uuid: { _eq: $uuid } }
    ) {
      id
      lang
      uuid
      updated_at
      created_at
      created_by
      updated_by
      house_function_feature_translates {
        lang
        value
        attribute_code
      }
    }
  }
`

export const ADD_HOUSE_FUNCTION_FEATURE = gql`
  mutation HouseFunctionFeature(
    $houseFunctionFeature: property_house_function_feature_insert_input!
  ) {
    insert_property_house_function_feature_one(object: $houseFunctionFeature) {
      id
      uuid
    }
  }
`

export const UPDATE_HOUSE_FUNCTION_FEATURE = gql`
  mutation HouseFunctionFeature(
    $houseFunctionFeature: property_house_function_feature_set_input!
    $houseFunctionFeatureTranslate: [property_house_function_feature_translate_insert_input!]!
    $uuid: uuid!
    $lang: String!
  ) {
    update_property_house_function_feature_by_pk(
      _set: $houseFunctionFeature
      pk_columns: { uuid: $uuid }
    ) {
      id
    }
    delete_property_house_function_feature_translate(
      where: { house_function_feature_uuid: { _eq: $uuid }, lang: { _eq: $lang } }
    ) {
      affected_rows
    }
    insert_property_house_function_feature_translate(
      objects: $houseFunctionFeatureTranslate
    ) {
      returning {
        uuid
      }
    }
  }
`

export const DELETE_HOUSE_FUNCTION_FEATURE = gql`
  mutation HouseFunctionFeature($uuid: uuid!, $revisionData: jsonb!) {
    insert_property_house_revision_one(
      object: {
        revision_uuid: $uuid
        revision_table: "house_function_feature"
        revision_data: $revisionData
      }
    ) {
      uuid
    }
    delete_property_house_function_feature_by_pk(uuid: $uuid) {
      uuid
    }
  }
`
