import gql from 'graphql-tag'

export const CREATE = gql`
  mutation($toCreateItem: cms_blacklist_email_insert_input!) {
    result: insert_cms_blacklist_email_one(object: $toCreateItem) {
      id
    }
  }
`
export const UPDATE = gql`
  mutation($id: uuid!, $toUpdateItem: cms_blacklist_email_set_input!) {
    updateResult: update_cms_blacklist_email_by_pk(
      pk_columns: { id: $id }
      _set: $toUpdateItem
    ) {
      id
    }
  }
`

export const DELETE = gql`
  mutation($id: uuid!) {
    deleteResult: delete_cms_blacklist_email_by_pk(id: $id) {
      id
    }
  }
`

export const FETCH = gql`
  query {
    result: cms_blacklist_email(
      order_by: { updated_at: desc }
    ) {
      id
      email
      updated_at
    }
  }
`

export const FIND_ONE_BY_ID = gql`
  query($id: uuid!) {
    result: cms_blacklist_email_by_pk(id: $id) {
      id
      email
      created_by
    }
  }
`
