import get from 'lodash/get'
import omit from 'lodash/omit'
import isEmpty from 'lodash/isEmpty'

import { createProvider } from '@/vue-apollo'
import {
  buildList,
  buildRequestBody,
  groupAttributeByLang,
  buildFileRequestBody,
  buildFileResponseBody,
  checkNameExist
} from '@/helper/Utils'
import {
  GET_HOUSE_ICON_LIST,
  GET_HOUSE_ICON_ITEM,
  ADD_HOUSE_ICON,
  UPDATE_HOUSE_ICON,
  DELETE_HOUSE_ICON,
} from './constantGql'

import HOUSE_ICON_ATTRIBUTE from './attributeJson.json'

const getItemByUuid = async (uuid) => {
  const apollo = createProvider().clients.defaultClient
  const response = await apollo.query({
    query: GET_HOUSE_ICON_ITEM,
    variables: {
      uuid,
    },
  })

  const rawData = get(response, ['data', 'house_icon_activity', 0], [])
  const parsedList = groupAttributeByLang(rawData)

  return parsedList
}

export default {
  async fetch({ commit, rootState }) {
    const apollo = createProvider().clients.defaultClient
    const response = await apollo.query({
      query: GET_HOUSE_ICON_LIST,
      variables: {
        lang: rootState.locale.currentLang,
      },
    })
    const rawList = get(response, ['data', 'house_icon_activity'], [])
    const parsedData = buildList(
      rawList,
      'house_icon_activity_translates',
      rootState.locale.currentLang,
    )

    commit('SET_ITEMS', parsedData)

    return parsedData
  },
  async addItem({ commit, rootState, dispatch }, data) {
    const apollo = createProvider().clients.defaultClient
    const existIcon = await dispatch('fetch')
    const lang = rootState.locale.currentLang

    try {
      checkNameExist(existIcon, data.house_icon_activity_title, 'house_icon_activity_title')
    } catch (e) {
      // console.log('e: ', e)
      throw e
    }

    const rawFile = get(data, 'house_icon_activity_files', [])
    const parsedFile = buildFileRequestBody(
      rawFile,
      HOUSE_ICON_ATTRIBUTE.house_icon_activity.house_icon_activity_files,
    )
    data.house_icon_activity_files = parsedFile
    const body = buildRequestBody(
      { house_icon_activity: { ...data, lang } },
      'house_icon_activity',
      HOUSE_ICON_ATTRIBUTE,
      lang,
    )

    const response = await apollo.mutate({
      mutation: ADD_HOUSE_ICON,
      // Parameters
      variables: {
        houseIconActivity: body.house_icon_activity,
      },
    })

    const id = get(
      response,
      ['data', 'insert_property_house_icon_activity_one', 'uuid'],
      null,
    )

    commit('ADD_ITEM', get(response, ['data', 'insert_property_house_icon_activity_one']))

    return id
  },

  async updateItem({ commit, rootState, dispatch }, data) {
    const apollo = createProvider().clients.defaultClient

    const lang = rootState.locale.currentLang

    const existIcon = await dispatch('fetch')

    try {
      checkNameExist(existIcon, data.house_icon_activity_title, 'house_icon_activity_title', data.id)
    } catch (e) {
      // console.log('e: ', e)
      throw e
    }

    const rawFile = get(data, 'house_icon_activity_files', [])
    const parsedFile = buildFileRequestBody(
      rawFile,
      HOUSE_ICON_ATTRIBUTE.house_icon_activity.house_icon_activity_files,
    )
    data.house_icon_activity_files = parsedFile
    const body = buildRequestBody(
      { house_icon_activity: { ...data, lang } },
      'house_icon_activity',
      HOUSE_ICON_ATTRIBUTE,
      lang,
    )
    // const revisionData = await getItemByUuid(data.uuid)
    const translates = body.house_icon_activity.house_icon_activity_translates

    const houseIconActivityTranslate = isEmpty(translates)
      ? []
      : translates.data.map((translate) => ({
          ...translate,
          house_icon_activity_uuid: data.uuid,
        }))

    const files = body.house_icon_activity.house_icon_activity_files
    const houseIconActivityFile = isEmpty(files)
      ? []
      : files.data.map((file) => ({
          ...file,
          house_icon_activity_uuid: data.uuid,
        }))

    const rawResponse = await apollo.mutate({
      mutation: UPDATE_HOUSE_ICON,
      // Parameters
      variables: {
        houseIconActivity: omit(body.house_icon_activity, [
          'house_icon_activity_translates',
          'house_icon_activity_files',
        ]),
        houseIconActivityTranslate,
        houseIconActivityFile,
        uuid: data.uuid,
      },
    })

    const response = get(rawResponse, [
      'data',
      'update_property_house_icon_activity_by_pk',
    ])

    commit('UPDATE_ITEM', response)

    return response
  },

  async delete({ commit }, uuid) {
    const apollo = createProvider().clients.defaultClient
    const revisionData = await getItemByUuid(uuid)

    await apollo.mutate({
      mutation: DELETE_HOUSE_ICON,
      // Parameters
      variables: {
        uuid,
        revisionData,
      },
    })

    commit('DELETE_ITEM', uuid)

    return uuid
  },
  async fetchOne({ commit }, uuid) {
    const parsed = await getItemByUuid(uuid)
    parsed.house_icon_activity_files = buildFileResponseBody(
      parsed.house_icon_activity_files,
    )

    commit('SET_ITEM', parsed)

    return parsed
  },
}
