import get from 'lodash/get'
import { createProvider } from '@/vue-apollo'
import { CREATE_FOLDER } from './constantGql'

export default {
  async createFolder(context, { path, folders }) {
    const apollo = createProvider().clients.defaultClient
    const response = await apollo.mutate({
      mutation: CREATE_FOLDER,
      variables: {
        path,
        folders
      },
    })

    context.commit('SET_RESULT', get(response, ['data', 'create_folder', 'result']))
  },
}
