/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  Object Structure:
                    path => router path
                    name => router name
                    component(lazy loading) => component to load
                    meta : {
                      rule => which user can have access (ACL)
                      breadcrumb => Add breadcrumb to specific page
                      pageTitle => Display title besides breadcrumb
                    }
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from 'vue'
import Router from 'vue-router'
import get from 'lodash/get'
import env from '@/env'

import firebase from 'firebase/app'
import 'firebase/auth'

import store from './store/store'

Vue.use(Router)

const ifNotAuthenticated = (to, from, next) => {
  const firebaseCurrentUser = firebase.auth().currentUser
  if (!firebaseCurrentUser) {
    next()
    return
  }
  next('/')
}

const router = new Router({
  mode: 'history',
  base: env.VUE_APP_PUBLIC_PATH,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: '',
      component: () => import('./layouts/main/Main.vue'),
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: '/',
          redirect: '/welcome',
        },
        {
          path: '/welcome',
          name: 'welcome',
          component: () => import('./views/Dashboard.vue'),
          meta: {
            rule: 'sale',
            authRequired: true,
          },
        },
        // =============================================================================
        // Zone Routes
        // =============================================================================
        {
          path: '/property/zone',
          name: 'property-zone',
          component: () => import('./views/apps/property-asset/zone/ZoneListView.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Zone List', active: true },
            ],
            pageTitle: 'Zone List',
            rule: 'editor',
            authRequired: true,
          },
        },
        {
          path: '/property/zone/create',
          name: 'property-zone-create',
          component: () => import('@/views/apps/property-asset/zone/ZoneForm.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Zone List', url: '/property/zone' },
              { title: 'Create Zone', active: true },
            ],
            pageTitle: 'New Zone',
            rule: 'editor',
            authRequired: true,
          },
        },
        {
          path: '/property/zone/edit/:id',
          name: 'property-zone-edit',
          component: () => import('@/views/apps/property-asset/zone/ZoneForm.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Zone List', url: '/property/zone' },
              { title: 'Edit Zone', active: true },
            ],
            pageTitle: 'Edit Zone',
            rule: 'editor',
            authRequired: true,
          },
        },
        // =============================================================================
        // Property Type Routes
        // =============================================================================
        {
          path: '/property/type',
          name: 'property-type',
          component: () => import('./views/apps/property-asset/type/TypeListView.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Property Type List', active: true },
            ],
            pageTitle: 'Property Type List',
            rule: 'editor',
            authRequired: true,
          },
        },
        {
          path: '/property/type/create',
          name: 'property-type-create',
          component: () => import('@/views/apps/property-asset/type/TypeForm.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Property Type List', url: '/property/type' },
              { title: 'Create Type', active: true },
            ],
            pageTitle: 'New Property Type',
            rule: 'editor',
            authRequired: true,
          },
        },
        {
          path: '/property/type/edit/:id',
          name: 'property-type-edit',
          component: () => import('@/views/apps/property-asset/type/TypeForm.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Property Type List', url: '/property/type' },
              { title: 'Edit Type', active: true },
            ],
            pageTitle: 'Edit Property Type',
            rule: 'editor',
            authRequired: true,
          },
        },
        // =============================================================================
        // Brand Routes
        // =============================================================================
        {
          path: '/property/brand',
          name: 'property-brand',
          component: () => import('./views/apps/property-asset/brand/BrandPage.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Brand List', active: true },
            ],
            pageTitle: 'Brand List',
            rule: 'editor',
            authRequired: true,
          },
        },
        {
          path: '/property/brand/create',
          name: 'property-brand-create',
          component: () => import('@/views/apps/property-asset/brand/BrandForm.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Brand List', url: '/property/brand' },
              { title: 'Create Brand', active: true },
            ],
            pageTitle: 'New Brand',
            rule: 'editor',
            authRequired: true,
          },
        },
        {
          path: '/property/brand/edit/:id',
          name: 'property-brand-edit',
          component: () => import('@/views/apps/property-asset/brand/BrandForm.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Brand List', url: '/property/brand' },
              { title: 'Edit Brand', active: true },
            ],
            pageTitle: 'Edit Brand',
            rule: 'editor',
            authRequired: true,
          },
        },
        // =============================================================================
        // Project Routes
        // =============================================================================
        {
          path: '/property-asset/project',
          name: 'property-asset-project',
          component: () =>
            import('./views/apps/property-asset/project/ProjectListView.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Project List', active: true },
            ],
            pageTitle: 'Project List',
            rule: 'sale',
            authRequired: true,
          },
        },
        {
          path: '/property-asset/project/create',
          name: 'property-asset-project-create',
          component: () => import('@/views/apps/property-asset/project/ProjectForm.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Project List', url: '/property-asset/project' },
              { title: 'Create Project', active: true },
            ],
            pageTitle: 'New Project',
            authRequired: true,
            rule: 'editor'
          },
        },
        {
          path: '/property-asset/project/edit/:id',
          name: 'property-asset-project-edit',
          component: () => import('@/views/apps/property-asset/project/ProjectForm.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Project List', url: '/property-asset/project' },
              { title: 'Edit Project', active: true },
            ],
            pageTitle: 'Edit Project',
            rule: 'sale',
            authRequired: true,
          },
        },
        {
          path: '/property-asset/project/:project_id/home-sell',
          name: 'property-asset-project-home-sell',
          component: () =>
            import(
              '@/views/apps/property-asset/project-home-sell/ProjectHomeSellList.vue'
            ),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Project', url: '/property-asset/project/edit/:project_id' },
              { title: 'Home Sell List', active: true },
            ],
            pageTitle: 'Home Sell',
            rule: 'sale',
            authRequired: true,
          },
        },
        {
          path: '/property-asset/project/:project_id/home-sell/create',
          name: 'property-asset-project-home-sell-create',
          component: () =>
            import(
              '@/views/apps/property-asset/project-home-sell/ProjectHomeSellForm.vue'
            ),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Project', url: '/property-asset/project/edit/:project_id' },
              {
                title: 'Home Sell List',
                url: '/property-asset/project/:project_id/home-sell',
              },
              { title: 'Create Home Sell', active: true },
            ],
            pageTitle: 'Create Home Sell',
            rule: 'sale',
            authRequired: true,
          },
        },
        {
          path: '/property-asset/project/:project_id/home-sell/edit/:uuid',
          name: 'property-asset-project-home-sell-edit',
          component: () =>
            import(
              '@/views/apps/property-asset/project-home-sell/ProjectHomeSellForm.vue'
            ),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Project', url: '/property-asset/project/edit/:project_id' },
              {
                title: 'Home Sell List',
                url: '/property-asset/project/:project_id/home-sell',
              },
              { title: 'Edit Home Sell', active: true },
            ],
            pageTitle: 'Edit Home Sell',
            rule: 'sale',
            authRequired: true,
          },
        },
        // =============================================================================
        // Promotion Routes
        // =============================================================================
        {
          path: '/property/promotion',
          name: 'property-promotion',
          component: () => import('./views/apps/promotion/PromotionListView.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Promotions', active: true },
            ],
            pageTitle: 'Promotions',
            rule: 'sale',
            authRequired: true,
          },
        },
        {
          path: '/property/promotion/create',
          name: 'property-promotion-create',
          component: () => import('@/views/apps/promotion/PromotionForm.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Promotions', url: '/property/promotion' },
              { title: 'Add Promotion', active: true },
            ],
            pageTitle: 'Add Promotion',
            rule: 'sale_not_editor',
            authRequired: true,
          },
        },
        {
          path: '/property/promotion/edit/:id',
          name: 'property-promotion-edit',
          component: () => import('@/views/apps/promotion/PromotionForm.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Promotions', url: '/property/promotion' },
              { title: 'Edit Promotion', active: true },
            ],
            pageTitle: 'Edit Promotion',
            rule: 'sale',
            authRequired: true,
          },
        },
        // =============================================================================
        // Property plan routes
        // =============================================================================
        {
          path: '/property-asset/plan/home-function',
          name: 'property-plan-home-function',
          component: () =>
            import(
              '@/views/apps/property-asset/plan/home-function/HomeFunctionListView.vue'
            ),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'เพิ่มฟังก์ชันบ้าน', active: true },
            ],
            pageTitle: 'เพิ่มฟังก์ชันบ้าน',
            rule: 'editor',
            authRequired: true,
          },
        },
        // =============================================================================
        // Condo function
        // =============================================================================
        {
          path: '/property-asset/plan/condo-function',
          name: 'property-plan-condo-function',
          component: () =>
            import(
              '@/views/apps/property-asset/plan/condo-function/CondoFunctionListView.vue'
            ),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'ฟังก์ชันคอนโด', active: true },
            ],
            pageTitle: 'ฟังก์ชันคอนโด',
            rule: 'editor',
            authRequired: true,
          },
        },
        // =============================================================================
        // Activity-icons
        // =============================================================================
        {
          path: '/property-asset/plan/activity-icons',
          name: 'property-plan-activity-icons',
          component: () =>
            import('@/views/apps/property-asset/plan/activity-icons/index.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'ไอคอนกิจกรรม', active: true },
            ],
            pageTitle: 'ไอคอนกิจกรรม',
            rule: 'editor',
            authRequired: true,
          },
        },
        // =============================================================================
        // House series
        // =============================================================================
        {
          path: '/property-asset/plan/home-series',
          name: 'property-plan-home-series',
          component: () =>
            import('@/views/apps/property-asset/plan/series/HomeSeriesListView.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'ข้อมูลสไตล์บ้าน (Series)', active: true },
            ],
            pageTitle: 'ข้อมูลสไตล์บ้าน (Series)',
            rule: 'editor',
            authRequired: true,
          },
        },
        {
          path: '/property-asset/plan/home-series/create',
          name: 'property-plan-home-series-create',
          component: () =>
            import('@/views/apps/property-asset/plan/series/HomeSeriesForm.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              {
                title: 'ข้อมูลสไตล์บ้าน (Series)',
                url: '/property-asset/plan/home-series',
              },
              { title: 'เพิ่มข้อมูลสไตล์บ้าน (Series)', active: true },
            ],
            pageTitle: 'เพิ่มข้อมูลสไตล์บ้าน (Series)',
            rule: 'editor',
            authRequired: true,
          },
        },
        {
          path: '/property-asset/plan/home-series/edit/:id',
          name: 'property-plan-home-series-edit',
          component: () =>
            import('@/views/apps/property-asset/plan/series/HomeSeriesForm.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              {
                title: 'ข้อมูลสไตล์บ้าน (Series)',
                url: '/property-asset/plan/home-series',
              },
              { title: 'แก้ไขข้อมูลสไตล์บ้าน (Series)', active: true },
            ],
            pageTitle: 'แก้ไขข้อมูลสไตล์บ้าน (Series)',
            rule: 'editor',
            authRequired: true,
          },
        },
        // =============================================================================
        // Master Gallery Home sell
        // =============================================================================
        {
          path: '/property-asset/plan/master-gallery-homesell',
          name: 'property-plan-master-gallery-homesell',
          component: () =>
            import(
              '@/views/apps/property-asset/plan/master-gallery-homesell/MasterGalleryHomesellListView.vue'
            ),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'รูปบ้านตกแต่งพร้อมขาย', active: true },
            ],
            pageTitle: 'รูปบ้านตกแต่งพร้อมขาย',
            rule: 'editor',
            authRequired: true,
          },
        },
        {
          path: '/property-asset/plan/master-gallery-homesell/create',
          name: 'property-plan-master-gallery-homesell-create',
          component: () =>
            import(
              '@/views/apps/property-asset/plan/master-gallery-homesell/MasterGalleryHomesellForm.vue'
            ),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              {
                title: 'รูปบ้านตกแต่งพร้อมขาย',
                url: '/property-asset/plan/master-gallery-homesell',
              },
              { title: 'เพิ่มรูปบ้านตกแต่งพร้อมขาย', active: true },
            ],
            pageTitle: 'เพิ่มรูปบ้านตกแต่งพร้อมขาย',
            rule: 'editor',
            authRequired: true,
          },
        },
        {
          path: '/property-asset/plan/master-gallery-homesell/edit/:id',
          name: 'property-plan-master-gallery-homesell-edit',
          component: () =>
            import(
              '@/views/apps/property-asset/plan/master-gallery-homesell/MasterGalleryHomesellForm.vue'
            ),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              {
                title: 'รูปบ้านตกแต่งพร้อมขาย',
                url: '/property-asset/plan/master-gallery-homesell',
              },
              { title: 'แก้ไขรูปบ้านตกแต่งพร้อมขาย', active: true },
            ],
            pageTitle: 'แก้ไขรูปบ้านตกแต่งพร้อมขาย',
            rule: 'editor',
            authRequired: true,
          },
        },
        // =============================================================================
        // House Model
        // =============================================================================
        {
          path: '/property-asset/plan/home-models',
          name: 'property-plan-home-model',
          component: () =>
            import('@/views/apps/property-asset/plan/home-model/HomeModelListView.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'ข้อมูลแบบบ้าน', active: true },
            ],
            pageTitle: 'ข้อมูลแบบบ้าน',
            rule: 'editor',
            authRequired: true,
          },
        },
        {
          path: '/property-asset/plan/home-models/create',
          name: 'property-plan-home-model-create',
          component: () =>
            import('@/views/apps/property-asset/plan/home-model/HomeModelForm.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'ข้อมูลแบบบ้าน', url: '/property-asset/plan/home-models' },
              { title: 'เพิ่มข้อมูลแบบบ้าน', active: true },
            ],
            pageTitle: 'เพิ่มข้อมูลแบบบ้าน',
            rule: 'editor',
            authRequired: true,
          },
        },
        {
          path: '/property-asset/plan/home-models/edit/:id',
          name: 'property-plan-home-model-edit',
          component: () =>
            import('@/views/apps/property-asset/plan/home-model/HomeModelForm.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'ข้อมูลแบบบ้าน', url: '/property-asset/plan/home-models' },
              { title: 'แก้ไขข้อมูลแบบบ้าน', active: true },
            ],
            pageTitle: 'แก้ไขข้อมูลแบบบ้าน',
            rule: 'editor',
            authRequired: true,
          },
        },
        // =============================================================================
        // Landing Page Routes
        // =============================================================================
        {
          path: '/landing-pages',
          name: 'landing-page',
          component: () => import('./views/apps/landing-page/LandingPageListView.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Landing Pages', active: true },
            ],
            pageTitle: 'Landing Pages',
            rule: 'editor',
            authRequired: true,
          },
        },
        {
          path: '/landing-pages/create',
          name: 'landing-page-create',
          component: () => import('@/views/apps/landing-page/LandingPageForm.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              {
                title: 'Landing Pages',
                url: '/landing-pages',
              },
              { title: 'Add Landing Page', active: true },
            ],
            pageTitle: 'Add Landing Page',
            rule: 'editor',
            authRequired: true,
          },
        },
        {
          path: '/landing-pages/edit/:id',
          name: 'landing-page-edit',
          component: () => import('@/views/apps/landing-page/LandingPageForm.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              {
                title: 'Landing Pages',
                url: '/landing-pages',
              },
              { title: 'Edit Landing Page', active: true },
            ],
            pageTitle: 'Edit Landing Page',
            rule: 'editor',
            authRequired: true,
          },
        },
        // =============================================================================
        // LH Content Routes
        // =============================================================================
        {
          path: '/lh-contents/motivo-magazines',
          name: 'lh-content-motivo',
          component: () => import('./views/apps/lh-content/motivo/MagazineListView.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Motivo Magazines', active: true },
            ],
            pageTitle: 'Motivo Magazines',
            rule: 'editor',
            authRequired: true,
          },
        },
        {
          path: '/lh-contents/motivo-magazines/create',
          name: 'lh-content-motivo-create',
          component: () => import('@/views/apps/lh-content/motivo/MagazineForm.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              {
                title: 'Motivo Magazines',
                url: '/lh-contents/motivo-magazines',
              },
              { title: 'Add Magazine', active: true },
            ],
            pageTitle: 'Add Magazine',
            rule: 'editor',
            authRequired: true,
          },
        },
        {
          path: '/lh-contents/motivo-magazines/edit/:id',
          name: 'lh-content-motivo-edit',
          component: () => import('@/views/apps/lh-content/motivo/MagazineForm.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              {
                title: 'Motivo Magazines',
                url: '/lh-contents/motivo-magazines',
              },
              { title: 'Edit Magazine', active: true },
            ],
            pageTitle: 'Edit Magazine',
            rule: 'editor',
            authRequired: true,
          },
        },
        {
          path: '/lh-contents/tvc',
          name: 'lh-content-tvc',
          component: () => import('@/views/apps/lh-content/tvc/TvcListView.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'TVC', active: true },
            ],
            pageTitle: 'TVC',
            rule: 'editor',
            authRequired: true,
          },
        },
        {
          path: '/lh-contents/tvc/create',
          name: 'lh-content-tvc-create',
          component: () => import('@/views/apps/lh-content/tvc/TvcForm.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'TVC', url: '/lh-contents/tvc' },
              { title: 'Create', active: true },
            ],
            pageTitle: 'Create TVC',
            rule: 'editor',
            authRequired: true,
          },
        },
        {
          path: '/lh-contents/tvc/edit/:id',
          name: 'lh-content-tvc-edit',
          component: () => import('@/views/apps/lh-content/tvc/TvcForm.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'TVC', url: '/lh-contents/tvc' },
              { title: 'Edit TVC', active: true },
            ],
            pageTitle: 'Edit TVC',
            rule: 'editor',
            authRequired: true,
          },
        },
        {
          path: '/lh-contents/project-review',
          name: 'lh-content-project-review',
          component: () =>
            import('@/views/apps/lh-content/projectReview/ProjectReviewListView.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Project Review', active: true },
            ],
            pageTitle: 'Project Review',
            rule: 'editor',
            authRequired: true,
          },
        },
        {
          path: '/lh-contents/project-review/create',
          name: 'lh-content-project-review-create',
          component: () =>
            import('@/views/apps/lh-content/projectReview/ProjectReviewForm.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Project Review', url: '/lh-contents/project-review' },
              { title: 'Create', active: true },
            ],
            pageTitle: 'Create Project Review',
            rule: 'editor',
            authRequired: true,
          },
        },
        {
          path: '/lh-contents/project-review/edit/:id',
          name: 'lh-content-project-review-edit',
          component: () =>
            import('@/views/apps/lh-content/projectReview/ProjectReviewForm.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Project Review', url: '/lh-contents/project-review' },
              { title: 'Edit Project Review', active: true },
            ],
            pageTitle: 'Edit Project Review',
            rule: 'editor',
            authRequired: true,
          },
        },
        {
          path: '/lh-contents/community',
          name: 'lh-content-community',
          component: () =>
            import('@/views/apps/lh-content/lhCommunity/LHCommunityListView.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'LH Community', active: true },
            ],
            pageTitle: 'LH Community',
            rule: 'editor',
            authRequired: true,
          },
        },
        {
          path: '/lh-contents/community/create',
          name: 'lh-content-community-create',
          component: () =>
            import('@/views/apps/lh-content/lhCommunity/LHCommunityForm.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'LH Community', url: '/lh-contents/community' },
              { title: 'Create', active: true },
            ],
            pageTitle: 'Create LH Community',
            rule: 'editor',
            authRequired: true,
          },
        },
        {
          path: '/lh-contents/community/edit/:id',
          name: 'lh-content-community-edit',
          component: () =>
            import('@/views/apps/lh-content/lhCommunity/LHCommunityForm.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'LH Community', url: '/lh-contents/community' },
              { title: 'Edit LH Community', active: true },
            ],
            pageTitle: 'Edit LH Community',
            rule: 'editor',
            authRequired: true,
          },
        },
        {
          path: '/lh-contents/living-tips',
          name: 'lh-content-living-tip',
          component: () =>
            import('@/views/apps/lh-content/livingTip/LivingTipListView.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Living Tips', active: true },
            ],
            pageTitle: 'Living Tips',
            rule: 'editor',
            authRequired: true,
          },
        },
        {
          path: '/lh-contents/living-tips/create',
          name: 'lh-content-living-tip-create',
          component: () => import('@/views/apps/lh-content/livingTip/LivingTipForm.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Living Tips', url: '/lh-contents/living-tips' },
              { title: 'Create', active: true },
            ],
            pageTitle: 'Create Living Tip',
            rule: 'editor',
            authRequired: true,
          },
        },
        {
          path: '/lh-contents/living-tips/edit/:id',
          name: 'lh-content-living-tip-edit',
          component: () => import('@/views/apps/lh-content/livingTip/LivingTipForm.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Living Tips', url: '/lh-contents/living-tips' },
              { title: 'Edit Living Tip', active: true },
            ],
            pageTitle: 'Edit Living Tip',
            rule: 'editor',
            authRequired: true,
          },
        },
        {
          path: '/media-widget',
          name: 'media-widget',
          component: () => import('@/views/apps/media-widget/index.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Media Widget', active: true },
            ],
            pageTitle: 'Media Widget',
            rule: 'editor',
            authRequired: true,
          },
        },
        // =============================================================================
        // SEO Routes
        // =============================================================================
        {
          path: '/seo',
          name: 'seo',
          component: () => import('@/views/apps/seo/SEOListView.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'SEO', active: true },
            ],
            pageTitle: 'SEO',
            rule: 'editor',
            authRequired: true,
          },
        },
        {
          path: '/seo/create',
          name: 'seo-create',
          component: () => import('@/views/apps/seo/SEOForm.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'SEO', url: '/seo' },
              { title: 'Create', active: true },
            ],
            pageTitle: 'Create SEO',
            rule: 'editor',
            authRequired: true,
          },
        },
        {
          path: '/seo/edit/:id',
          name: 'seo-edit',
          component: () => import('@/views/apps/seo/SEOForm.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'SEO', url: '/seo' },
              { title: 'Edit SEO', active: true },
            ],
            pageTitle: 'Edit SEO',
            rule: 'editor',
            authRequired: true,
          },
        },
        // =============================================================================
        // Redirection Routes
        // =============================================================================
        {
          path: '/redirection',
          name: 'redirection',
          component: () => import('@/views/apps/redirection/RedirectionListView.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Redirection', active: true },
            ],
            pageTitle: 'Redirection',
            rule: 'editor',
            authRequired: true,
          },
        },
        {
          path: '/redirection/create',
          name: 'redirection-create',
          component: () => import('@/views/apps/redirection/RedirectionForm.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Redirection', url: '/redirection' },
              { title: 'Create', active: true },
            ],
            pageTitle: 'Create Redirection',
            rule: 'editor',
            authRequired: true,
          },
        },
        {
          path: '/redirection/edit/:id',
          name: 'redirection-edit',
          component: () => import('@/views/apps/redirection/RedirectionForm.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Redirection', url: '/redirection' },
              { title: 'Edit Redirection', active: true },
            ],
            pageTitle: 'Edit Redirection',
            rule: 'editor',
            authRequired: true,
          },
        },
        // =============================================================================
        // BlacklistEmail Routes
        // =============================================================================
        {
          path: '/blacklist-email',
          name: 'blacklistEmail',
          component: () => import('@/views/apps/blacklist-email/BlackListEmailListView.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Blacklist Email', active: true },
            ],
            pageTitle: 'Blacklist Email',
            rule: 'editor',
            authRequired: true,
          },
        },
        {
          path: '/blacklist-email/create',
          name: 'blacklistEmail-create',
          component: () => import('@/views/apps/blacklist-email/BlackListEmailForm.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Blacklist Email', url: '/blacklist-email' },
              { title: 'Create', active: true },
            ],
            pageTitle: 'Create Blacklist Email',
            rule: 'editor',
            authRequired: true,
          },
        },
        {
          path: '/blacklist-email/edit/:id',
          name: 'blacklistEmail-edit',
          component: () => import('@/views/apps/blacklist-email/BlackListEmailForm.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Blacklist Email', url: '/blacklist-email' },
              { title: 'Edit Blacklist Email', active: true },
            ],
            pageTitle: 'Edit Blacklist Email',
            rule: 'editor',
            authRequired: true,
          },
        },
        // =============================================================================
        // Banner Routes
        // =============================================================================
        {
          path: '/banners',
          name: 'banner',
          component: () => import('@/views/apps/banner/BannerListView.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Banner', active: true },
            ],
            pageTitle: 'Banner',
            rule: 'editor',
            authRequired: true,
          },
        },
        {
          path: '/banners/create',
          name: 'banner-create',
          component: () => import('@/views/apps/banner/BannerForm.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Banner', url: '/banners' },
              { title: 'Create', active: true },
            ],
            pageTitle: 'Create Banner',
            rule: 'editor',
            authRequired: true,
          },
        },
        {
          path: '/banners/edit/:id',
          name: 'banner-edit',
          component: () => import('@/views/apps/banner/BannerForm.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Banner', url: '/banners' },
              { title: 'Edit Banner', active: true },
            ],
            pageTitle: 'Edit Banner',
            rule: 'editor',
            authRequired: true,
          },
        },
        // =============================================================================
        // Highlight Routes
        // =============================================================================
        {
          path: '/highlights',
          name: 'highlight',
          component: () =>
            import('@/views/apps/banner-highlight/BannerHighlightListView.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Highlight', active: true },
            ],
            pageTitle: 'Highlight',
            rule: 'editor',
            authRequired: true,
          },
        },
        {
          path: '/highlights/create',
          name: 'highlight-create',
          component: () =>
            import('@/views/apps/banner-highlight/BannerHighlightForm.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Highlight', url: '/highlights' },
              { title: 'Create', active: true },
            ],
            pageTitle: 'Create Highlight',
            rule: 'editor',
            authRequired: true,
          },
        },
        {
          path: '/highlights/edit/:id',
          name: 'highlight-edit',
          component: () =>
            import('@/views/apps/banner-highlight/BannerHighlightForm.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Highlight', url: '/highlights' },
              { title: 'Edit Highlight', active: true },
            ],
            pageTitle: 'Edit Highlight',
            rule: 'editor',
            authRequired: true,
          },
        },
        // =============================================================================
        // Footer Routes
        // =============================================================================
        {
          path: '/footers',
          name: 'footer',
          component: () => import('@/views/apps/footer/FooterListView.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Footer', active: true },
            ],
            pageTitle: 'Footer',
            rule: 'editor',
            authRequired: true,
          },
        },
        {
          path: '/footers/create',
          name: 'footer-create',
          component: () => import('@/views/apps/footer/FooterForm.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Footer', url: '/footers' },
              { title: 'Create', active: true },
            ],
            pageTitle: 'Create Footer',
            rule: 'editor',
            authRequired: true,
          },
        },
        {
          path: '/footers/edit/:id',
          name: 'footer-edit',
          component: () => import('@/views/apps/footer/FooterForm.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Footer', url: '/footers' },
              { title: 'Edit Footer', active: true },
            ],
            pageTitle: 'Edit Footer',
            rule: 'editor',
            authRequired: true,
          },
        },
        {
          path: '/vdo-project',
          name: 'vdo-project',
          component: () => import('@/views/apps/vdoProjectClip/VdoListView.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'VDO Project Clip', active: true },
            ],
            pageTitle: 'VDO Project Clip',
            rule: 'editor',
            authRequired: true,
          },
        },
        {
          path: '/vdo-project/create',
          name: 'vdo-project-create',
          component: () => import('@/views/apps/vdoProjectClip/VdoForm.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'VDO Project Clip', url: '/vdo-project' },
              { title: 'Create', active: true },
            ],
            pageTitle: 'Create VDO Project Clip',
            rule: 'editor',
            authRequired: true,
          },
        },
        {
          path: '/vdo-project/edit/:id',
          name: 'vdo-project-edit',
          component: () => import('@/views/apps/vdoProjectClip/VdoForm.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'VDO Project Clip', url: '/vdo-project' },
              { title: 'Edit VDO Project Clip', active: true },
            ],
            pageTitle: 'Edit VDO Project Clip',
            rule: 'editor',
            authRequired: true,
          },
        },
        {
          path: '/csr',
          name: 'csr',
          component: () => import('@/views/apps/csr/CsrListView.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'CSR', active: true },
            ],
            pageTitle: 'CSR',
            rule: 'editor',
            authRequired: true,
          },
        },
        {
          path: '/csr/create',
          name: 'csr-create',
          component: () => import('@/views/apps/csr/CsrForm.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'CSR', url: '/csr' },
              { title: 'Create', active: true },
            ],
            pageTitle: 'Create CSR',
            rule: 'editor',
            authRequired: true,
          },
        },
        {
          path: '/csr/edit/:id',
          name: 'csr-edit',
          component: () => import('@/views/apps/csr/CsrForm.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'CSR', url: '/csr' },
              { title: 'Edit CSR', active: true },
            ],
            pageTitle: 'Edit CSR',
            rule: 'editor',
            authRequired: true,
          },
        },
        // =============================================================================
        // Admin Users Routes
        // =============================================================================
        {
          path: '/admin/users',
          name: 'admin-user',
          component: () => import('@/views/apps/admin-user/AdminUserListView.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Admin User', active: true },
            ],
            pageTitle: 'Admin User',
            rule: 'sale',
            authRequired: true,
          },
        },
        {
          path: '/admin/users/create',
          name: 'admin-user-create',
          component: () => import('@/views/apps/admin-user/AdminUserForm.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Admin User', url: '/admin/users' },
              { title: 'Create', active: true },
            ],
            pageTitle: 'Create Admin User',
            rule: 'sale',
            authRequired: true,
          },
        },
        {
          path: '/admin/users/edit/:id',
          name: 'admin-user-edit',
          component: () => import('@/views/apps/admin-user/AdminUserForm.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Admin User', url: '/admin/users' },
              { title: 'Edit Admin User', active: true },
            ],
            pageTitle: 'Edit Admin User',
            rule: 'sale',
            authRequired: true,
          },
        },

        // =============================================================================
        // User Group Routes
        // =============================================================================
        {
          path: '/user-groups',
          name: 'user-group',
          component: () =>
            import('@/views/apps/admin-user/user-group/UserGroupListView.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'User Group', active: true },
            ],
            pageTitle: 'User Group',
            rule: 'editor',
            authRequired: true,
          },
        },
        {
          path: '/user-groups/create',
          name: 'user-group-create',
          component: () => import('@/views/apps/admin-user/user-group/UserGroupForm.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'User Group', url: '/user-groups' },
              { title: 'Create', active: true },
            ],
            pageTitle: 'Create User Group',
            rule: 'editor',
            authRequired: true,
          },
        },
        {
          path: '/user-groups/edit/:id',
          name: 'user-group-edit',
          component: () => import('@/views/apps/admin-user/user-group/UserGroupForm.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'User Group', url: '/user-groups' },
              { title: 'Edit User Group', active: true },
            ],
            pageTitle: 'Edit User Group',
            rule: 'editor',
            authRequired: true,
          },
        },
        // =============================================================================
        // User Management
        // =============================================================================
        {
          path: '/user/user-list',
          name: 'user-list',
          component: () => import('@/views/apps/user/user-list/UserList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'User' },
              { title: 'List', active: true },
            ],
            pageTitle: 'User List',
            rule: 'super_editor',
            authRequired: true,
          },
        },
        {
          path: '/user/user-view/:userId',
          name: 'app-user-view',
          component: () => import('@/views/apps/user/UserView.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'User' },
              { title: 'View', active: true },
            ],
            pageTitle: 'User View',
            rule: 'super_editor',
            authRequired: true,
          },
        },
        {
          path: '/user/user-edit/:userId',
          name: 'app-user-edit',
          component: () => import('@/views/apps/user/user-edit/UserEdit.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'User' },
              { title: 'Edit', active: true },
            ],
            pageTitle: 'User Edit',
            rule: 'super_editor',
            authRequired: true,
          },
        },
        {
          path: '/user/create',
          name: 'user-create',
          component: () => import('@/views/apps/user/UserForm.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'User' },
              { title: 'Create', active: true },
            ],
            pageTitle: 'New User',
            rule: 'super_editor',
            authRequired: true,
          },
        },

        // =============================================================================
        // Other Routes
        // =============================================================================
        {
          path: '/other/manual',
          name: 'manual',
          component: () => import('@/views/apps/manual/Manual.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'คู่มือการใช้งานระบบ', active: true },
            ],
            pageTitle: 'คู่มือการใช้งานระบบ',
            rule: 'guest',
            authRequired: true,
          },
        },
        {
          path: '/other/contact-us',
          name: 'contact-us',
          component: () =>
            import('@/views/apps/contact-us/ContactUsListView.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Contact Us', url: '/other/contact-us' },
            ],
            pageTitle: 'Contact Us',
            rule: 'editor',
            authRequired: true,
          },
        },
        {
          path: '/other/report',
          name: 'report',
          component: () =>
            import('@/views/apps/report/ReportListView.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Report', url: '/other/report' },
            ],
            pageTitle: 'Report',
            rule: 'editor',
            authRequired: true,
          },
        },
      ],
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: '/callback',
          name: 'auth-callback',
          component: () => import('@/views/Callback.vue'),
          meta: {
            rule: 'editor',
          },
        },
        {
          path: '/pages/login',
          name: 'page-login',
          component: () => import('@/views/pages/login/Login.vue'),
          meta: {
            rule: 'guest',
          },
          beforeEnter: ifNotAuthenticated
        },
        // {
        //   path: '/pages/register',
        //   name: 'page-register',
        //   component: () => import('@/views/pages/register/Register.vue'),
        //   meta: {
        //     rule: 'editor',
        //   },
        // },
        {
          path: '/pages/forgot-password',
          name: 'page-forgot-password',
          component: () => import('@/views/pages/ForgotPassword.vue'),
          meta: {
            rule: 'guest',
          },
        },
        {
          path: '/pages/reset-password',
          name: 'page-reset-password',
          component: () => import('@/views/pages/ResetPassword.vue'),
          meta: {
            rule: 'guest',
          },
        },
        {
          path: '/pages/lock-screen',
          name: 'page-lock-screen',
          component: () => import('@/views/pages/LockScreen.vue'),
          meta: {
            rule: 'guest',
          },
        },
        {
          path: '/pages/comingsoon',
          name: 'page-coming-soon',
          component: () => import('@/views/pages/ComingSoon.vue'),
          meta: {
            rule: 'guest',
          },
        },
        {
          path: '/pages/error-404',
          name: 'page-error-404',
          component: () => import('@/views/pages/Error404.vue'),
          meta: {
            rule: 'guest',
          },
        },
        {
          path: '/pages/error-500',
          name: 'page-error-500',
          component: () => import('@/views/pages/Error500.vue'),
          meta: {
            rule: 'guest',
          },
        },
        {
          path: '/pages/not-authorized',
          name: 'page-not-authorized',
          component: () => import('@/views/pages/NotAuthorized.vue'),
          meta: {
            rule: 'guest',
          },
        },
        {
          path: '/pages/maintenance',
          name: 'page-maintenance',
          component: () => import('@/views/pages/Maintenance.vue'),
          meta: {
            rule: 'guest',
          },
        },
      ],
    },
    // Redirect to 404 page, if no match found
    {
      path: '*',
      redirect: '/pages/error-404',
    },
  ],
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach(async (to, from, next) => {
  if (
    to.path.indexOf('/property/promotion') === -1
    && to.path.indexOf('/lh-contents/project-review') === -1
    && to.path.indexOf('/property-asset/plan/master-gallery-homesell') === -1
  ) {
    store.dispatch('promotion/fetch')
    store.dispatch('project/fetchProjectName')  
  }

  if (to.path.indexOf('/lh-contents/project-review') > -1
    || to.path.indexOf('/property-asset/plan/master-gallery-homesell') > -1
  ) {
    store.dispatch('promotion/fetch')
  }

  firebase.auth().onAuthStateChanged(async() => {
    // get firebase current user
    const firebaseCurrentUser = firebase.auth().currentUser

    // const firebaseCurrentUser = firebase.auth().currentUser
    if (firebaseCurrentUser) {
      const uid = get(firebaseCurrentUser, ['uid'], null)
      if (uid) {
        const rawAccountInfoDB = await store.dispatch('auth/fetchUserByProvider', {
          authProvider: 'firebase', authProviderUniqueId: uid
        })

        const accountInfoDB = get(rawAccountInfoDB, 0, null)
        const linkGroup = get(accountInfoDB, ['account', 'user', 'user_group_links'], [])
        const listGroupName = linkGroup.map((group) => {
          return get(group, ['user_group', 'group_name'], '')
        })

        const data = {
          ...firebaseCurrentUser.providerData[0],
          uid,
          userRole: get(accountInfoDB, ['account', 'default_role'], 'guest'),
          displayName: get(accountInfoDB, ['account', 'user', 'display_name'], 'Guest'),
          setting: get(accountInfoDB, ['account', 'user', 'setting'], {}),
          groupName: listGroupName || [],
        }

        await store.dispatch('updateUserInfo', data)
      }
    }

    if (
      to.path === '/pages/login' ||
      to.path === '/pages/forgot-password' ||
      to.path === '/pages/error-404' ||
      to.path === '/pages/error-500' ||
      to.path === '/pages/register' ||
      to.path === '/callback' ||
      to.path === '/pages/comingsoon' ||
      firebaseCurrentUser
    ) {
      return next()
    }

    if (to.meta.authRequired) {
      if (!firebaseCurrentUser) {
        // router.push({ path: '/pages/login', query: { to: to.path } })
        return next({ path: '/pages/login', query: { to: to.fullPath } })
      }
    }

    return next()
  })
})

export default router
