const state = {
  currentLang: localStorage.getItem('contentLang') || 'th',
}

const getters = {
  getLabel: (state) => {
    if (state.currentLang === 'th') {
      return "ไทย"
    }

    if (state.currentLang === 'cn') {
      return "中文"
    }

    return 'ENG'
  }
}

const mutations = {
  SET_CURRENT_LANG(state, value) {
    state.currentLang = value
    localStorage.setItem('contentLang', value)
  },
}

const actions = {
  changeLang({ commit }, value) {
    commit('SET_CURRENT_LANG', value)
  },
}

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
