import gql from 'graphql-tag'

export const CREATE = gql`
  mutation($toCreateItem: cms_project_vdo_insert_input!) {
    result: insert_cms_project_vdo_one(object: $toCreateItem) {
      uuid
      id
    }
  }
`
export const UPDATE = gql`
  mutation (
    $vdo: [cms_project_vdo_insert_input!]!
    $id: Int!
    $lang: String!
  ) {
    update_cms_project_vdo(
      _set: { status: "archived" }
      where: {
        id: { _eq: $id }
        lang: { _eq: $lang }
      }
    ) {
      affected_rows
    }
    updateResult: insert_cms_project_vdo(objects: $vdo) {
      returning {
        uuid
      }
    }
  }
`

export const DELETE = gql`
  mutation ($uuid: uuid!) {
    update_cms_project_vdo(where: {uuid: {_eq: $uuid}}, _set: {status: "archived"}) {
      affected_rows 
    }
  }
`

export const FETCH = gql`
  query($lang: String!) {
    result: cms_project_vdo(
      where: { status: { _neq: "archived" }, lang: { _eq: $lang } }
      order_by: { updated_at: desc }
    ) {
      uuid
      id
      lang
      published_date
      show_homepage
      updated_at
      created_by
      updated_by
      description
      title
      video_thumbnail
    }
  }
`

export const FIND_ONE_BY_ID = gql`
  query($id: Int!) {
    result: cms_project_vdo(
      where: {
        status: { _neq: "archived" }, id: { _eq: $id }
      }
      order_by: { updated_at: desc }
    ) {
      uuid
      id
      lang
      title
      published_date
      show_homepage
      video_file
      video_url
      description
      video_type
      video_thumbnail
      description
      updated_at
      created_by
      updated_by
      folder_name
    }
  }
`
