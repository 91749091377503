import get from 'lodash/get'
import omit from 'lodash/omit'
import isEmpty from 'lodash/isEmpty'
import { createProvider } from '@/vue-apollo'
import { FETCH, CREATE, UPDATE, DELETE, FIND_ONE_BY_ID } from './constantGql'

export default {
  async fetch(context) {
    const apollo = createProvider().clients.defaultClient
    const response = await apollo.query({
      query: FETCH,
      variables: {
        lang: context.rootState.locale.currentLang,
      },
    })

    context.commit('SET_ITEMS', get(response, ['data', 'result']))
  },

  async fetchOne(context, id) {
    const apollo = createProvider().clients.defaultClient
    const response = await apollo.query({
      query: FIND_ONE_BY_ID,
      variables: { id },
    })
    return get(response, ['data', 'result'])
  },

  async addItem(context, data) {
    const apollo = createProvider().clients.defaultClient

    try {
      const {
        data: { result },
      } = await apollo.mutate({
        mutation: CREATE,
        variables: {
          toCreateItem: {
            ...omit(data, ['id', 'created_at']),
            lang: context.rootState.locale.currentLang,
          },
        },
      })
      return result.content

    } catch (e) {
      const errors = e.graphQLErrors
      if (!isEmpty(errors)) {
        if (
          errors[0].message.split('unique constraint "content_slug_lang_key"').length > 1
        ) {
          throw 'ไม่สามารถบันทึกข้อมูลได้ เนื่องจากชื่อ หรือ URL พิเศษซ้ำกับข้อมูลที่มีอยู่ในระบบ'
        } else {
          throw 'ไม่สามารถบันทึกข้อมูลได้ โปรดลองใหม่อีกครั้ง'
        }
      }
    }
  },

  async updateItem(context, { initialData, formData }) {
    const apollo = createProvider().clients.defaultClient

    try {
      const {
        data: { updateResult },
      } = await apollo.mutate({
        mutation: UPDATE,
        variables: {
          id: formData.id,
          toUpdateItem: formData,
          toDeleteItem: omit(initialData, ['status']),
        }
      })

      return updateResult.id
    } catch (e) {
      const errors = e.graphQLErrors
      if (!isEmpty(errors)) throw errors[0]
    }
  },

  async delete(context, id) {
    const apollo = createProvider().clients.defaultClient

    const {
      data: { result: toDeleteItem },
    } = await apollo.mutate({
      mutation: FIND_ONE_BY_ID,
      variables: { id },
    })

    const result = await apollo.mutate({
      mutation: DELETE,
      variables: {
        id,
        toDeleteItem: {
          ...omit(toDeleteItem, ['__typename', 'updated_at', 'content_categories']),
          status: 'archived',
        },
      },
    })

    return result
  },
}
