import gql from 'graphql-tag'

export const CREATE = gql`
  mutation(
    $custom_banner: jsonb
    $desktop_banner: cms_banner_obj_rel_insert_input
    $is_event: Boolean
    $mobile_banner: cms_banner_obj_rel_insert_input
    $page_url: String!
    $lang: String!
    $folder_name: String!
    $updated_by: String!
    $created_by: String!
  ) {
    result: insert_cms_page_banner_one(
      object: {
        page_url: $page_url
        desktop_banner: $desktop_banner
        mobile_banner: $mobile_banner
        custom_banner: $custom_banner
        is_event: $is_event
        lang: $lang
        folder_name: $folder_name
        created_by: $created_by
        updated_by: $updated_by
      }
    ) {
      id
      revision_id
    }
  }
`

export const UPDATE = gql`
  mutation(
    $id: Int!
    $custom_banner: jsonb
    $desktop_banner: cms_banner_obj_rel_insert_input
    $is_event: Boolean
    $mobile_banner: cms_banner_obj_rel_insert_input
    $page_url: String!
    $lang: String!
    $folder_name: String!
    $updated_by: String!
    $created_by: String!
  ) {
    update_cms_page_banner(
      where: {
        page_url: { _eq: $page_url }
        lang: { _eq: $lang }
        status: { _neq: "archived" }
      }
      _set: { status: "archived" }
    ) {
      returning {
        id
      }
    }
    result: insert_cms_page_banner_one(
      object: {
        id: $id
        page_url: $page_url
        desktop_banner: $desktop_banner
        mobile_banner: $mobile_banner
        custom_banner: $custom_banner
        is_event: $is_event
        lang: $lang
        folder_name: $folder_name
        created_by: $created_by
        updated_by: $updated_by
      }
    ) {
      id
      revision_id
    }
  }
`

export const DELETE = gql`
  mutation($revision_id: uuid!) {
    result: update_cms_page_banner_by_pk(
      pk_columns: { revision_id: $revision_id }
      _set: { status: "archived" }
    ) {
      id
      revision_id
    }
  }
`
export const FETCH = gql`
  query($lang: String!) {
    result: cms_page_banner(
      where: { status: { _neq: "archived" }, lang: { _eq: $lang } }
      order_by: { updated_at: desc }
    ) {
      id
      revision_id
      page_url
      desktop_banner_id
      mobile_banner_id
      custom_banner
      updated_at
      updated_by
      created_by
    }
  }
`

export const FETCH_ALL = gql`
  query {
    result: cms_page_banner(
      where: { status: { _neq: "archived" }}
      order_by: { updated_at: desc }
    ) {
      id
      lang
      revision_id
      page_url
      desktop_banner_id
      mobile_banner_id
      custom_banner
      updated_at
    }
  }
`

export const FIND_ONE_BY_ID = gql`
  query($revision_id: uuid!) {
    result: cms_page_banner_by_pk(revision_id: $revision_id) {
      id
      revision_id
      desktop_banner_id
      mobile_banner_id
      page_url
      custom_banner
      is_event
      mobile_banner {
        items
        type
      }
      desktop_banner {
        items
        type
      }
      folder_name
      updated_at
      updated_by
      created_by
    }
  }
`
