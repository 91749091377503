import { createProvider } from '@/vue-apollo'
import { GET_PAGE } from './constantGql'

export default {
  async fetchPages({ commit, rootState }) {
    const apollo = createProvider().clients.defaultClient
    const lang = rootState.locale.currentLang
    const response = await apollo.query({
      query: GET_PAGE,
      variables: {
        type: ['category', 'simple_content'],
        lang,
      },
    })

    commit('SET_PAGE', response.data.pages)

    return response.data.pages
  },
  async fetchCategoryPageByLang(_, lang) {
    const apollo = createProvider().clients.defaultClient
    const response = await apollo.query({
      query: GET_PAGE,
      variables: {
        type: ['category'],
        lang,
      },
    })

    return response.data.pages
  },
}
