import gql from 'graphql-tag'

export const CREATE = gql`
  mutation(
    $title: String!
    $description: String!
    $thumbnail_id: Int!
    $asset_id: Int
    $asset_type: String!
    $asset_value: String!
    $link_url: String
    $published_at: date!
    $lang: String!
    $folder_name: String!
    $created_by: String!
    $updated_by: String!
  ) {
    result: insert_cms_tvc_one(
      object: {
        title: $title
        description: $description
        thumbnail_id: $thumbnail_id
        asset_id: $asset_id
        asset_type: $asset_type
        asset_value: $asset_value
        link_url: $link_url
        published_at: $published_at
        lang: $lang
        folder_name: $folder_name
        created_by: $created_by
        updated_by: $updated_by
      }
    ) {
      id
      revision_id
    }
  }
`

export const UPDATE = gql`
  mutation(
    $id: Int!
    $title: String!
    $description: String!
    $thumbnail_id: Int!
    $asset_id: Int
    $asset_type: String!
    $asset_value: String!
    $link_url: String
    $published_at: date!
    $lang: String!
    $folder_name: String!
    $created_by: String!
    $updated_by: String!
  ) {
    result: insert_cms_tvc_one(
      object: {
        id: $id
        title: $title
        description: $description
        thumbnail_id: $thumbnail_id
        asset_id: $asset_id
        asset_type: $asset_type
        asset_value: $asset_value
        link_url: $link_url
        published_at: $published_at
        lang: $lang
        folder_name: $folder_name
        created_by: $created_by
        updated_by: $updated_by
      }
    ) {
      id
      revision_id
    }
  }
`

export const DELETE = gql`
  mutation($revision_id: uuid!) {
    result: update_cms_tvc_by_pk(
      pk_columns: { revision_id: $revision_id }
      _set: { status: "archived" }
    ) {
      id
      revision_id
    }
  }
`

export const FETCH = gql`
  query($lang: String!) {
    result: cms_tvc(
      where: { status: { _neq: "archived" }, lang: { _eq: $lang } }
      order_by: { updated_at: desc }
    ) {
      id
      revision_id
      title
      description
      asset_type
      asset_id
      link_url
      asset_value
      published_at
      updated_at
      created_by
      updated_by
    }
  }
`

export const FIND_ONE_BY_ID = gql`
  query($revision_id: uuid!) {
    result: cms_tvc_by_pk(revision_id: $revision_id) {
      id
      revision_id
      title
      description
      thumbnail_id
      asset_id
      asset_type
      asset_value
      published_at
      folder_name
      link_url
      thumbnail {
        mime_type
        file_name
        file_size
        file_url
        width
        height
        resource_type
      }
      asset {
        mime_type
        file_name
        file_size
        file_url
        width
        height
        resource_type
      }
      created_at
      updated_at
      created_by
      updated_by
    }
  }
`
