/* eslint-disable */
import axios from 'axios'
import sha1 from 'js-sha1'
import gql from 'graphql-tag'
import snakeCase from 'lodash/snakeCase'
import isNil from 'lodash/isNil'
import get from 'lodash/get'
import env from '@/env'
import { createProvider } from '@/vue-apollo'
import store from '../store/store'

const createAssetGQL = gql`
  mutation(
    $fileName: String!
    $fileUrl: String!
    $fileSize: Int!
    $height: Int
    $width: Int
    $mimeType: String!
    $resourceType: String!
  ) {
    insert_dam_assets_one(
      object: {
        file_name: $fileName
        file_url: $fileUrl
        file_size: $fileSize
        height: $height
        width: $width
        mime_type: $mimeType
        resource_type: $resourceType
      }
    ) {
      id
    }
  }
`

const UPDATE = gql`
  mutation(
    $id: Int!
    $fileName: String!
    $fileUrl: String!
    $fileSize: Int!
    $height: Int
    $width: Int
    $mimeType: String!
    $resourceType: String!
  ) {
    result: update_dam_assets_by_pk(
      pk_columns: { id: $id }
      _set: {
        file_name: $fileName
        file_url: $fileUrl
        file_size: $fileSize
        height: $height
        width: $width
        mime_type: $mimeType
        resource_type: $resourceType
      }
    ) {
      id
    }
  }
`

export const uploadToCloudinary = (file) => {
  const apiUrl = env.VUE_APP_CLOUDINARY_API_URL
  const cldName = env.VUE_APP_CLOUDINARY_CLOUD_NAME
  const apiKey = env.VUE_APP_CLOUDINARY_API_KEY
  const apiSecret = env.VUE_APP_CLOUDINARY_API_SECRET
  const defaultFolder = env.VUE_APP_CLOUDINARY_DEFAULT_FOLDER
  const folder = `${defaultFolder}/contents`

  const formData = new FormData()
  const timestamp = Date.now().toString()
  const decryptedData = `folder=${folder}&timestamp=${timestamp}${apiSecret}`
  formData.append('file', file)
  formData.append('api_key', apiKey)
  formData.append('folder', folder)
  formData.append('timestamp', timestamp)
  formData.append('signature', sha1(decryptedData))

  return axios.post(apiUrl.replace('{cld_name}', cldName), formData, { headers: { 'Content-Type': 'multipart/form-data' } })
    .then((response) => response.data)
}

export const propertiesCldForUpload = (folder, type = 'image') => {
  let apiUrl = env.VUE_APP_CLOUDINARY_API_URL
  if (type === 'video') {
    apiUrl = `${apiUrl}/video/upload`
  } else if(type === 'raw') {
    apiUrl = `${apiUrl}/raw/upload`
  } else {
    apiUrl = `${apiUrl}/image/upload`
  }
  const cldName = env.VUE_APP_CLOUDINARY_CLOUD_NAME
  const apiKey = env.VUE_APP_CLOUDINARY_API_KEY
  const apiSecret = env.VUE_APP_CLOUDINARY_API_SECRET
  const timestamp = Date.now().toString()
  const decryptedData = `folder=${folder}&timestamp=${timestamp}${apiSecret}`

  return {
    apiUrl: apiUrl.replace('{cld_name}', cldName),
    api_key: apiKey,
    folder,
    timestamp,
    signature: sha1(decryptedData)
  }
}

export const createAssetWithObjectCld = async (data) => {
  const { public_id, format, url, bytes, height, width, resource_type } = data
  // Add upload file to Assets table
  const apollo = createProvider().clients.defaultClient
  const {
    data: {
      insert_dam_assets_one: { id },
    },
  } = await apollo.mutate({
    mutation: createAssetGQL,
    variables: {
      fileName: `${public_id}`,
      fileUrl: url,
      fileSize: bytes,
      height,
      width,
      mimeType: format,
      resourceType: resource_type,
    },
  })

  return id
}

export const updateAssetWithObjectCld = async (data) => {
  const { id, public_id, format, url, bytes, height, width, resource_type } = data

  console.log(data)

  // Add upload file to Assets table
  const apollo = createProvider().clients.defaultClient
  console.log('update asset')
  const {
    data: { result },
  } = await apollo.mutate({
    mutation: UPDATE,
    variables: {
      id,
      fileName: `${public_id}`,
      fileUrl: url,
      fileSize: bytes,
      height,
      width,
      mimeType: format,
      resourceType: resource_type,
    },
  })

  console.log(result)

  return result.id
}

export const createAsset = async ({ file, mimeType }) => {
  const {
    public_id,
    width,
    height,
    format,
    resource_type: resourceType,
    bytes: fileSize,
    secure_url: fileUrl,
  } = await uploadToCloudinary(file)

  // Add upload file to Assets table
  const apollo = createProvider().clients.defaultClient
  const {
    data: {
      insert_dam_assets_one: { id },
    },
  } = await apollo.mutate({
    mutation: createAssetGQL,
    variables: {
      fileName: public_id.concat('.').concat(format),
      fileUrl,
      fileSize,
      height,
      width,
      mimeType,
      resourceType,
    },
  })

  return id
}

export const uploadMultiFile = async (data = [], attributeCode = null) => {
  if (!isNil(data)) {
    const isArray = Array.isArray(data)
    const values = isArray ? data : Object.keys(data)

    const uploadPromises = values.map(async (value) => {
      const result = {}
      const request = isArray ? value : data[value]
      result.assetId = await createAsset(request)
      result.attributeCode = isNil(attributeCode)
        ? snakeCase(value)
        : snakeCase(attributeCode)
      result.imageDescription = isArray
        ? value.imageDescription
        : get(data, [value, 'imageDescription'], null)
      result.imageAltText = isArray
        ? value.imageAltText
        : get(data, [value, 'imageAltText'], null)
      result.imageExUrl = isArray
        ? value.imageExUrl
        : get(data, [value, 'imageExUrl'], null)

      return result
    })
    const result = await Promise.all(uploadPromises)
    return result
  }

  return data
}

export const createFolder = async (path, folders) => {
  // const apiUrl = env.VUE_APP_API_URL
  // return await axios
  //   .post(`${apiUrl}/cloudinary/create-folder`, {
  //     folders,
  //     path: `${env.VUE_APP_CLOUDINARY_DEFAULT_FOLDER}/${path}`,
  //   })
  //   .then((response) => response.data)
  try {
    await store.dispatch('dam/createFolder', {path: `${env.VUE_APP_CLOUDINARY_DEFAULT_FOLDER}/${path}`, folders})
  } catch (e) {
    console.log('e: ', e)
  }
}

export const getSignedUrl = async (postId) => {
  const apiUrl = env.VUE_APP_API_URL
  console.log(env.VUE_APP_API_URL)
  const { data } = await axios.get(`${apiUrl}/api/v1/cloudinary/signed-url`, {
    params: { postId },
  })

  return data
}
