import gql from 'graphql-tag'
import projectAttributeJson from './attributeJson.json'

export const PROJECT_ATTRIBUTE = projectAttributeJson

export const ADD_PROJECT = gql`
  mutation Project($project: property_project_insert_input!) {
    insert_property_project_one(object: $project) {
      id
      uuid
    }
  }
`

export const UPDATE_PROJECT = gql`
  mutation Project(
    $project: [property_project_insert_input!]!
    $id: Int!
    $uuid: uuid!
    $lang: String!
  ) {
    update_property_project(
      _set: { status: "archived" }
      where: {
        id: { _eq: $id },
        lang: { _eq: $lang }
        status: { _neq: "archived" }
      }
    ) {
      affected_rows
    }
    delete_property_project_house_sell_link(where: {project_uuid: {_eq: $uuid}}) {
      returning {
        uuid
      }
    }
    delete_property_project_house_sell_gallery_link(where: {project_uuid: {_eq: $uuid}}) {
      returning {
        uuid
      }
    }
    delete_cms_project_page_link(
      where: { project_uuid: { _eq: $uuid }, page: { type: { _eq: "category" } } }
    ) {
      affected_rows
    }
    insert_property_project(objects: $project) {
      returning {
        id
        uuid
      }
    }
  }
`

export const GET_EXIST_PROJECT_ID_BY_SLUG = gql`
  query GET_EXIST_PROJECT_ID_BY_LANG($slug: String!, $lang: String!, $id: Int) {
    property_project(where: {
      status: { _neq: "archived" },
      slug: {_eq: $slug}
      lang: {_eq: $lang}
      id: {_neq: $id}
    }) {
      uuid
    }
  }
`

export const GET_EXIST_PROJECT_ID_BY_LANG = gql`
  query GET_EXIST_PROJECT_ID_BY_LANG($projectId: Int!) {
    property_project(where: {
      status: { _neq: "archived" },
      id: {_eq: $projectId}
    }) {
      lang
    }
  }
`

export const GET_PROJECT_BY_ID = gql`
  query MyQuery($projectId: Int!, $lang:String) {
    property_project(where: {
      id: { _eq: $projectId },
      status: { _neq: "archived" },
      lang: {_eq: $lang}
    }) {
      id
      uuid
      brand_id
      group_id
      slug
      lang
      created_by
      updated_by
      updated_at
      project_house_sell_gallery_links {
        house_sell_gallery_id
      }
      project_house_sell_links {
        project_house_sell_uuid
      }
      project_translates {
        project_translate_id: id
        attribute_code
        attribute {
          id
          unitModel {
            unit_labels {
              lang
              value
            }
          }
          attribute_labels {
            lang
            value
          }
        }
        lang
        value
      }
      project_zones {
        project_zone_id: id
        zone_id
        province_id
        district_id
        sub_district_id
      }
      project_properties {
        project_properties_id: id
        property_category {
          id
          property_type
          code
          property_category_translates {
            attribute_code
            attribute {
              id
              unitModel {
                unit_labels {
                  lang
                  value
                }
              }
              attribute_labels {
                lang
                value
              }
            }
            value
          }
        }
        project_property_translates {
          project_property_translate_id: id
          attribute_code
          attribute {
            id
            unitModel {
              unit_labels {
                lang
                value
              }
            }
            attribute_labels {
              lang
              value
            }
          }
          lang
          value
        }
        project_property_buildings {
          project_property_building_id: id
          project_property_building_translates {
            project_property_building_translate_id: id
            attribute_code
            attribute {
              id
              unitModel {
                unit_labels {
                  lang
                  value
                }
              }
              attribute_labels {
                lang
                value
              }
            }
            lang
            value
          }
        }
        project_property_units {
          project_property_unit_id: id
          project_property_unit_translates {
            project_property_unit_translate_id: id
            attribute_code
            attribute {
              id
              unitModel {
                unit_labels {
                  lang
                  value
                }
              }
              attribute_labels {
                lang
                value
              }
            }
            lang
            value
          }
        }
      }
      project_facilities {
        project_facilitie_id: id
        project_facility_translates {
          project_facility_translate_id: id
          attribute_code
          attribute {
            id
            unitModel {
              unit_labels {
                lang
                value
              }
            }
            attribute_labels {
              lang
              value
            }
          }
          lang
          value
        }
      }
      project_category_links {
        property_category_id
        property_category {
          property_type
        }
        url
      }
      project_page_links(where: { page: { type: { _eq: "category" } } }) {
        page_id
      }
      project_files {
        public_id
        attribute_code
        image
        project_file_translates {
          attribute_code
          attribute {
            id
            unitModel {
              unit_labels {
                lang
                value
              }
            }
            attribute_labels {
              lang
              value
            }
          }
          lang
          value
        }
      }
      project_unit_plans {
        public_id
        image
        project_unit_plan_translates {
          attribute_code
          attribute {
            id
            unitModel {
              unit_labels {
                lang
                value
              }
            }
            attribute_labels {
              lang
              value
            }
          }
          lang
          value
        }
      }
      project_property_progresses {
        progress_percent
        progress_date
        project_property_progress_files {
          public_id
          image
          project_property_progress_file_translates {
            attribute_code
            attribute {
              id
              unitModel {
                unit_labels {
                  lang
                  value
                }
              }
              attribute_labels {
                lang
                value
              }
            }
            lang
            value
          }
        }
        project_property_progress_translates {
          attribute_code
          attribute {
            id
            unitModel {
              unit_labels {
                lang
                value
              }
            }
            attribute_labels {
              lang
              value
            }
          }
          lang
          value
        }
      }
      project_property_facilities {
        public_id
        image
        project_property_facility_translates {
          attribute_code
          attribute {
            id
            unitModel {
              unit_labels {
                lang
                value
              }
            }
            attribute_labels {
              lang
              value
            }
          }
          lang
          value
        }
      }
    }
  }
`

export const GET_PROJECT_BY_UUID = gql`
  query MyQuery($projectUuid: uuid!, $lang:String) {
    property_project(where: {
      uuid: { _eq: $projectUuid },
      status: { _neq: "archived" },
      lang: {_eq: $lang}
    }) {
      id
      uuid
      brand_id
      group_id
      slug
      lang
      created_by
      updated_by
      updated_at
      project_house_sell_gallery_links {
        house_sell_gallery_id
      }
      project_house_sell_links {
        project_house_sell_uuid
      }
      project_translates {
        project_translate_id: id
        attribute_code
        attribute {
          id
          unitModel {
            unit_labels {
              lang
              value
            }
          }
          attribute_labels {
            lang
            value
          }
        }
        lang
        value
      }
      project_zones {
        project_zone_id: id
        zone_id
        province_id
        district_id
        sub_district_id
      }
      project_properties {
        project_properties_id: id
        property_category {
          id
          property_type
          code
          property_category_translates {
            attribute_code
            attribute {
              id
              unitModel {
                unit_labels {
                  lang
                  value
                }
              }
              attribute_labels {
                lang
                value
              }
            }
            value
          }
        }
        project_property_translates {
          project_property_translate_id: id
          attribute_code
          attribute {
            id
            unitModel {
              unit_labels {
                lang
                value
              }
            }
            attribute_labels {
              lang
              value
            }
          }
          lang
          value
        }
        project_property_buildings {
          project_property_building_id: id
          project_property_building_translates {
            project_property_building_translate_id: id
            attribute_code
            attribute {
              id
              unitModel {
                unit_labels {
                  lang
                  value
                }
              }
              attribute_labels {
                lang
                value
              }
            }
            lang
            value
          }
        }
        project_property_units {
          project_property_unit_id: id
          project_property_unit_translates {
            project_property_unit_translate_id: id
            attribute_code
            attribute {
              id
              unitModel {
                unit_labels {
                  lang
                  value
                }
              }
              attribute_labels {
                lang
                value
              }
            }
            lang
            value
          }
        }
      }
      project_facilities {
        project_facilitie_id: id
        project_facility_translates {
          project_facility_translate_id: id
          attribute_code
          attribute {
            id
            unitModel {
              unit_labels {
                lang
                value
              }
            }
            attribute_labels {
              lang
              value
            }
          }
          lang
          value
        }
      }
      project_category_links {
        property_category_id
        property_category {
          property_type
        }
        url
      }
      project_page_links(where: { page: { type: { _eq: "category" } } }) {
        page_id
      }
      project_files {
        public_id
        attribute_code
        image
        project_file_translates {
          attribute_code
          attribute {
            id
            unitModel {
              unit_labels {
                lang
                value
              }
            }
            attribute_labels {
              lang
              value
            }
          }
          lang
          value
        }
      }
      project_unit_plans {
        public_id
        image
        project_unit_plan_translates {
          attribute_code
          attribute {
            id
            unitModel {
              unit_labels {
                lang
                value
              }
            }
            attribute_labels {
              lang
              value
            }
          }
          lang
          value
        }
      }
      project_property_progresses {
        progress_percent
        progress_date
        project_property_progress_files {
          public_id
          image
          project_property_progress_file_translates {
            attribute_code
            attribute {
              id
              unitModel {
                unit_labels {
                  lang
                  value
                }
              }
              attribute_labels {
                lang
                value
              }
            }
            lang
            value
          }
        }
        project_property_progress_translates {
          attribute_code
          attribute {
            id
            unitModel {
              unit_labels {
                lang
                value
              }
            }
            attribute_labels {
              lang
              value
            }
          }
          lang
          value
        }
      }
      project_property_facilities {
        public_id
        image
        project_property_facility_translates {
          attribute_code
          attribute {
            id
            unitModel {
              unit_labels {
                lang
                value
              }
            }
            attribute_labels {
              lang
              value
            }
          }
          lang
          value
        }
      }
    }
  }
`

export const GET_PROJECT_LIST = gql`
  query GET_PROJECT_LIST($lang: String!) {
    property_project(
      order_by: { updated_at: desc }
      where: { status: { _neq: "archived" }, lang: { _eq: $lang } }
    ) {
      id
      lang
      uuid
      status
      created_at
      updated_at
      project_translates(
        where: {
          lang: { _eq: $lang }
          attribute_code: { _in: [
            "title",
            "published_date",
            "project_status",
            "direct_telephone",
            "location_flm",
            "sale_name",
            "company_code",
            "project_code",
            "common_fee",
            "common_fee_unit",
            "common_fee_date"
          ] }
        }
      ) {
        attribute_code
        lang
        value
      }
      brand {
        brand_translates(
          where: { lang: { _eq: $lang }, attribute_code: { _eq: "brand_name" } }
        ) {
          id
          attribute_code
          lang
          value
        }
      }
      user_group {
        id
        group_name
      }
      project_zones {
        zone {
          zone_translates(where: { lang: { _in: ["default", "th", "en"] } }) {
            id
            attribute_code
            lang
            value
          }
        }
      }
    }
  }
`

export const DELETE_PROJECT = gql`
  mutation Project($uuid: uuid!, $email: String!) {
    update_property_project(
      _set: {
        status: "archived",
        updated_by: $email
      }
      where: { uuid: { _eq: $uuid } }
    ) {
      affected_rows
    }
    update_property_project_house_sell(
      _set: {
        status: "archived"
      }
      where: { 
        project_house_sell_links: { 
          project: {
            uuid: { _eq: $uuid }
          }
        }
      }
    ) {
      affected_rows
    }
  }
`

export const GET_PROJECT_WITH_GALLERY = gql`
  query GET_PROJECT_WITH_GALLERY($lang: String!) {
    property_project(
      order_by: { updated_at: desc }
      where: { status: { _neq: "archived" }, lang: { _eq: $lang } }
    ) {
      id
      lang
      uuid
      group_id
      user_group {
        group_name
      }
      status
      created_at
      updated_at
      project_translates(
        where: {
          lang: { _eq: $lang }
          attribute_code: { _in: [
            "title", "project_status"
          ]}
        }
      ) {
        attribute_code
        lang
        value
      }
      project_files {
        public_id
        attribute_code
        image
        project_file_translates {
          attribute_code
          lang
          value
        }
      }
    }
  }
`

export const GET_PROJECT_NAMES = gql`
  query GET_PROJECT_NAMES($lang: String!) {
    property_project(
      order_by: { updated_at: desc }
      where: { status: { _neq: "archived" }, lang: { _eq: $lang } }
    ) {
      id
      lang
      uuid
      group_id
      user_group {
        group_name
      }
      status
      created_at
      updated_at
      project_translates(
        where: {
          lang: { _eq: $lang }
          attribute_code: { _in: [
            "title", "project_status"
          ]}
        }
      ) {
        attribute_code
        lang
        value
      }
    }
  }
`

export const GET_PROJECT_TITLE_BY_ID_LANG = gql`
  query GET_PROJECT_TITLE_BY_ID_LANG($projectId: Int!, $lang:String) {
    property_project(where: {
      id: { _eq: $projectId },
      status: { _neq: "archived" },
      lang: {_eq: $lang}
    }) {
      id
      uuid
      brand_id
      group_id
      slug
      lang
      created_by
      updated_by
      project_translates {
        project_translate_id: id
        attribute_code
        attribute {
          id
          unitModel {
            unit_labels {
              lang
              value
            }
          }
          attribute_labels {
            lang
            value
          }
        }
        lang
        value
      }
    }
  }
`
