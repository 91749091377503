import get from 'lodash/get'
import omit from 'lodash/omit'
import orderBy from 'lodash/orderBy'
import moment from 'moment'
import { createProvider } from '@/vue-apollo'
import { FETCH, CREATE, UPDATE, DELETE, FIND_ONE_BY_ID } from './constantGql'

const filterRedirect = (today, data) => {
  if (!data.expiry && !data.start_date) return true
  if (data.expiry && !data.start_date) {
    return moment(data.expiry).isSameOrAfter(today, 'day')
  }
  if (!data.expiry && data.start_date) {
    return moment(data.start_date).isSameOrBefore(today, 'day')
  }
  if (data.expiry && data.start_date) {
    return moment(today).isBetween(data.start_date, data.expiry, 'day', '[]')
  }
}


export default {
  async fetch(context) {
    const apollo = createProvider().clients.defaultClient
    const response = await apollo.query({
      query: FETCH,
    })

    const result = get(response, ['data', 'result'], [])
    const today = moment().format()

    const checkActive = {}

    const redirect = orderBy(result, ['source', 'priority'], ['asc', 'asc'])

    const activeRedirection = redirect.map((r) => {
      const valid = filterRedirect(today, r)
      const isUsed = r.active && valid

      if (isUsed && !checkActive[r.source]) {
        checkActive[r.source] = r
        r.in_use = 'USED'
      }
      return r
    })

    context.commit('SET_ITEMS', orderBy(activeRedirection, ['updated_at'], ['desc']))

    return activeRedirection
  },

  async fetchOne(context, id) {
    const apollo = createProvider().clients.defaultClient
    const response = await apollo.query({
      query: FIND_ONE_BY_ID,
      variables: { id },
    })
    return get(response, ['data', 'result'])
  },

  async addItem(context, data) {
    const apollo = createProvider().clients.defaultClient
    const {
      data: { result },
    } = await apollo.mutate({
      mutation: CREATE,
      variables: {
        toCreateItem: {
          ...omit(data, ['id', 'created_at']),
        },
      },
    })

    return result
  },

  async updateItem(context, { formData }) {
    const apollo = createProvider().clients.defaultClient

    const {
      data: { updateResult },
    } = await apollo.mutate({
      mutation: UPDATE,
      variables: {
        id: formData.id,
        toUpdateItem: formData,
      },
    })

    return updateResult.id
  },

  async delete(context, id) {
    const apollo = createProvider().clients.defaultClient

    const result = await apollo.mutate({
      mutation: DELETE,
      variables: {
        id,
      },
    })

    return result
  },
}
