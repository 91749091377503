/*=========================================================================================
  File Name: moduleDataList.js
  Description: Calendar Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/Geo/pixinvent
==========================================================================================*/

import state from './moduleGeoListState.js'
import mutations from './moduleGeoListMutations.js'
import actions from './moduleGeoListActionsGql.js'
import getters from './moduleGeoListGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
