import get from 'lodash/get'
import { createProvider } from '@/vue-apollo'
import { FETCH, CREATE, UPDATE, DELETE, FIND_ONE_BY_ID } from './constantGql'

export default {
  async fetch({ commit, rootState }) {
    const apollo = createProvider().clients.defaultClient
    const response = await apollo.query({
      query: FETCH,
      variables: {
        lang: rootState.locale.currentLang,
      },
    })

    commit('SET_ITEMS', get(response, ['data', 'result']))
  },
  async fetchOne(context, revision_id) {
    const apollo = createProvider().clients.defaultClient
    const response = await apollo.query({
      query: FIND_ONE_BY_ID,
      variables: { revision_id },
    })
    return get(response, ['data', 'result'])
  },

  async addItem(context, payload) {
    const variables = {
      ...payload,
      lang: context.rootState.locale.currentLang,
    }

    delete variables['id']
    delete variables['revision_id']

    const apollo = createProvider().clients.defaultClient
    const {
      data: { result },
    } = await apollo.mutate({
      mutation: CREATE,
      variables,
    })

    return result
  },

  async updateItem(context, payload) {
    const variables = {
      ...payload,
      lang: context.rootState.locale.currentLang,
    }

    delete variables['revision_id']

    const apollo = createProvider().clients.defaultClient
    const {
      data: { result },
    } = await apollo.mutate({
      mutation: UPDATE,
      variables,
    })

    return result
  },

  async delete({ dispatch }, revision_id) {
    const apollo = createProvider().clients.defaultClient
    await apollo.mutate({
      mutation: DELETE,
      variables: { revision_id },
    })

    await dispatch('fetch')
  },
}
