import get from 'lodash/get'
import { createProvider } from '@/vue-apollo'
import { buildList } from '@/helper/Utils'
import {
  GET_PROVINCE_LIST,
  GET_DISTRICT_LIST,
  GET_SUB_DISTRICT_LIST,
} from './constantGql'

export default {
  async fetchProvinceListItems({ commit, rootState }) {
    const lang = rootState.locale.currentLang
    const apollo = createProvider().clients.defaultClient
    const response = await apollo.query({
      query: GET_PROVINCE_LIST,
    })

    const rawList = get(response, ['data', 'provinces'], [])
    const parsedData = buildList(rawList, 'province_translates', lang)

    commit('SET_PROVINCE', parsedData)
  },

  async fetchDistrictListItems({ commit, rootState }) {
    const lang = rootState.locale.currentLang
    const apollo = createProvider().clients.defaultClient
    const response = await apollo.query({
      query: GET_DISTRICT_LIST,
    })

    const rawList = get(response, ['data', 'districts'], [])
    const parsedData = buildList(rawList, 'district_translates', lang)

    commit('SET_DISTRICT', parsedData)
  },

  async fetchSubDistrictListItems({ commit, rootState }) {
    const lang = rootState.locale.currentLang
    const apollo = createProvider().clients.defaultClient
    const response = await apollo.query({
      query: GET_SUB_DISTRICT_LIST,
    })

    const rawList = get(response, ['data', 'subDistricts'], [])
    const parsedData = buildList(rawList, 'sub_district_translates', lang)

    commit('SET_SUB_DISTRICT', parsedData)
  },
}
