export default {
  SET_ITEMS(state, value) {
    state.items = value
  },
  SET_LOADING(state, loading) {
    state.loading = loading
  },
  SET_IS_SLUG_EXIST(state, isExist = false) {
    state.isSlugExist = isExist
  }
}
