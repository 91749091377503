// en, th, cn
export default {
  en: {
    Dashboard: 'Dashboard',
    eCommerce: 'eCommerce',
    Apps: 'Apps',
    PropertyApp: 'Property Apps',
    PropertyBrand: 'Brand',
    PropertyBrandList: 'All Brands',
    PropertyBrandAdd: 'Add Brand',
    PropertyProject: 'Project',
    PropertyPlan: 'Plan',
    PropertyPlanSeries: 'Series',
    PropertyPlanHouseModel: 'House Model',
    PropertyPlanHomeFunction: 'Home Function',
    PropertyPlanCondoFunction: 'Condo Function',
    PropertyPlanActivityIcon: 'Activity Icons',
    PropertyPlanMasterGallery: 'Master Gallery',
    PropertyType: 'Property Type',
    PropertyZone: 'Zone',
    PropertyGeoInfo: 'Geo Info',
    PropertyPromotion: 'Promotion',
    PropertySetting: 'Setting',
    PropertyReview: 'Review',
    WebsiteMgtApp: 'Website Management',
    LHContentApp: 'LH Content',
    LHContentMotivoMagazine: 'Motivo Magazine',
    LHContentProjectReview: 'Project Review',
    LHContentTvc: 'TVC',
    LHContentCommunity: 'LH Community',
    LHContentLivingTips: 'Living Tips',
    LandingPage: 'Landing Pages',
    SeoManagement: 'SEO',
    RedirectionManagement: 'Redirection',
    BlackListEmailManagement: 'Blacklist Email',
    BannerManagement: 'Banner',
    FooterManagement: 'Footer',
    HighlightManagement: 'Highlight',
    MenuManagement: 'Menu',
    UserMgtApp: 'User',
    AdminUserManagement: 'Admin Users',
    UserGroupManagement: 'User Groups',
    PageApp: 'Page',
    Todo: 'Todo',
    Chat: 'Chat',
    Email: 'Email',
    Calendar: 'Calendar',
    FullCalendar: 'Full Calendar',
    SimpleCalendar: 'Simple Calendar',
    Shop: 'Shop',
    ItemDetails: 'Item Details',
    Cart: 'Cart',
    WishList: 'Wish List',
    Checkout: 'Checkout',
    UI: 'UI',
    DataList: 'Data List',
    ListView: 'List View',
    ThumbView: 'Thumb View',
    Grid: 'Grid',
    Vuexy: 'Vuexy',
    Vuesax: 'Vuesax',
    Tailwind: 'Tailwind',
    Colors: 'Colors',
    Card: 'Card',
    Basic: 'Basic',
    Statistics: 'Statistics',
    Analytics: 'Analytics',
    CardActions: 'Card Actions',
    CardColors: 'Card Colors',
    Table: 'Table',
    agGridTable: 'agGrid Table',
    Components: 'Components',
    Alert: 'Alert',
    Avatar: 'Avatar',
    Badge: 'Badge',
    Breadcrumb: 'Breadcrumb',
    Button: 'Button',
    ButtonGroup: 'Button Group',
    Chip: 'Chip',
    Collapse: 'Collapse',
    Dialogs: 'Dialogs',
    Divider: 'Divider',
    DropDown: 'DropDown',
    List: 'List',
    Loading: 'Loading',
    Navbar: 'Navbar',
    Notifications: 'Notifications',
    Pagination: 'Pagination',
    Popup: 'Popup',
    Progress: 'Progress',
    Sidebar: 'Sidebar',
    Slider: 'Slider',
    Tabs: 'Tabs',
    Tooltip: 'Tooltip',
    Upload: 'Upload',
    FormsAndTable: 'Forms & Table',
    FormElements: 'Form Elements',
    Select: 'Select',
    Switch: 'Switch',
    Checkbox: 'Checkbox',
    Radio: 'Radio',
    Input: 'Input',
    NumberInput: 'Number Input',
    Textarea: 'Textarea',
    FormLayouts: 'Form Layouts',
    FormWizard: 'Form Wizard',
    FormValidation: 'Form Validation',
    FormInputGroup: 'Form Input Group',
    Pages: 'Pages',
    Authentication: 'Authentication',
    Login: 'Login',
    Register: 'Register',
    ForgotPassword: 'Forgot Password',
    ResetPassword: 'Reset Password',
    LockScreen: 'Lock Screen',
    Miscellaneous: 'Miscellaneous',
    ComingSoon: 'Coming Soon',
    Error: 'Error',
    '404': '404',
    '500': '500',
    NotAuthorized: 'Not Authorized',
    Maintenance: 'Maintenance',
    Profile: 'Profile',
    User: 'User',
    View: 'View',
    Edit: 'Edit',
    UserSettings: 'User Settings',
    Invoice: 'Invoice',
    FAQ: 'FAQ',
    Search: 'Search',
    KnowledgeBase: 'Knowledge Base',
    ChartsAndMaps: 'Charts & Maps',
    Charts: 'Charts',
    ApexCharts: 'Apex Charts',
    chartjs: 'chartjs',
    echarts: 'echarts',
    chartist: 'chartist',
    GoogleMap: 'Google Map',
    Extensions: 'Extensions',
    QuillEditor: 'Quill Editor',
    DragAndDrop: 'Drag & Drop',
    Datepicker: 'Datepicker',
    DatetimePicker: 'Datetime Picker',
    AccessControl: 'Access Control',
    I18n: 'I18n',
    Carousel: 'Carousel',
    Clipboard: 'Clipboard',
    ContextMenu: 'Context Menu',
    StarRatings: 'Star Ratings',
    Autocomplete: 'Autocomplete',
    Tree: 'Tree',
    '// "Import/Export"': 'Import/Export',
    Import: 'Import',
    Export: 'Export',
    ExportSelected: 'Export Selected',
    Others: 'อื่นๆ',
    MenuLevels: 'Menu Levels',
    MenuLevel2p1: 'Menu Level 2.1',
    MenuLevel2p2: 'Menu Level 2.2',
    MenuLevel3p1: 'Menu Level 3.1',
    MenuLevel3p2: 'Menu Level 3.2',
    DisabledMenu: 'Disabled Menu',
    Support: 'Support',
    Documentation: 'Documentation',
    RaiseSupport: 'Raise Support',
    demoTitle: 'Card Title',
    demoText:
      'Cake sesame snaps cupcake gingerbread danish I love gingerbread. Apple pie pie jujubes chupa chups muffin halvah lollipop. Chocolate cake oat cake tiramisu marzipan sugar plum. Donut sweet pie oat cake dragée fruitcake cotton candy lemon drops.',
    home: 'หน้า Home',
    singlehome: 'บ้านเดี่ยว',
    'furnished-home': 'บ้านตกแต่งพร้อมขาย',
    townhome: 'ทาวน์โฮม',
    condominium: 'คอนโดมิเนียม',
    newproject: 'ข่าวโครงการใหม่',
    'major-cities': 'โครงการในต่างจังหวัด',
    ladawan: 'LADAWAN',
    'home-series': 'แบบบ้าน',
    gallery: 'Lead Image',
    video_uploaded: 'Clip VDO',
    video_youtube: 'Clip Youtube',
    text_image: 'Banner Activity',
    ContactUs: 'Contact Us',
    Report: 'Report',
    ProjectVdoManagement: "VDO Project Clip",
    CsrManagement: "LH CSR"
  },
  th: {
    Dashboard: 'แดชบอร์ด',
    eCommerce: 'eCommerce',
    Apps: 'Apps',
    PropertyApp: 'จัดการพร็อพเพอร์ตี้',
    PropertyBrand: 'แบรนด์',
    PropertyBrandList: 'แบรนด์ทั้งหมด',
    PropertyBrandAdd: 'เพิ่มแบรนด์',
    PropertyProject: 'โครงการ',
    PropertyPlan: 'ระบบจัดการข้อมูลแบบบ้าน',
    PropertyPlanSeries: 'ข้อมูลสไตล์บ้าน',
    PropertyPlanHouseModel: 'ข้อมูลแบบบ้าน',
    PropertyPlanHomeFunction: 'เพิ่มฟังก์ชันบ้าน',
    PropertyPlanCondoFunction: 'ฟังก์ชันคอนโด',
    PropertyPlanActivityIcon: 'ไอคอนกิจกรรม',
    PropertyPlanMasterGallery: 'ข้อมูลรูปบ้านตกแต่งพร้อมขาย',
    PropertyType: 'ประเภท Property',
    PropertyZone: 'โซน',
    PropertyGeoInfo: 'จังหวัด เขต แขวง',
    PropertyPromotion: 'โปรโมชัน',
    PropertySetting: 'ตั้งค่าอื่นๆ',
    PropertyReview: 'รีวิว',
    WebsiteMgtApp: 'จัดการเว็บไซต์',
    LHContentApp: 'LH Content',
    LHContentMotivoMagazine: 'Motivo Magazine',
    LHContentProjectReview: 'Project Review',
    LHContentTvc: 'TVC',
    LHContentCommunity: 'LH Community',
    LHContentLivingTips: 'Living Tips',
    LandingPage: 'Landing Pages',
    SeoManagement: 'SEO',
    RedirectionManagement: 'Redirection',
    BlackListEmailManagement: 'Blacklist Email',
    BannerManagement: 'Banner',
    FooterManagement: 'Footer',
    HighlightManagement: 'Highlight',
    AdminToolsMgtApp: 'เครื่องมือจัดการระบบ',
    UserMgtApp: 'ผู้ใช้งาน',
    MenuManagement: 'จัดการเมนู',
    AdminUserManagement: 'ผู้ใช้งานระบบ',
    UserGroupManagement: 'กลุ่มผู้ใช้งานระบบ',
    ManualApp: 'คู่มือการใช้งานระบบ',
    Todo: 'Todo',
    Chat: 'Chat',
    Email: 'Email',
    Calendar: 'Calendar',
    FullCalendar: 'Full Calendar',
    SimpleCalendar: 'Simple Calendar',
    Shop: 'Shop',
    ItemDetails: 'Item Details',
    Cart: 'Cart',
    WishList: 'Wish List',
    Checkout: 'Checkout',
    UI: 'UI',
    DataList: 'Data List',
    ListView: 'List View',
    ThumbView: 'Thumb View',
    Grid: 'Grid',
    Vuexy: 'Vuexy',
    Vuesax: 'Vuesax',
    Tailwind: 'Tailwind',
    Colors: 'Colors',
    Card: 'Card',
    Basic: 'Basic',
    Statistics: 'Statistics',
    Analytics: 'Analytics',
    CardActions: 'Card Actions',
    CardColors: 'Card Colors',
    Table: 'Table',
    agGridTable: 'agGrid Table',
    Components: 'Components',
    Alert: 'Alert',
    Avatar: 'Avatar',
    Badge: 'Badge',
    Breadcrumb: 'Breadcrumb',
    Button: 'Button',
    ButtonGroup: 'Button Group',
    Chip: 'Chip',
    Collapse: 'Collapse',
    Dialogs: 'Dialogs',
    Divider: 'Divider',
    DropDown: 'DropDown',
    List: 'List',
    Loading: 'Loading',
    Navbar: 'Navbar',
    Notifications: 'Notifications',
    Pagination: 'Pagination',
    Popup: 'Popup',
    Progress: 'Progress',
    Sidebar: 'Sidebar',
    Slider: 'Slider',
    Tabs: 'Tabs',
    Tooltip: 'Tooltip',
    Upload: 'Upload',
    FormsAndTable: 'Forms & Table',
    FormElements: 'Form Elements',
    Select: 'Select',
    Switch: 'Switch',
    Checkbox: 'Checkbox',
    Radio: 'Radio',
    Input: 'Input',
    NumberInput: 'Number Input',
    Textarea: 'Textarea',
    FormLayouts: 'Form Layouts',
    FormWizard: 'Form Wizard',
    FormValidation: 'Form Validation',
    FormInputGroup: 'Form Input Group',
    Pages: 'Pages',
    Authentication: 'Authentication',
    Login: 'Login',
    Register: 'Register',
    ForgotPassword: 'Forgot Password',
    ResetPassword: 'Reset Password',
    LockScreen: 'Lock Screen',
    Miscellaneous: 'Miscellaneous',
    ComingSoon: 'Coming Soon',
    Error: 'Error',
    '404': '404',
    '500': '500',
    NotAuthorized: 'Not Authorized',
    Maintenance: 'Maintenance',
    Profile: 'Profile',
    User: 'User',
    View: 'View',
    Edit: 'Edit',
    UserSettings: 'User Settings',
    Invoice: 'Invoice',
    FAQ: 'FAQ',
    Search: 'Search',
    KnowledgeBase: 'Knowledge Base',
    ChartsAndMaps: 'Charts & Maps',
    Charts: 'Charts',
    ApexCharts: 'Apex Charts',
    chartjs: 'chartjs',
    echarts: 'echarts',
    chartist: 'chartist',
    GoogleMap: 'Google Map',
    Extensions: 'Extensions',
    QuillEditor: 'Quill Editor',
    DragAndDrop: 'Drag & Drop',
    Datepicker: 'Datepicker',
    DatetimePicker: 'Datetime Picker',
    AccessControl: 'Access Control',
    I18n: 'I18n',
    Carousel: 'Carousel',
    Clipboard: 'Clipboard',
    ContextMenu: 'Context Menu',
    StarRatings: 'Star Ratings',
    Autocomplete: 'Autocomplete',
    Tree: 'Tree',
    '// "Import/Export"': 'Import/Export',
    Import: 'Import',
    Export: 'Export',
    ExportSelected: 'Export Selected',
    Others: 'อื่นๆ',
    MenuLevels: 'Menu Levels',
    MenuLevel2p1: 'Menu Level 2.1',
    MenuLevel2p2: 'Menu Level 2.2',
    MenuLevel3p1: 'Menu Level 3.1',
    MenuLevel3p2: 'Menu Level 3.2',
    DisabledMenu: 'Disabled Menu',
    Support: 'Support',
    Documentation: 'Documentation',
    RaiseSupport: 'Raise Support',
    demoTitle: 'Card Title',
    demoText:
      'Cake sesame snaps cupcake gingerbread danish I love gingerbread. Apple pie pie jujubes chupa chups muffin halvah lollipop. Chocolate cake oat cake tiramisu marzipan sugar plum. Donut sweet pie oat cake dragée fruitcake cotton candy lemon drops.',
    home: 'หน้า Home',
    singlehome: 'บ้านเดี่ยว',
    'furnished-home': 'บ้านตกแต่งพร้อมขาย',
    townhome: 'ทาวน์โฮม',
    condominium: 'คอนโดมิเนียม',
    newproject: 'ข่าวโครงการใหม่',
    'major-cities': 'โครงการในต่างจังหวัด',
    ladawan: 'LADAWAN',
    'home-series': 'แบบบ้าน',
    gallery: 'Lead Image',
    video_uploaded: 'Clip VDO',
    video_youtube: 'Clip Youtube',
    text_image: 'Banner Activity',
    ContactUs: 'Contact Us',
    Report: 'Report',
    ProjectVdoManagement: "VDO Project Clip"
  },
  cn: {
    Dashboard: 'Dashboard',
    eCommerce: 'eCommerce',
    Apps: 'Apps',
    PropertyApp: 'Property Apps',
    PropertyBrand: 'Brand',
    PropertyBrandList: 'All Brands',
    PropertyBrandAdd: 'Add Brand',
    PropertyProject: 'Project',
    PropertyPlan: 'Plan',
    PropertyPlanSeries: 'Series',
    PropertyPlanHouseModel: 'House Model',
    PropertyPlanHomeFunction: 'Home Function',
    PropertyPlanCondoFunction: 'Condo Function',
    PropertyPlanActivityIcon: 'Activity Icons',
    PropertyPlanMasterGallery: 'Master Gallery',
    PropertyType: 'Property Type',
    PropertyZone: 'Zone',
    PropertyGeoInfo: 'Geo Info',
    PropertyPromotion: 'Promotion',
    PropertyReview: 'Review',
    PropertySetting: 'Setting',
    WebsiteMgtApp: 'Website Management',
    LHContentApp: 'LH Content',
    LHContentMotivoMagazine: 'Motivo Magazine',
    LHContentProjectReview: 'Project Review',
    LHContentTvc: 'TVC',
    LHContentCommunity: 'LH Community',
    LHContentLivingTips: 'Living Tips',
    LandingPage: 'Landing Pages',
    SeoManagement: 'SEO',
    RedirectionManagement: 'Redirection',
    BlackListEmailManagement: 'Blacklist Email',
    BannerManagement: 'Banner',
    FooterManagement: 'Footer',
    HighlightManagement: 'Highlight',
    MenuManagement: 'จัดการเมนู',
    AdminToolsMgtApp: 'Admin Tools',
    UserMgtApp: 'User',
    AdminUserManagement: 'Admin Users',
    UserGroupManagement: 'User Groups',
    ManualApp: 'Manual Documentation',
    Todo: 'Todo',
    Chat: 'Chat',
    Email: 'Email',
    Calendar: 'Calendar',
    FullCalendar: 'Full Calendar',
    SimpleCalendar: 'Simple Calendar',
    Shop: 'Shop',
    ItemDetails: 'Item Details',
    Cart: 'Cart',
    WishList: 'Wish List',
    Checkout: 'Checkout',
    UI: 'UI',
    DataList: 'Data List',
    ListView: 'List View',
    ThumbView: 'Thumb View',
    Grid: 'Grid',
    Vuexy: 'Vuexy',
    Vuesax: 'Vuesax',
    Tailwind: 'Tailwind',
    Colors: 'Colors',
    Card: 'Card',
    Basic: 'Basic',
    Statistics: 'Statistics',
    Analytics: 'Analytics',
    CardActions: 'Card Actions',
    CardColors: 'Card Colors',
    Table: 'Table',
    agGridTable: 'agGrid Table',
    Components: 'Components',
    Alert: 'Alert',
    Avatar: 'Avatar',
    Badge: 'Badge',
    Breadcrumb: 'Breadcrumb',
    Button: 'Button',
    ButtonGroup: 'Button Group',
    Chip: 'Chip',
    Collapse: 'Collapse',
    Dialogs: 'Dialogs',
    Divider: 'Divider',
    DropDown: 'DropDown',
    List: 'List',
    Loading: 'Loading',
    Navbar: 'Navbar',
    Notifications: 'Notifications',
    Pagination: 'Pagination',
    Popup: 'Popup',
    Progress: 'Progress',
    Sidebar: 'Sidebar',
    Slider: 'Slider',
    Tabs: 'Tabs',
    Tooltip: 'Tooltip',
    Upload: 'Upload',
    FormsAndTable: 'Forms & Table',
    FormElements: 'Form Elements',
    Select: 'Select',
    Switch: 'Switch',
    Checkbox: 'Checkbox',
    Radio: 'Radio',
    Input: 'Input',
    NumberInput: 'Number Input',
    Textarea: 'Textarea',
    FormLayouts: 'Form Layouts',
    FormWizard: 'Form Wizard',
    FormValidation: 'Form Validation',
    FormInputGroup: 'Form Input Group',
    Pages: 'Pages',
    Authentication: 'Authentication',
    Login: 'Login',
    Register: 'Register',
    ForgotPassword: 'Forgot Password',
    ResetPassword: 'Reset Password',
    LockScreen: 'Lock Screen',
    Miscellaneous: 'Miscellaneous',
    ComingSoon: 'Coming Soon',
    Error: 'Error',
    '404': '404',
    '500': '500',
    NotAuthorized: 'Not Authorized',
    Maintenance: 'Maintenance',
    Profile: 'Profile',
    User: 'User',
    View: 'View',
    Edit: 'Edit',
    UserSettings: 'User Settings',
    Invoice: 'Invoice',
    FAQ: 'FAQ',
    Search: 'Search',
    KnowledgeBase: 'Knowledge Base',
    ChartsAndMaps: 'Charts & Maps',
    Charts: 'Charts',
    ApexCharts: 'Apex Charts',
    chartjs: 'chartjs',
    echarts: 'echarts',
    chartist: 'chartist',
    GoogleMap: 'Google Map',
    Extensions: 'Extensions',
    QuillEditor: 'Quill Editor',
    DragAndDrop: 'Drag & Drop',
    Datepicker: 'Datepicker',
    DatetimePicker: 'Datetime Picker',
    AccessControl: 'Access Control',
    I18n: 'I18n',
    Carousel: 'Carousel',
    Clipboard: 'Clipboard',
    ContextMenu: 'Context Menu',
    StarRatings: 'Star Ratings',
    Autocomplete: 'Autocomplete',
    Tree: 'Tree',
    '// "Import/Export"': 'Import/Export',
    Import: 'Import',
    Export: 'Export',
    ExportSelected: 'Export Selected',
    Others: 'Others',
    MenuLevels: 'Menu Levels',
    MenuLevel2p1: 'Menu Level 2.1',
    MenuLevel2p2: 'Menu Level 2.2',
    MenuLevel3p1: 'Menu Level 3.1',
    MenuLevel3p2: 'Menu Level 3.2',
    DisabledMenu: 'Disabled Menu',
    Support: 'Support',
    Documentation: 'Documentation',
    RaiseSupport: 'Raise Support',
    demoTitle: 'Card Title',
    demoText:
      'Cake sesame snaps cupcake gingerbread danish I love gingerbread. Apple pie pie jujubes chupa chups muffin halvah lollipop. Chocolate cake oat cake tiramisu marzipan sugar plum. Donut sweet pie oat cake dragée fruitcake cotton candy lemon drops.',
    '"home"': 'หน้า Home',
    singlehome: 'บ้านเดี่ยว',
    'furnished-home': 'บ้านตกแต่งพร้อมขาย',
    townhome: 'ทาวน์โฮม',
    condominium: 'คอนโดมิเนียม',
    newproject: 'ข่าวโครงการใหม่',
    'major-cities': 'โครงการในต่างจังหวัด',
    ladawan: 'LADAWAN',
    'home-series': 'แบบบ้าน',
    gallery: 'Lead Image',
    video_uploaded: 'Clip VDO',
    video_youtube: 'Clip Youtube',
    text_image: 'Banner Activity',
    ContactUs: 'Contact Us',
    Report: 'Report',
    ProjectVdoManagement: "VDO Project Clip",
    CsrManagement: "LH CSR"
  },
}
