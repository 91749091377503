export default {
  ADD_PROJECT(state, item) {
    state.addProject = item
  },
  UPDATE_PROJECT(state, item) {
    state.updateProject = item
  },
  SET_PROJECTS(state, projects) {
    state.projects = projects
  },
  SET_GALLERY_PROJECTS(state, projects) {
    state.galleryProjects = projects
  },
  SET_NAMES(state, projects) {
    state.nameProjects = projects
  },
  SET_PROJECT(state, project) {
    state.project = project
  },
  SET_IS_SLUG_EXIST(state, isExist = false) {
    state.isSlugExist = isExist
  }
  // SET_LABELS(state, labels) {
  //   state.eventLabels = labels
  // },
  // UPDATE_PROJECT (state, item) {
  //  const itemIndex = state.projects.findIndex((p) => p.id === item.id)
  //  Object.assign(state.brands[itemIndex], item)
  // },
  // REMOVE_PROJECT (state, itemId) {
  //   const ItemIndex = state.projects.findIndex((p) => p.id === itemId)
  //   state.projects.splice(ItemIndex, 1)
  // }
}
