import get from 'lodash/get'
import omit from 'lodash/omit'
import { createProvider } from '@/vue-apollo'
import { FETCH, CREATE, UPDATE, DELETE, FIND_ONE_BY_ID } from './constantGql'

export default {
  async fetch(context) {
    const apollo = createProvider().clients.defaultClient
    const response = await apollo.query({
      query: FETCH,
      variables: {
        lang: context.rootState.locale.currentLang,
      },
    })

    context.commit('SET_ITEMS', get(response, ['data', 'result']))
  },

  async fetchOne(_, id) {
    const apollo = createProvider().clients.defaultClient
    const response = await apollo.query({
      query: FIND_ONE_BY_ID,
      variables: { id },
    })
    return get(response, ['data', 'result', 0])
  },

  async addItem(context, data) {
    const apollo = createProvider().clients.defaultClient
    const {
      data: { result },
    } = await apollo.mutate({
      mutation: CREATE,
      variables: {
        toCreateItem: {
          ...omit(data, ['uuid', 'id', 'created_at', 'updated_at']),
          lang: context.rootState.locale.currentLang,
        },
      },
    })

    return result
  },

  async updateItem(context, { formData }) {
    const apollo = createProvider().clients.defaultClient

    const {
      data: { updateResult },
    } = await apollo.mutate({
      mutation: UPDATE,
      variables: {
        id: formData.id,
        lang: context.rootState.locale.currentLang,
        csr: omit(formData, ['uuid', 'created_at', 'updated_at']),
      },
    })

    return updateResult.uuid
  },

  async delete(context, uuid) {
    const apollo = createProvider().clients.defaultClient

    const result = await apollo.mutate({
      mutation: DELETE,
      variables: {
        uuid,
      },
    })

    return result
  },
}
