/*=========================================================================================
  File Name: moduleAuthActions.js
  Description: Auth Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import { createProvider } from '@/vue-apollo'
import get from 'lodash/get'
import firebase from 'firebase/app'
import 'firebase/auth'
import router from '@/router'

import {
  FETCH_ACCOUNT_BY_PROVIDER
} from './constantGql'

import jwt from '../../http/requests/auth/jwt/index.js'

export default {
  async loginAttempt({ dispatch }, payload) {
    // New payload for login action
    const newPayload = {
      userDetails: payload.userDetails,
      notify: payload.notify,
      closeAnimation: payload.closeAnimation,
    }

    // If remember_me is enabled change firebase Persistence
    if (!payload.checkbox_remember_me) {
      // Change firebase Persistence
      return firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.LOCAL)

        // If success try to login
        .then(() => {
          return dispatch('login', newPayload)
        })

        // If error notify
        .catch(function (err) {
          // Close animation if passed as payload
          if (payload.closeAnimation) payload.closeAnimation()

          payload.notify({
            time: 2500,
            title: 'Error',
            text: err.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    } else {
      // Try to login
      return dispatch('login', newPayload)
    }
  },

  login({ commit, state, dispatch }, payload) {
    // If user is already logged in notify and exit
    if (state.isUserLoggedIn()) {
      // Close animation if passed as payload
      if (payload.closeAnimation) payload.closeAnimation()

      payload.notify({
        title: 'Login Attempt',
        text: 'You are already logged in!',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'warning',
      })

      return false
    }

    // Try to sign-in
    return firebase
      .auth()
      .signInWithEmailAndPassword(payload.userDetails.email, payload.userDetails.password)
      .then(
        async (result) => {
          const userUid = get(result, ['user', 'uid'], null)

          const rawAccountInfoDB = await dispatch('fetchUserByProvider', {
            authProvider: 'firebase', authProviderUniqueId: userUid
          })

          const accountInfoDB = get(rawAccountInfoDB, 0, null)

          // console.log('accountInfoDB: ', accountInfoDB)
          // // Result
          // console.log('isUserLoggedIn', result.user)
          
          // Set FLAG username update required for updating username
          let isUsernameUpdateRequired = false

          // if username is provided and updateUsername FLAG is true
          // set local username update FLAG to true
          // try to update username
          if (payload.updateUsername && payload.userDetails.displayName) {
            isUsernameUpdateRequired = true

            dispatch('updateUsername', {
              user: result.user,
              username: payload.userDetails.displayName,
              email: result.user.email,
              notify: payload.notify,
              isReloadRequired: true,
              token: result.user.xa,
              refresh_token: result.user.refreshToken
            })
          }

          // Close animation if passed as payload
          if (payload.closeAnimation) payload.closeAnimation()

          // if username update is not required
          // just reload the page to get fresh data
          // set new user data in localstorage
          if (!isUsernameUpdateRequired) {
            const userRole = get(accountInfoDB, ['account', 'default_role'], 'guest')
            const linkGroup = get(accountInfoDB, ['account', 'user', 'user_group_links'], [])
            const listGroupName = linkGroup.map((group) => {
              return get(group, ['user_group', 'group_name'], '')
            })

            await commit('UPDATE_USER_INFO', {
              ...result.user.providerData[0],
              uid: userUid,
              userRole,
              displayName: get(accountInfoDB, ['account', 'user', 'display_name'], 'Guest'),
              setting: get(accountInfoDB, ['account', 'user', 'setting'], {}),
              groupName: listGroupName || [],
            }, { root: true })
          }
        },
        (err) => {
          // Close animation if passed as payload
          if (payload.closeAnimation) payload.closeAnimation()

          payload.notify({
            time: 2500,
            title: 'Error',
            text: err.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        },
      )
  },

  // Google Login
  loginWithGoogle({ commit, state }, payload) {
    if (state.isUserLoggedIn()) {
      payload.notify({
        title: 'Login Attempt',
        text: 'You are already logged in!',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'warning',
      })
      return false
    }
    const provider = new firebase.auth.GoogleAuthProvider()

    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        router.push(router.currentRoute.query.to || '/')
        commit('UPDATE_USER_INFO', result.user.providerData[0], { root: true })
      })
      .catch((err) => {
        payload.notify({
          time: 2500,
          title: 'Error',
          text: err.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
      })
  },

  // Facebook Login
  loginWithFacebook({ commit, state }, payload) {
    if (state.isUserLoggedIn()) {
      payload.notify({
        title: 'Login Attempt',
        text: 'You are already logged in!',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'warning',
      })
      return false
    }
    const provider = new firebase.auth.FacebookAuthProvider()

    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        router.push(router.currentRoute.query.to || '/')
        commit('UPDATE_USER_INFO', result.user.providerData[0], { root: true })
      })
      .catch((err) => {
        payload.notify({
          time: 2500,
          title: 'Error',
          text: err.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
      })
  },

  // Twitter Login
  loginWithTwitter({ commit, state }, payload) {
    if (state.isUserLoggedIn()) {
      payload.notify({
        title: 'Login Attempt',
        text: 'You are already logged in!',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'warning',
      })
      return false
    }
    const provider = new firebase.auth.TwitterAuthProvider()

    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        router.push(router.currentRoute.query.to || '/')
        commit('UPDATE_USER_INFO', result.user.providerData[0], { root: true })
      })
      .catch((err) => {
        payload.notify({
          time: 2500,
          title: 'Error',
          text: err.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
      })
  },

  // Github Login
  loginWithGithub({ commit, state }, payload) {
    if (state.isUserLoggedIn()) {
      payload.notify({
        title: 'Login Attempt',
        text: 'You are already logged in!',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'warning',
      })
      return false
    }
    const provider = new firebase.auth.GithubAuthProvider()

    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        router.push(router.currentRoute.query.to || '/')
        commit('UPDATE_USER_INFO', result.user.providerData[0], { root: true })
      })
      .catch((err) => {
        payload.notify({
          time: 2500,
          title: 'Error',
          text: err.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
      })
  },

  async registerUser(_, payload) {
    console.log('Call register user request to Firebase.')
    // create user using firebase
    const user = await firebase
      .auth()
      .createUserWithEmailAndPassword(payload.email, payload.password)
    const userUid = get(user, ['user', 'uid'], null)
    if (userUid) {
      await firebase.database().ref(`metadata/${userUid}`).set({
        username: payload.username,
        email: payload.username,
        group_id: payload.group_id,
        role: payload.role
      })
    }
    return user
  },

  updateUsername({ commit }, payload) {
    payload.user
      .updateProfile({
        displayName: payload.displayName,
      })
      .then(() => {
        // If username update is success
        // update in localstorage
        const newUserData = Object.assign({}, payload.user.providerData[0])
        newUserData.displayName = payload.displayName
        commit('UPDATE_USER_INFO', newUserData, { root: true })

        // If reload is required to get fresh data after update
        // Reload current page
        if (payload.isReloadRequired) {
          router.push(router.currentRoute.query.to || '/')
        }
      })
      .catch((err) => {
        payload.notify({
          time: 8800,
          title: 'Error',
          text: err.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
      })
  },

  // JWT
  loginJWT({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .login(payload.userDetails.email, payload.userDetails.password)
        .then((response) => {
          // If there's user data in response
          if (response.data.userData) {
            // Navigate User to homepage
            router.push(router.currentRoute.query.to || '/')

            // Set accessToken
            localStorage.setItem('accessToken', response.data.accessToken)

            // Update user details
            commit('UPDATE_USER_INFO', response.data.userData, { root: true })

            // Set bearer token in axios
            commit('SET_BEARER', response.data.accessToken)

            resolve(response)
          } else {
            reject({ message: 'Wrong Email or Password' })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  registerUserJWT({ commit }, payload) {
    const { displayName, email, password, confirmPassword } = payload.userDetails

    return new Promise((resolve, reject) => {
      // Check confirm password
      if (password !== confirmPassword) {
        reject({ message: "Password doesn't match. Please try again." })
      }

      jwt
        .registerUser(displayName, email, password)
        .then((response) => {
          // Redirect User
          router.push(router.currentRoute.query.to || '/')

          // Update data in localStorage
          localStorage.setItem('accessToken', response.data.accessToken)
          commit('UPDATE_USER_INFO', response.data.userData, { root: true })

          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  fetchAccessToken() {
    return new Promise((resolve) => {
      jwt.refreshToken().then((response) => {
        resolve(response)
      })
    })
  },

  async fetchUserByProvider(_, data) {
    const { authProvider = '', authProviderUniqueId = null } = data
    
    const apollo = createProvider().clients.defaultClient
    const response = await apollo.query({
      query: FETCH_ACCOUNT_BY_PROVIDER,
      variables: { authProvider, authProviderUniqueId }
    })
    return get(response, ['data', 'result'])
  },
  async updateUserInfo({ commit }, data) {
    return commit('UPDATE_USER_INFO', data, { root: true })
  }
}
